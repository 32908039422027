import { memo } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'

import HomeSubscribeSignIn from "./HomeSubscribeSignIn"
import VideoPlayer from '../../common/VideoPlayer'

const Container = styled.div`
  padding: 0 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LogoImage = styled.img`
  height: 70px;
`

const TagLine = styled.div`
  margin: -6px 0 0;
  font-weight: 300;
  white-space: nowrap;
`

const Video = styled(VideoPlayer)`
  margin-top: 20px;
  width: 360px;
  height: 203px;
  border: none;
`

const VideoIntroLine = styled.div`
  font-weight: 300;
  margin: 2px 0;
  font-size: 13px;
`

const HomeWhatIs = ({
  fullScreen,
  toggleSliderSectionOpen,
}) => {

  return (
    <Container>

      <LogoImage src="/logo.svg"/>

      <TagLine>
        {i18n("For Deeper Study of Scripture")}
      </TagLine>

      <HomeSubscribeSignIn
        fullScreen={fullScreen}
        toggleSliderSectionOpen={toggleSliderSectionOpen}
      />

      <Video
        src="https://player.vimeo.com/video/135262312?color=bdac59&title=1&byline=0"
      />
      <VideoIntroLine>
        {i18n("Introduction from John Piper")}
      </VideoIntroLine>

    </Container>
  )
}

export default memo(HomeWhatIs)