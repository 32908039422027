import React, { useMemo, useCallback } from 'react'

import useRefState from '../hooks/useRefState'

export const StudyBibleItemInfoContext = React.createContext({})

export const StudyBibleItemInfoContextProvider = ({ children }) => {

  const [ studyBibleItemInfo, setStudyBibleItemInfo, getStudyBibleItemInfo ] = useRefState({})
  const closeStudyBibleItemInfo = useCallback(
    ({ keyOverride }={}) => {
      setStudyBibleItemInfo({
        ...getStudyBibleItemInfo(),
        anchorEl: null,
        goStraightToEditor: false,
        keyOverride,
      })
    },
    [ setStudyBibleItemInfo, getStudyBibleItemInfo ],
  )

  const value = useMemo(
    () => ({
      studyBibleItemInfo: {
        ...studyBibleItemInfo,
        onClose: closeStudyBibleItemInfo,
      },
      setStudyBibleItemInfo,
      getStudyBibleItemInfo,
      closeStudyBibleItemInfo,
    }),
    [ studyBibleItemInfo, setStudyBibleItemInfo, getStudyBibleItemInfo, closeStudyBibleItemInfo ]
  )

  return (
    <StudyBibleItemInfoContext.Provider value={value}>
      {children}
    </StudyBibleItemInfoContext.Provider>
  )
}