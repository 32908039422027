import { memo, useCallback } from 'react'
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import EditIcon from '@material-ui/icons/Edit'
import styled from 'styled-components'

import { GET_DEFAULT_JOURNEY } from './BibleMap'
import useLayoutEffectAsync from '../../../hooks/useLayoutEffectAsync'
import useRefState from '../../../hooks/useRefState'

const filter = createFilterOptions()

const Container = styled.div`
  margin-bottom: 7px;
  display: flex;
  align-items: center;
  gap: 10px;
`

const StyledAutocomplete = styled(Autocomplete)`
  flex: 1;
`

const StyledIconButton = styled(IconButton)`
  padding: 8px;

  .MuiSvgIcon-root {
    font-size: 17px;
  }
`

const BibleMapEditorSelectJourney = ({
  journeyId,
  journeys,
  setSelectedJourney,
  doUpdateMapLayer,
  onEventChange,
}) => {

  const [ value, setValue, getValue ] = useRefState(``)

  useLayoutEffectAsync(
    () => {
      setValue(journeys.find(({ id }) => id === journeyId) || ``)
    },
    [ journeys, journeyId ],
  )

  const onChange = useCallback(
    (event, newValue) => {
      if(typeof newValue === 'string') {
        const matchingJourney = journeys.find(({ names }) => names[0].name.toLowerCase() === newValue.toLowerCase())
        if(matchingJourney) {
          newValue = matchingJourney
        } else {
          newValue = { inputValue: newValue }
        }
      }

      if((newValue || {}).inputValue) {
        const journey = GET_DEFAULT_JOURNEY()
        journey.names[0].name = newValue.inputValue
        onEventChange({ keys: [ `journeyId` ], newValue: journey.id })
        doUpdateMapLayer({ journey })
        setTimeout(() => setSelectedJourney(journey))
      } else {
        onEventChange({ keys: [ `journeyId` ], newValue: (newValue || {}).id || null })
      }

      event.target.blur()
    },
    [ journeys, doUpdateMapLayer, setSelectedJourney, onEventChange ],
  )

  const filterOptions = useCallback(
    (options, params) => {
      const filtered = filter(options, params)

      if(
        params.inputValue !== ''
        && !journeys.some(({ names }) => names[0].name.toLowerCase() === params.inputValue.toLowerCase())
      ) {
        filtered.push({
          inputValue: params.inputValue,
          title: i18n("Add “{{value}}”", { value: params.inputValue }),
        })
      }

      return filtered
    },
    [ journeys ],
  )

  const getOptionLabel = useCallback(
    option => {
      if(typeof option === 'string') {
        return option
      }
      if(option.inputValue) {
        return option.inputValue
      }
      return option.names[0].name
    },
    [],
  )

  const goEdit = useCallback(
    () => {
      if(getValue().id) {
        setSelectedJourney(getValue())
      }
    },
    [ setSelectedJourney, getValue ],
  )

  const renderOption = useCallback(option => option.title || option.inputValue || option.names[0].name, [])

  return (
    <Container>

      <StyledAutocomplete
        value={value}
        onChange={onChange}
        filterOptions={filterOptions}
        options={journeys}
        getOptionLabel={getOptionLabel}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderOption={renderOption}
        freeSolo
        size="small"
        renderInput={params => (
          <TextField
            {...params}
            label={i18n("Journey")}
            variant="outlined"
          />
        )}
      />

      <StyledIconButton
        onClick={goEdit}
        disabled={!value}
      >
        <EditIcon />
      </StyledIconButton>

    </Container>
  )
}

export default memo(BibleMapEditorSelectJourney)