import { useMemo } from "react"
import { useThrottle } from 'react-use'

import { addJourneyColorToEvents, cloneObj, getPrimaryDate, timeBetweenMapDates } from "../utils/misc"
import eras from "../components/pages/map/eras"
import { MAX_MAP_SCALE } from "./useDragMap"
import { getApproxRect as getApproxRectCity } from "../components/pages/map/BibleMapPlaceCity"
import { getApproxRect as getApproxRectProvince } from "../components/pages/map/BibleMapPlaceProvince"
import { getApproxRect as getApproxRectKingdom } from "../components/pages/map/BibleMapPlaceKingdom"
import { getApproxRect as getApproxRectSea } from "../components/pages/map/BibleMapPlaceSea"
import { getApproxRect as getApproxRectDesert } from "../components/pages/map/BibleMapPlaceDesert"
import { getApproxRect as getApproxRectArea } from "../components/pages/map/BibleMapPlaceArea"
import { getApproxRect as getApproxRectMountain } from "../components/pages/map/BibleMapPlaceMountain"
import { getApproxRect as getApproxRectSpot } from "../components/pages/map/BibleMapPlaceSpot"

const getApproxRect = place => (
  (
    {
      CITY: getApproxRectCity,
      PROVINCE: getApproxRectProvince,
      KINGDOM: getApproxRectKingdom,
      SEA: getApproxRectSea,
      DESERT: getApproxRectDesert,
      AREA: getApproxRectArea,
      MOUNTAIN: getApproxRectMountain,
      SPOT: getApproxRectSpot,
    }[place.type] || getApproxRectSpot
  )(place)
)

const {
  timelineStartDate: BIBLE_TIMELINE_START_DATE,
  timelineEndDate: BIBLE_TIMELINE_END_DATE,
} = eras.find(({ id }) => id === `bible`)
const TOTAL_DAYS_IN_BIBLICAL_HISTORY = timeBetweenMapDates(BIBLE_TIMELINE_START_DATE, BIBLE_TIMELINE_END_DATE).totalDays

const MAX_BUFFER_IN_DAYS = 400 * 360
const MIN_BUFFER_IN_DAYS = 15 * 360

const useContextAdjustedMapLayer = ({
  mapLayer,
  stage,
  fromDate,
  toDate,
  getPlaceInfo,
  selectedPlace,
  journeyDateRangeById,
  colorByJourneyId,
  showJourneys,
  eventsByJourneyId,
 }) => {

  const fromDateAfterThrottle = useThrottle(fromDate, 50)
  const toDateAfterThrottle = useThrottle(toDate, 50)
  const selectedPlaceId = !(selectedPlace || {}).closed && (selectedPlace || {}).id

  const data = useMemo(
    () => {

      const data = cloneObj(mapLayer.data || {})
      data.places = data.places || []
      data.journeys = data.journeys || []
      data.persons = data.persons || []
      addJourneyColorToEvents({ places: data.places, colorByJourneyId })
      data.allPlaces = cloneObj(data.places)
      data.allJourneys = cloneObj(data.journeys)

      if(stage === `PUBLISHED`) {

        const buffer = (
          toDateAfterThrottle
            ? 0
            : (timeBetweenMapDates(fromDateAfterThrottle, BIBLE_TIMELINE_END_DATE).totalDays * ((MAX_BUFFER_IN_DAYS - MIN_BUFFER_IN_DAYS) / TOTAL_DAYS_IN_BIBLICAL_HISTORY) + MIN_BUFFER_IN_DAYS)
        )

        // filter which journeys to show, depending on the current date range
        data.journeys = data.journeys.filter(journey => {

          if(!showJourneys) return false
          if(!journeyDateRangeById[journey.id]) return false

          const [ startDate, endDate ] = journeyDateRangeById[journey.id].split(` - `).map(d => d.replace(/ \[.*$/, ``))
          const daysAfterStart = timeBetweenMapDates(fromDateAfterThrottle, endDate || startDate).totalDays
          const daysBeforeEnd = timeBetweenMapDates(startDate, toDateAfterThrottle || fromDateAfterThrottle).totalDays
          const minDaysAway = (
            daysAfterStart >= 0 && daysBeforeEnd >= 0
              ? -1
              : Math.max(daysAfterStart, daysBeforeEnd)
          )

          if(minDaysAway < buffer) {
            if(buffer > 0) {
              journey.opacity = Math.min((1 - minDaysAway/buffer) * 2, 1)
            } else {
              journey.opacity = 1
            }
            return true
          }

          return false

        })

        const visibleJourneyIds = {}
        data.journeys.forEach(({ id }) => {
          visibleJourneyIds[id] = true
        })

        // filter which places to show, depending on the current date range
        data.places = data.places.filter(place => {

          const { type, events, levelOfImportance, dateRange, dateRanges } = place
          place.totalLevelOfImportanceFromRelevantEvents = 1
          const afterTheFlood = timeBetweenMapDates("2348 BC", toDateAfterThrottle || fromDateAfterThrottle).totalDays >= 0
          const bibleEra = eras.find(({ id }) => id === `bible`)
          const pseudoDateRangeForArea = [ `AREA` ].includes(type) ? `${bibleEra.timelineStartDate} - ${bibleEra.timelineEndDate}` : ``

          if(place.id === selectedPlaceId) {
            place.opacity = 1
            return true
          }

          if(
            [
              "CITY",
              "SPOT",
              "MOUNTAIN",
            ].includes(type)
            && (
              events.some(event => visibleJourneyIds[event.journeyId])
            )
          ) {
            place.alwaysShowDot = true
          }

          if(
            [
              "SEA",
              "DESERT",
              "MOUNTAIN",
              "RIVER",
            ].includes(type)
            && afterTheFlood
          ) {
            place.opacity = 1
            return true
          }

          if(
            [
              "KINGDOM",
              "PROVINCE",
              "AREA",
            ].includes(type)
          ) {
            if(
              (dateRanges || dateRange || pseudoDateRangeForArea).split(/\n/g).filter(Boolean).some(dateRangeWithKingdomId => {
                const [ establishmentDate, disestablishmentDate ] = dateRangeWithKingdomId.replace(/ \| .*$/, ``).split(` - `)
                return (
                  disestablishmentDate
                  && timeBetweenMapDates(fromDateAfterThrottle, disestablishmentDate).totalDays >= 0
                  && timeBetweenMapDates(establishmentDate, toDateAfterThrottle || fromDateAfterThrottle).totalDays >= 0
                )
              })
            ) {
              place.opacity = 1
              return true
            } else {
              return false
            }
          }

          let minDaysAway = Infinity
          for(let event of events) {
            const [ startDate, endDate ] = ((showJourneys && journeyDateRangeById[event.journeyId]) || getPrimaryDate(event)).split(` - `).map(d => d.replace(/ \[.*$/, ``))
            const daysAfterStart = timeBetweenMapDates(fromDateAfterThrottle, endDate || startDate).totalDays
            const daysBeforeEnd = timeBetweenMapDates(startDate, toDateAfterThrottle || fromDateAfterThrottle).totalDays
            const minDaysAwayThisEvent = (
              daysAfterStart >= 0 && daysBeforeEnd >= 0
                ? -1
                : Math.max(daysAfterStart, daysBeforeEnd)
            )
            if(minDaysAwayThisEvent < buffer) {
              place.totalLevelOfImportanceFromRelevantEvents += event.levelOfImportance || 0
            }
            minDaysAway = Math.min(minDaysAway, minDaysAwayThisEvent)

          }

          if(minDaysAway < buffer) {
            if(buffer > 0) {
              place.opacity = Math.min((1 - minDaysAway/buffer) * 2, 1)
            } else {
              place.opacity = 1
            }
            return true
          }

          if(levelOfImportance >= 9 && afterTheFlood) {
            place.opacity = .55
            place.totalLevelOfImportanceFromRelevantEvents = levelOfImportance - 8
            return true
          }

          return false

        })

        data.journeys.forEach(({ id }) => {
          const placeId = eventsByJourneyId[id][0].place.id
          const firstPlaceInJourney = data.places.find(({ id }) => id === placeId)
          if(firstPlaceInJourney) {
            firstPlaceInJourney.journeyColor = colorByJourneyId[id]
          }
        })

        // calculate map scale at which point to show each place
        const calcPriority = ({ id, opacity, totalLevelOfImportanceFromRelevantEvents }) => (id === selectedPlaceId ? Infinity : (opacity * totalLevelOfImportanceFromRelevantEvents))
        data.places.sort((a,b) => calcPriority(b) - calcPriority(a))

        // try to place at map scale level 1; for whatever did not fit, try more zoomed in until all placed
        const usedRects = []
        const updateRect = ({ rect, mapScale }) => {
          const { scale, left, top } = getPlaceInfo({ ...rect, mapScale })
          const { x, y, width, height } = rect.approxRect
          rect.x = left + x * scale
          rect.y = top + y * scale
          rect.width = width * scale
          rect.height = height * scale
        }

        let mapScale = 1
        let placesLeftToPlace = [ ...data.places ]

        while(placesLeftToPlace.length > 0 && mapScale < MAX_MAP_SCALE) {

          placesLeftToPlace = placesLeftToPlace.filter(place => {  // eslint-disable-line no-loop-func

            if([ `RIVER`, `KINGDOM`, `PROVINCE`, `AREA`, `SEA`, `DESERT` ].includes(place.type)) {
              place.visibleFromScaleLevel = parseInt(place.minDisplayScale || 10, 10)
              return false
            }

            place.approxRect = place.approxRect || getApproxRect(place)
            const { approxRect } = place
            const rect = { place, approxRect }
            updateRect({ rect, mapScale })

            // check if rect overlaps with any usedRects
            if(usedRects.some(usedRect => (
              rect.x < usedRect.x + usedRect.width
              && rect.x + rect.width > usedRect.x
              && rect.y < usedRect.y + usedRect.height
              && rect.y + rect.height > usedRect.y
            ))) {
              // has overlap, try again at next scale level
              return true
            } else {
              place.visibleFromScaleLevel = mapScale
              usedRects.push(rect)
              return false
            }

          })

          mapScale *= 1.1

          // adjust usedRects
          usedRects.forEach(rect => updateRect({ rect, mapScale }))  // eslint-disable-line no-loop-func

        }

        placesLeftToPlace.forEach(place => {
          place.visibleFromScaleLevel = MAX_MAP_SCALE
        })

      }

      return data
    },
    [ mapLayer.data, stage, fromDateAfterThrottle, toDateAfterThrottle, getPlaceInfo, selectedPlaceId, journeyDateRangeById, colorByJourneyId, showJourneys, eventsByJourneyId ],
  )

  return data
}

export default useContextAdjustedMapLayer