import React, { memo, useCallback, useMemo, useRef } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { isRTLText } from "@bibletags/bibletags-ui-helper"
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import { useThrottle } from 'react-use'

import useStudyBibleItems from '../../hooks/useStudyBibleItems'
import useVersionInfo from '../../hooks/useVersionInfo'
import useRefState from '../../hooks/useRefState'
import useChannelPriorities from '../../hooks/useChannelPriorities'
import useSetStudyBibleItemInfo from '../../hooks/useSetStudyBibleItemInfo'
import useSetChannelItemInfo from '../../hooks/useSetChannelItemInfo'
import useAppSize from '../../hooks/useAppSize'
import useSetTimeout from '../../hooks/useSetTimeout'
import useEffectAsync from '../../hooks/useEffectAsync'
import useMapLayers from '../../hooks/useMapLayers'
import { cloneObj, dedup } from '../../utils/misc'

import StudyBibleItemThumbnail from './StudyBibleItemThumbnail'
import StudyBibleItemsPassageRef from './StudyBibleItemsPassageRef'
import StudyBibleFollowAddButton from './StudyBibleFollowAddButton'
import Loading from '../common/Loading'
import PassageStudyBibleNotesPieces from './PassageStudyBibleNotesPieces'
import PassageStudyBibleNotesMeasureContainer from './PassageStudyBibleNotesMeasureContainer'
import PassageStudyBibleViewOrMoreLink from './PassageStudyBibleViewOrMoreLink'

// All heights include any desired margin to follow
const MORE_LINK_HEIGHT = 25
const PASSAGE_REF_HEIGHT = 30
const MINI_MORE_LINK_HEIGHT = 44
const MINI_HEIGHT = {
  EVENT: 102,
  default: 134,
}

const getMiniHeight = ({ type }) => MINI_HEIGHT[type] || MINI_HEIGHT.default
const getMiniHeightTotal = studyBibleItems => studyBibleItems.reduce((acc, studyBibleItem) => acc + getMiniHeight(studyBibleItem), 0)

const getCombinedLoc = ({ fromLoc, toLoc }) => `${fromLoc || ``}-${toLoc || ``}`
const numVerses = ({ fromLoc, toLoc }) => parseInt(toLoc, 10) - parseInt(fromLoc, 10)

const Container = styled.div`
  width: ${({ $mini }) => $mini ? 26 : 208}px;
  margin-right: 10px;
  margin-left: -10px;
  direction: ltr;
  position: relative;

  ${({ $isRTL }) => !$isRTL ? `` : `
    margin-right: -10px;
    margin-left: 20px;
  `}
`

const RefDotPassageStudyBibleViewOrMoreLink = styled(PassageStudyBibleViewOrMoreLink)`
  && {
    text-decoration: none;
    background-color: ${({ theme }) => theme.palette.grey[300]};
    width: 32px;
    height: 32px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    letter-spacing: -.5px;
    overflow: hidden;
    font-size: 13px;
    font-weight: 500;
    color: rgb(0 0 0/.75);

    ${({ isRTL }) => !isRTL ? `` : `
      margin-left: -14px;
    `}

  }
`

const LookDownArrow = styled.div`
  border-left: 1px dashed ${({ theme }) => theme.palette.grey[400]};
  width: 1px;
  height: ${({ $lookDownArrowHeight }) => $lookDownArrowHeight}px;
  position: absolute;
  right: 50%;
  top: 50px;

  ${({ $isRTL }) => !$isRTL ? `` : `
    margin-right: 10px;
  `}

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: -8px;
    width: 15px;
    height: 15px;
    border: 1px dashed ${({ theme }) => theme.palette.grey[400]};
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
  }
`

const None = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  text-align: center;
  max-width: 100px;
  margin: 0 auto;
  font-weight: 300;

  ${({ $mini }) => !$mini ? `` : `
    transform-origin: top right;
    transform: rotate(90deg);
    white-space: nowrap;
    position: relative;
    top: 20px;
  `}
`

const PassageStudyBibleNotes = ({
  chapterInfo,
  versionId,
  width,
  channelIdOrUrl,
  relativeYAndHeightByLoc,
}) => {

  const mini = width < 600
  const { height: windowHeight, miniHeaderSize } = useAppSize()

  const { studyBibleItems, loading } = useStudyBibleItems({
    chapterInfo,
    channelIdOrUrl,
  })
  const setStudyBibleItemInfo = useSetStudyBibleItemInfo()
  const setChannelItemInfo = useSetChannelItemInfo()

  const { channelPriorities } = useChannelPriorities()

  const [ ready, setReady ] = useRefState(false)
  const [ setSetReadyTimeout ] = useSetTimeout()
  const [ heightById, setHeightById, getHeightById ] = useRefState({})
  const heightByIdAfterThrottle = useThrottle(heightById, 50)
  const containerRef = useRef()

  const { version:{ languageId }={} } = useVersionInfo(versionId) || {}
  const { bookId } = getRefFromLoc(`${chapterInfo.originalChapterLocs[0]}001`)
  const isRTL = isRTLText({ languageId, bookId })

  const layer = `BIBLE`
  const stage = `PUBLISHED`
  const { getMapLayerState } = useMapLayers({ layer, stage })
  const [ mapLayer ] = getMapLayerState({ layer, stage })

  const studyBibleItemsWidthEvents = useMemo(
    () => {

      const { places=[] } = mapLayer.data || {}

      return [
        ...(studyBibleItems || []),
        ...(
          dedup(
            (
              places
                .map(place => (
                  place.events.map(event => {
                    const { passages } = event
                    return (
                      passages
                        .map(({ fromLoc, toLoc }) => ({
                          ...event,
                          type: `EVENT`,
                          fromLoc,
                          toLoc,
                          place,
                        }))
                        .filter(chapterInfo.filterForVersion)
                    )
                  })
                ))
                .flat(2)
                .filter(Boolean)
            ),
            ({ id }) => id
          )
        ),
      ]

    },
    [ mapLayer, studyBibleItems, chapterInfo ],
  )

  // pieces to include: all studyBibleItems (including invisible) + ref headings (with and without view links) + more links
  const pieces = useMemo(
    () => {

      const numDownloadsByChannelAndId = {}
      const adminRatingByTypeAndId = {}
      const idOrderingByChannel = {}

      const alteredRelativeYAndHeightByLoc = (
        Object.values(relativeYAndHeightByLoc || {}).length > 0
          ? relativeYAndHeightByLoc
          : {
            y: 0,
            height: document.body.clientHeight,
          }
      )
      const sortedRelativeYAndHeightByLocKeys = Object.keys(alteredRelativeYAndHeightByLoc).sort()
      const lastRelativeYAndHeight = alteredRelativeYAndHeightByLoc[sortedRelativeYAndHeightByLocKeys.at(-1)]
      const getRelativeYAndHeightByLoc = (loc=``) => (
        alteredRelativeYAndHeightByLoc[loc]
        || alteredRelativeYAndHeightByLoc[
          sortedRelativeYAndHeightByLocKeys.find(locKey => locKey > loc)
          || [ ...sortedRelativeYAndHeightByLocKeys ].reverse().find(locKey => locKey < loc)
        ]
      )

      const getIdealAndPermissibleYs = ({ fromLoc, toLoc }) => {
        // determine idealY and permissibleYRange
        const relativeYAndHeightOfFromLoc = getRelativeYAndHeightByLoc(fromLoc)
        const relativeYAndHeightOfToLoc = getRelativeYAndHeightByLoc(toLoc)
        const relativeYAndHeight = {
          y: relativeYAndHeightOfFromLoc.y,
          height: ((relativeYAndHeightOfToLoc.y - relativeYAndHeightOfFromLoc.y) + relativeYAndHeightOfToLoc.height),
        }
        const idealY = relativeYAndHeight.y - 10
        const permissibleYRange = [
          Math.max(15, relativeYAndHeight.y - 150),
          relativeYAndHeight.y + relativeYAndHeight.height + 100,
        ]
        return {
          idealY,
          permissibleYRange,
        }
      }

      studyBibleItemsWidthEvents.forEach(({ id, type, studyBibleFollow, info, adminRating }) => {
        if(type === `CHANNEL`) {
          const studyBibleFollowId = studyBibleFollow.id
          numDownloadsByChannelAndId[studyBibleFollowId] = numDownloadsByChannelAndId[studyBibleFollowId] || {}
          numDownloadsByChannelAndId[studyBibleFollowId][id] = (info || {}).downloads || 0
        } else {
          adminRatingByTypeAndId[type] = adminRatingByTypeAndId[type] || {}
          adminRatingByTypeAndId[type][id] = adminRating || 0
        }
      })

      for(let studyBibleFollowId in numDownloadsByChannelAndId) {
        idOrderingByChannel[studyBibleFollowId] = (
          Object.keys(numDownloadsByChannelAndId[studyBibleFollowId])
            .sort((a,b) => numDownloadsByChannelAndId[studyBibleFollowId][a] - numDownloadsByChannelAndId[studyBibleFollowId][b])
        )
      }

      for(let type in adminRatingByTypeAndId) {
        idOrderingByChannel[type] = (
          Object.keys(adminRatingByTypeAndId[type])
            .sort((a,b) => adminRatingByTypeAndId[type][a] - adminRatingByTypeAndId[type][b])
        )
      }

      const prioritizedStudyBibleItems = (

        studyBibleItemsWidthEvents

          // filter it out if it hasn't been measured yet
          .filter(({ id }) => mini || heightByIdAfterThrottle[id])

          // give each a priorityScore
          .map(studyBibleItem => {

            let priorityScore = 0
            const { id, type, studyBibleFollow, levelOfImportance=1 } = studyBibleItem

            const studyBibleFollowIdOrType = type === `CHANNEL` ? studyBibleFollow.id : type
            const idOrderingForThisChannel = idOrderingByChannel[studyBibleFollowIdOrType]

            if(((studyBibleItem.reactions || {}).myReactionTypes || []).some(type => /^BOOKMARK:/.test(type))) {
              // current user has hearted it
              priorityScore += 10000  // i.e. always showed items you have hearted first
            }

            if(idOrderingForThisChannel.slice(0,2).includes(id)) {
              // it is the among the top two in a channel, so increase score based on channel priority
              const channelPriorityFactor = channelPriorities.length - channelPriorities.findIndex(({ type, studyBibleFollowId }) => studyBibleFollowIdOrType.includes(studyBibleFollowId || type))
              priorityScore += channelPriorityFactor * 15
            }

            // increase score based number of hearts from other Biblearc users
            const numBookmarks = Object.keys(studyBibleItem.reactions || {}).filter(type => /^BOOKMARK:/.test(type)).length
            const numHearts = (studyBibleItem.reactions || {}).HEART || 0
            priorityScore += (numHearts + numBookmarks) * 3

            if(type === `EVENT`) {
              // increase score based on importance of the event
              priorityScore += levelOfImportance
            } else {
              // increase score based on ordering within this channel (based on downloads or adminLevel)
              const orderingFactor = idOrderingForThisChannel.length - idOrderingForThisChannel.indexOf(id)
              priorityScore += orderingFactor * 1
            }

            return {
              studyBibleItem,
              priorityScore,
            }

          })

          // sort by priorityScore
          .sort((a,b) => b.priorityScore - a.priorityScore)

      )

      const getGroupHeight = group => (
        mini
          ? (
            getMiniHeightTotal(group.studyBibleItems)
            + (group.hiddenStudyBibleItems.length > 0 ? MINI_MORE_LINK_HEIGHT : 0)
          )
          : (
            PASSAGE_REF_HEIGHT
            + group.studyBibleItems.map(({ id }) => heightByIdAfterThrottle[id]).reduce((a,b) => a+b, 0)
            + (group.hiddenStudyBibleItems.length > 0 ? MORE_LINK_HEIGHT : 0)
          )
      )
    
      const getGroupLocs = ({ studyBibleItems, hiddenStudyBibleItems }) => (
        studyBibleItems.length > 0
          ? {
            fromLoc: studyBibleItems[0].fromLoc,
            toLoc: studyBibleItems[0].toLoc,
          }
          : {
            fromLoc: hiddenStudyBibleItems.reduce((loc, { fromLoc }) => (!loc || fromLoc < loc) ? fromLoc : loc, null),
            toLoc: hiddenStudyBibleItems.reduce((loc, { toLoc }) => (!loc || toLoc > loc) ? toLoc : loc, null),
          }
      )

      // start with everything hidden
      let visibleGroups = []
      if(studyBibleItemsWidthEvents.length > 0) {
        visibleGroups.push({
          studyBibleItems: [],
          hiddenStudyBibleItems: studyBibleItemsWidthEvents,
          y: getRelativeYAndHeightByLoc(studyBibleItemsWidthEvents.fromLoc).y
        })
      }

      prioritizedStudyBibleItems.forEach(({ studyBibleItem }) => {

        let newVisibleGroups = cloneObj(visibleGroups)
        const groupsToPlace = []

        // extract relevant studyBibleItems
        const additionalExtractedStudyBibleItems = []  // extract the one we are looking for, along with any others with the same loc range
        const groupExtractedFrom = newVisibleGroups.find(group => {
          const numHiddenBefore = group.hiddenStudyBibleItems.length
          group.hiddenStudyBibleItems = group.hiddenStudyBibleItems.filter(hiddenStudyBibleItem => {
            if(hiddenStudyBibleItem.id === studyBibleItem.id) {
              return false
            } else if(
              hiddenStudyBibleItem.fromLoc >= studyBibleItem.fromLoc
              && hiddenStudyBibleItem.toLoc <= studyBibleItem.toLoc
            ) {
              additionalExtractedStudyBibleItems.push(hiddenStudyBibleItem)
              return false
            } else {
              return true
            }
          })
          return group.hiddenStudyBibleItems.length < numHiddenBefore
        })

        // see if there is an existing group to put this in; otherwise make a new group
        const groupToInsertInto = newVisibleGroups.find(group => getCombinedLoc(getGroupLocs(group)) === getCombinedLoc(studyBibleItem))
        if(groupToInsertInto) {
          groupToInsertInto.studyBibleItems.push(studyBibleItem)
          groupToInsertInto.hiddenStudyBibleItems.push(...additionalExtractedStudyBibleItems)
          newVisibleGroups = newVisibleGroups.filter(group => group !== groupToInsertInto)
          groupsToPlace.push(groupToInsertInto)
        } else {
          groupsToPlace.push({
            studyBibleItems: [ studyBibleItem ],
            hiddenStudyBibleItems: additionalExtractedStudyBibleItems,
          })
        }

        // remove, split, or mark groupExtractedFrom for adjustment
        if(groupExtractedFrom.studyBibleItems.length === 0) {

          newVisibleGroups = newVisibleGroups.filter(group => group !== groupExtractedFrom)

          if(groupExtractedFrom.hiddenStudyBibleItems.length !== 0) {

            const before = groupExtractedFrom.hiddenStudyBibleItems.filter(({ toLoc }) => toLoc < studyBibleItem.fromLoc)
            const after = groupExtractedFrom.hiddenStudyBibleItems.filter(({ fromLoc }) => fromLoc > studyBibleItem.toLoc)

            if(
              before.length
              && after.length
              && before.length + after.length === groupExtractedFrom.hiddenStudyBibleItems.length
            ) {
              groupsToPlace.push(
                {
                  studyBibleItems: [],
                  hiddenStudyBibleItems: before,
                },
                {
                  studyBibleItems: [],
                  hiddenStudyBibleItems: after,
                },
              )
            } else {
              groupsToPlace.push(groupExtractedFrom)
            }

          }
        }

        groupsToPlace.sort((a,b) => numVerses(a) - numVerses(b))  // do the narrow ranges first

        for(let groupToPlace of groupsToPlace) {

          const { fromLoc, toLoc } = getGroupLocs(groupToPlace)
          const height = getGroupHeight(groupToPlace)

          const { idealY, permissibleYRange } = getIdealAndPermissibleYs({ fromLoc, toLoc })

          // calc space between each
          const slotInfoByIdx = newVisibleGroups.map((visibleGroup, idx) => {
            const prevVisibleGroup = newVisibleGroups[idx-1]
            const slotY = prevVisibleGroup ? (prevVisibleGroup.y + getGroupHeight(prevVisibleGroup)) : 0
            const slotHeight = newVisibleGroups[idx].y - slotY
            const { fromLoc, toLoc } = (
              idx === 0
                ? { fromLoc: ``, toLoc: `` }
                : getGroupLocs(newVisibleGroups[idx-1])
            )
            return {
              slotHeight,
              slotY,
              idx,
              prevFromLocToLoc: getCombinedLoc({ fromLoc, toLoc }),
            }
          })
          const lastVisibleGroup = newVisibleGroups.at(-1)
          const slotY = lastVisibleGroup ? (lastVisibleGroup.y + getGroupHeight(lastVisibleGroup)) : 0
          slotInfoByIdx.push({
            slotHeight: (lastRelativeYAndHeight.y + lastRelativeYAndHeight.height) - slotY,
            slotY,
            idx: slotInfoByIdx.length,
            prevFromLocToLoc: lastVisibleGroup ? getGroupLocs(lastVisibleGroup).fromLoc : `-`,
          })

          const wouldKeepVersesInProperOrder = ({ prevFromLocToLoc, idx }) => (
            getCombinedLoc({ fromLoc, toLoc }) >= prevFromLocToLoc
            && (idx === slotInfoByIdx.length - 1 || getCombinedLoc({ fromLoc, toLoc }) <= slotInfoByIdx[idx+1].prevFromLocToLoc)
          )

          const isWithinPermissibleRange = ({ slotY, slotHeight }) => (
            slotY <= permissibleYRange[1]
            && slotY + slotHeight >= permissibleYRange[0]
          )

          // look for the first big enough in permissible ranges
          const firstIndexWithEnoughSpaceBefore = slotInfoByIdx.findIndex(({ slotHeight, slotY, prevFromLocToLoc }, idx) => (
            wouldKeepVersesInProperOrder({ prevFromLocToLoc, idx })
            && isWithinPermissibleRange({ slotY, slotHeight })
            && slotHeight >= height
          ))


          if(firstIndexWithEnoughSpaceBefore !== -1) {
            const { slotHeight, slotY } = slotInfoByIdx[firstIndexWithEnoughSpaceBefore]
            const maxY = slotY + (slotHeight - getGroupHeight(groupToPlace))
            newVisibleGroups.splice(
              firstIndexWithEnoughSpaceBefore,
              0,
              {
                ...groupToPlace,
                y: Math.min(Math.max(slotY, idealY), maxY),
              },
            )
            continue
          }

          // look for the biggest in permissible ranges
          let { idx: indexToAttemptToSpliceInto=-1 } = (
            slotInfoByIdx
              .filter(({ slotHeight, slotY, prevFromLocToLoc }, idx) => (
                wouldKeepVersesInProperOrder({ prevFromLocToLoc, idx })
                && isWithinPermissibleRange({ slotY, slotHeight })
              ))
              .sort((a,b) => b.slotHeight - a.slotHeight)
              .at(0)
          ) || {}

          if(indexToAttemptToSpliceInto === -1) {
            // get spot nearest to permissible range with the proper vs num
            const getDistanceFromPermissibleRange = ({ slotY, slotHeight }) => Math.min(
              slotY - permissibleYRange[1],
              permissibleYRange[0] - (slotY + slotHeight),
            )
            let { idx=-1 } = (
              slotInfoByIdx
                .filter(({ prevFromLocToLoc }, idx) => wouldKeepVersesInProperOrder({ prevFromLocToLoc, idx }))
                .sort((a,b) => getDistanceFromPermissibleRange(a) - getDistanceFromPermissibleRange(b))
                .at(0)
            ) || {}

            if(idx !== -1) {
              indexToAttemptToSpliceInto = idx
            }
          }

          if(indexToAttemptToSpliceInto !== -1) {

            // push in ideal direction and see if I can get it all
            const { slotY, slotHeight } = slotInfoByIdx[indexToAttemptToSpliceInto]
            const middleYOfSpace = slotY + slotHeight/2

            let neededDirectionalAdjustmentUp = Math.max(slotY - permissibleYRange[1], 0)  // amount that groups above this slot must be shifted up
            let neededDirectionalAdjustmentDown = Math.max(permissibleYRange[0] - slotY + height - slotHeight, 0)  // amount that groups below this slot must be shifted down

            const getTotalNeededDirectionalAdjustment = () => neededDirectionalAdjustmentUp + neededDirectionalAdjustmentDown
            let neededExtraEitherWay = Math.max(height - slotHeight - getTotalNeededDirectionalAdjustment(), 0)

            const pushUp = () => {
              let attemptedPushAmount = neededDirectionalAdjustmentUp + neededExtraEitherWay
              if(attemptedPushAmount <= 0) return
              if(indexToAttemptToSpliceInto === 0) return

              const initialY = newVisibleGroups[indexToAttemptToSpliceInto-1].y

              // loop up (from one before insert slot idx)
              let idx1, idx2
              for(idx1=indexToAttemptToSpliceInto-1; idx1>=0; idx1--) {
                // push to be above the one below (or above the starting desired position)
                newVisibleGroups[idx1].y -= attemptedPushAmount
                attemptedPushAmount -= slotInfoByIdx[idx1].slotHeight
                // break if previous does not overlap
                if(attemptedPushAmount <= 0) break
                if(idx1 === 0) break
              }

              // loop down (from where I reached in the prev loop)
              let minReadjustment = 0
              for(idx2=idx1; idx2<=indexToAttemptToSpliceInto-1; idx2++) {
                // push down to permissible range
                const smallestPossibleY = getIdealAndPermissibleYs(getGroupLocs(newVisibleGroups[idx2])).permissibleYRange[0]
                const readjustment = Math.max(smallestPossibleY - newVisibleGroups[idx2].y, minReadjustment)
                minReadjustment = Math.max(readjustment, minReadjustment)
                newVisibleGroups[idx2].y += readjustment
              }

              const amountAdjusted = initialY - newVisibleGroups[indexToAttemptToSpliceInto-1].y
              neededExtraEitherWay -= Math.max(amountAdjusted - neededDirectionalAdjustmentUp, 0)
              neededDirectionalAdjustmentUp = Math.max(neededDirectionalAdjustmentUp - amountAdjusted, 0)
            }

            const pushDown = () => {
              let attemptedPushAmount = neededDirectionalAdjustmentDown + neededExtraEitherWay
              if(attemptedPushAmount <= 0) return
              if(indexToAttemptToSpliceInto === newVisibleGroups.length) return

              const initialY = newVisibleGroups[indexToAttemptToSpliceInto].y

              // loop down (from insert slot idx)
              let idx1, idx2
              for(idx1=indexToAttemptToSpliceInto; idx1<newVisibleGroups.length; idx1++) {
                // push to be below the one above it (or below the starting desired position)
                newVisibleGroups[idx1].y += attemptedPushAmount
                attemptedPushAmount -= slotInfoByIdx[idx1+1].slotHeight
                // break if next does not overlap
                if(attemptedPushAmount <= 0) break
                if(idx1 === newVisibleGroups.length-1) break
              }

              // loop up (from where I reached in the prev loop)
              let minReadjustment = 0
              for(idx2=idx1; idx2>=indexToAttemptToSpliceInto; idx2--) {
                // push up to permissible range
                const biggestPossibleY = getIdealAndPermissibleYs(getGroupLocs(newVisibleGroups[idx2])).permissibleYRange[1]
                const readjustment = Math.max(newVisibleGroups[idx2].y - biggestPossibleY, minReadjustment)
                minReadjustment = Math.max(readjustment, minReadjustment)
                newVisibleGroups[idx2].y -= readjustment
              }

              const amountAdjusted = newVisibleGroups[indexToAttemptToSpliceInto].y - initialY
              neededExtraEitherWay -= Math.max(amountAdjusted - neededDirectionalAdjustmentDown, 0)
              neededDirectionalAdjustmentDown = Math.max(neededDirectionalAdjustmentDown - amountAdjusted, 0)
            }

            // go one direction or the other, then do the second
            if(middleYOfSpace > idealY) {
              pushUp()
              pushDown()
            } else {
              pushDown()
              pushUp()
            }

            if(getTotalNeededDirectionalAdjustment() === 0 && neededExtraEitherWay === 0) {
              const prevVisibleGroup = newVisibleGroups[indexToAttemptToSpliceInto-1]
              const slotY = prevVisibleGroup ? (prevVisibleGroup.y + getGroupHeight(prevVisibleGroup)) : 0
              const nextVisibleGroup = newVisibleGroups[indexToAttemptToSpliceInto]
              const slotHeight = nextVisibleGroup ? (nextVisibleGroup.y - slotY) : height
              const y = slotY + (slotHeight - height)
              if(
                slotHeight >= height
                && y >= permissibleYRange[0]
                && y <= permissibleYRange[1]
              ) {
                newVisibleGroups.splice(
                  indexToAttemptToSpliceInto,
                  0,
                  {
                    ...groupToPlace,
                    y,
                  },
                )
                continue
              } else {
                console.log(`Unexpected non-insert`, slotHeight, height, y, slotY, permissibleYRange)
              }
            }

          }

          return
        }

        visibleGroups = newVisibleGroups

      })

      visibleGroups.forEach(({ hiddenStudyBibleItems }) => hiddenStudyBibleItems.sort((a,b) => numVerses(a) - numVerses(b)))  // do the narrow ranges first

      const topForExtras = (visibleGroups.length > 0 ? (visibleGroups.at(-1).y + getGroupHeight(visibleGroups.at(-1))) : (mini ? 190 : 30)) + 80
      let delay = 1
      const getDelay = () => mini ? 0 : (delay++) * 100

      return [

        ...(
          visibleGroups
            .sort((a, b) => a.y - b.y)
            .map(group => {

              const { studyBibleItems, hiddenStudyBibleItems, y } = group
              const { fromLoc, toLoc } = getGroupLocs(group)
              const doMiniSummaryDot = mini && hiddenStudyBibleItems.length > 0
              const sumHeights = (total, { id }) => total + heightByIdAfterThrottle[id]

              return [
                ...(mini ? [] : [{
                  key: `ref:${getCombinedLoc({ fromLoc, toLoc })}`,
                  top: y,
                  delay: getDelay(),
                  children: (
                    <StudyBibleItemsPassageRef
                      fromLoc={fromLoc}
                      toLoc={toLoc}
                      convertToVersionId={versionId}
                    />
                  ),
                }]),
                ...studyBibleItems.map((studyBibleItem, idx) => ({
                  key: `studyBibleItem:${studyBibleItem.id}`,
                  top: y + (mini ? (idx * getMiniHeight(studyBibleItem)) : (PASSAGE_REF_HEIGHT + studyBibleItems.slice(0, idx).reduce(sumHeights, 0))),
                  delay: getDelay(),
                  children: (
                    <StudyBibleItemThumbnail
                      studyBibleItem={studyBibleItem}
                      versionId={versionId}
                      mini={mini}
                      isRTL={isRTL}
                      setItemInfo={studyBibleItem.type === `CHANNEL` ? setChannelItemInfo : setStudyBibleItemInfo}
                    />
                  ),
                })),
                ...((mini || hiddenStudyBibleItems.length === 0) ? [] : [{
                  key: `${studyBibleItems.length > 0 ? `more` : `view`}-link:${getCombinedLoc({ fromLoc, toLoc })}`,
                  top: y + PASSAGE_REF_HEIGHT + studyBibleItems.reduce(sumHeights, 0),
                  delay: getDelay(),
                  children: (
                    <PassageStudyBibleViewOrMoreLink
                      isMoreLink={studyBibleItems.length > 0}
                      hiddenStudyBibleItems={hiddenStudyBibleItems}
                      setStudyBibleItemInfo={setStudyBibleItemInfo}
                      setChannelItemInfo={setChannelItemInfo}
                      versionId={versionId}
                    />
                  ),
                }]),
                ...(!doMiniSummaryDot ? [] : [{
                  key: `mini-summary-dot:${getCombinedLoc({ fromLoc, toLoc })}`,
                  top: y + getMiniHeightTotal(studyBibleItems),
                  delay: getDelay(),
                  children: (
                    <RefDotPassageStudyBibleViewOrMoreLink
                      hiddenStudyBibleItems={hiddenStudyBibleItems}
                      setStudyBibleItemInfo={setStudyBibleItemInfo}
                      setChannelItemInfo={setChannelItemInfo}
                      versionId={versionId}
                      isRTL={isRTL}
                    >
                      {hiddenStudyBibleItems.length}
                    </RefDotPassageStudyBibleViewOrMoreLink>
                  ),
                }]),
              ]
            })
            .flat()
        ),

        ...(!(visibleGroups.length === 0 && !loading) ? [] : [{
          key: `none`,
          top: 30,
          delay: getDelay(),
          children: (
            <None $mini={mini} className="PassageStudyBibleNotes-None">
              {(!channelIdOrUrl && bookId < 40) &&i18n("OT study Bible entries in development.")}
              {!(!channelIdOrUrl && bookId < 40) && i18n("No study Bible entries for this chapter.")}
            </None>
          ),
        }]),

        ...(!!channelIdOrUrl ? [] : [{
          key: `add-button`,
          top: topForExtras,
          delay: getDelay(),
          children: (
            <StudyBibleFollowAddButton
              mini={mini}
              isRTL={isRTL}
              $hide={loading}
            />
          ),
        }]),

        ...(!loading ? [] : [
          {
            key: `loading`,
            top: topForExtras,
            delay: 0,
            children: <Loading size={mini ? 20 : 26} />,
          },
          ...((visibleGroups.length > 0 || !channelIdOrUrl) ? [] : [{
            key: `retrieving`,
            top: 160,
            delay: getDelay(),
            children: <None $mini={mini} className="PassageStudyBibleNotes-None">{i18n("Syncing with sermon host...")}</None>,
          }]),
        ]),

      ]

    },
    [ studyBibleItemsWidthEvents, relativeYAndHeightByLoc, versionId, mini, heightByIdAfterThrottle, channelPriorities, loading, channelIdOrUrl, setStudyBibleItemInfo, setChannelItemInfo, isRTL, bookId ],
  )

  const reportHeight = useCallback(
    ({ id, height }) => {
      setHeightById({
        ...getHeightById(),
        [id]: height,
      })
    },
    [ setHeightById, getHeightById ],
  )

  useEffectAsync(
    () => {
      const goSetReady = () => {
        const pageContainerEl = containerRef.current.closest(`.LazyLoadPageViewer-PageContainer`)
        if(
          pageContainerEl
          && !pageContainerEl.nextSibling  // i.e. not in animation to next page
        ) {
          setReady(true)
        }
      }
      setSetReadyTimeout(goSetReady, 500)
    },
    [],
  )

  const lookDownArrowHeight = (
    ((pieces[0] || {}).top || 0) > windowHeight - (miniHeaderSize ? 85 : 140)
      ? windowHeight - (miniHeaderSize ? 150 : 200)
      : null
  )

  return (
    <Container
      $mini={mini}
      $isRTL={isRTL}
      ref={containerRef}
    >

      {ready && lookDownArrowHeight &&
        <LookDownArrow
          $lookDownArrowHeight={lookDownArrowHeight}
          $isRTL={isRTL}
        />
      }

      {ready && !mini && studyBibleItemsWidthEvents.filter(({ id }) => !heightByIdAfterThrottle[id]).map(studyBibleItem => (
        <PassageStudyBibleNotesMeasureContainer
          key={studyBibleItem.id}
          id={studyBibleItem.id}
          reportHeight={reportHeight}
        >
          <StudyBibleItemThumbnail
            studyBibleItem={studyBibleItem}
            versionId={versionId}
            mini={mini}
            isRTL={isRTL}
          />
        </PassageStudyBibleNotesMeasureContainer>
      ))}

      {ready &&
        <PassageStudyBibleNotesPieces
          pieces={pieces}
        />
      }

    </Container>
  )
}

export default memo(PassageStudyBibleNotes)