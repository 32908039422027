import { memo, useContext } from 'react'
// import { i18n } from 'inline-i18n'
// import styled from 'styled-components'

import useSimpleToggle from '../../hooks/useSimpleToggle'
import useLayoutEffectAsync from '../../hooks/useLayoutEffectAsync'
import { StudyBibleItemInfoContext } from '../../context/StudyBibleItemInfo'
import { LoggedInUserContext } from '../../context/LoggedInUser'

import StudyBibleItem from '../study-bible/StudyBibleItem'
import ExpandPopover from '../common/ExpandPopover'
import StudyBibleItemEditor from '../study-bible/StudyBibleItemEditor'

const StudyBibleItemPopover = () => {

  const { studyBibleItemInfo } = useContext(StudyBibleItemInfoContext)

  const {
    anchorEl,
    goStraightToEditor,
    studyBibleItem,  // warning: this will not be up-to-date if edited by StudyBibleItemEditor; but that should be okay, since from the editor the user can only close
    versionId,
    onClose,
    keyOverride,
    ...otherProps
  } = studyBibleItemInfo

  const { adminLevel } = useContext(LoggedInUserContext) || {}
  const showEditIcon = [ 'ADMIN', 'EDITOR', 'CONTRIBUTOR' ].includes(adminLevel)

  const [ showEdit, toggleShowEdit ] = useSimpleToggle()

  useLayoutEffectAsync(
    () => {
      if(goStraightToEditor) {
        toggleShowEdit({ force: true })
        onClose()
      }
    },
    [ goStraightToEditor ],
  )

  useLayoutEffectAsync(
    () => {
      if(anchorEl && showEdit) {
        onClose()
      }
    },
    [ anchorEl, showEdit ],
  )

  return (
    <>

      <ExpandPopover
        key={`ExpandPopover:${keyOverride || (studyBibleItem || {}).id || `-`}`}
        onClose={onClose}
        anchorEl={anchorEl}
        // anchorSide="left"
        {...otherProps}
      >
        <StudyBibleItem
          studyBibleItem={studyBibleItem}
          goShowEdit={showEditIcon && toggleShowEdit}
          onClose={onClose}
          versionId={versionId}
          fromThumbnail
        />
      </ExpandPopover>

      {[ 'ADMIN', 'EDITOR', 'CONTRIBUTOR' ].includes(adminLevel) &&
        <StudyBibleItemEditor
          key={`StudyBibleItemEditor:${(studyBibleItem || {}).id}`}
          open={showEdit}
          id={(studyBibleItem || {}).id}
          versionId={versionId}
          onClose={toggleShowEdit}
        />
      }

    </>
  )
}

export default memo(StudyBibleItemPopover)