import { memo, useState, useCallback, useContext, useLayoutEffect } from 'react'
import { Switch, Route, useLocation } from "react-router-dom"
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'

import useIsLoggedIn from '../../hooks/useIsLoggedIn'
import useInstanceValue from '../../hooks/useInstanceValue'
import useEffectAsync from '../../hooks/useEffectAsync'
import useDataQuery from '../../hooks/useDataQuery'
import useLayoutEffectAsync from '../../hooks/useLayoutEffectAsync'
import { ChannelIdInPWAContext } from '../../context/ChannelIdInPWA'
import { BSB_MANIFEST_URL, IS_EMBED } from '../../utils/constants'
import { isPWA, isIOS, isAndroid, updateRelatedApps, getBsbIsAlreadyInstalledAsPWA } from '../../utils/misc'

import Home from '../pages/home/Home'
import BibleMap from '../pages/map/BibleMap'
import Projects from '../pages/projects/Projects'
import Project from '../pages/project/Project'
import Shared from '../pages/shared/Shared'
import StudyBibleItemPage from '../pages/study-bible/StudyBibleItemPage'
import MessagePage from '../pages/message/MessagePage'
import ChurchBibles from '../pages/church-bibles/ChurchBibles'
import AboutChurchBibles from '../pages/about-church-bibles/AboutChurchBibles'
import ChurchBiblesFAQ from '../pages/church-bibles-faq/ChurchBiblesFAQ'
import CreateAChurchBible from '../pages/create-a-church-bible/CreateAChurchBible'
import Channel from '../pages/channel/Channel'
import ChannelAdmin from '../pages/channel-admin/ChannelAdmin'
// import Contact from '../pages/contact/Contact'
import Settings from '../pages/settings/Settings'
import Tagger from '../pages/tag/Tagger'
import Admin from '../pages/admin/Admin'
import FAQ from '../pages/faq/FAQ'
import Versions from '../pages/versions/Versions'
import Version from '../pages/version/Version'
import GrammarSearchKey from '../pages/grammar-search-key/GrammarSearchKey'
import Terms from '../pages/terms/Terms'
import Privacy from '../pages/privacy/Privacy'
import Subscribe from '../pages/subscribe/Subscribe'
import Donate from '../pages/donate/Donate'
import GiveAGift from '../pages/give-a-gift/GiveAGift'
import AboutTheBiblearcBible from '../pages/about-the-biblearc-bible/AboutTheBiblearcBible'
import AboutTheBiblearcStudyBible from '../pages/about-the-biblearc-study-bible/AboutTheBiblearcStudyBible'
import AboutMyBSB from '../pages/about-my-bsb/AboutMyBSB'
import AboutTools from '../pages/about-tools/AboutTools'
import YourMyBSBSubscription from '../pages/your-my-bsb-subscription/YourMyBSBSubscription'
import YourEquipSubscription from '../pages/your-equip-subscription/YourEquipSubscription'
import YourToolsSubscription from '../pages/your-tools-subscription/YourToolsSubscription'
import WhateverYouCanAfford from '../pages/whatever-you-can-afford/WhateverYouCanAfford'
import RequestFreeTools from '../pages/request-free-tools/RequestFreeTools'
import GroupSubscriptions from '../pages/group-subscriptions/GroupSubscriptions'
import GroupSubscriptionsFAQ from '../pages/group-subscriptions-faq/GroupSubscriptionsFAQ'
import ActionCode from '../pages/action-code/ActionCode'
import NotFound from '../pages/not-found/NotFound'
import Install from '../pages/install/Install'
import Embed from '../pages/embed/Embed'
import Loading from './Loading'
import PrintPreviewOrDownload from './PrintPreviewOrDownload'
import Sketch from './Sketch'
import StudyBibleItemPopover from '../passage/StudyBibleItemPopover'
import ChannelItemSnackbar from '../passage/ChannelItemSnackbar'
import DarkModeGlobalStyle from './DarkModeGlobalStyle'
import PartnershipStatementOfFaith from '../pages/partnership-statement-of-faith/PartnershipStatementOfFaith'
import NotificationsSnackbar from './NotificationsSnackbar'

import channelQuery from '../../graphql/queries/channel'

const AppContainer = styled.div`
  width: 100vw;  // keeping this for older browsers
  height: 100vh;  // keeping this for older browsers
  width: 100dvw;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${({ $inTools }) => !$inTools ? `` : `
    @media print {
      display: none;
    }
  `}
`

const PrintViaModuleMenu = styled.div`
  display: none;

  @media print {
    display: block;
    padding: 150px 100px 30px;
    font-size: 25px;
    font-style: italic;
    text-align: center;
  }
`

export const getRouteInfoByPath = path => {
  const routeInfoByPath = {
    '/': {
      title: i18n("The Biblearc Study Bible"),
      Component: Home,
    },
    '/install': {
      title: i18n("Install"),
      Component: Install,
    },
    '/version/:id/:book': {
      Component: Home,
    },
    '/version/:id/:book/:chapter': {
      Component: Home,
    },
    '/map': {
      title: i18n("Bible Map + Timeline"),
      Component: BibleMap,
    },
    '/projects': {
      title: i18n("Projects"),
      Component: Projects,
      exact: false,
      requiresLogin: true,
    },
    '/project/:id': {
      Component: Project,
      requiresLogin: true,
    },
    '/shared/:id': {
      Component: Shared,
    },
    '/study-bible/:id': {
      Component: StudyBibleItemPage,
    },
    '/message/:id': {
      Component: MessagePage,
    },
    '/admin': {
      title: i18n("Admin"),
      Component: Admin,
      requiresLogin: true,
    },
    '/faq': {
      title: i18n("Frequently Asked Questions"),
      Component: FAQ,
    },
    // '/contact': {
    //   title: i18n("Contact Us"),
    //   Component: Contact,
    // },
    '/settings': {
      title: i18n("Settings"),
      Component: Settings,
      exact: false,
    },
    '/tag': {
      title: i18n("Tag Hebrew and Greek"),
      Component: Tagger,
    },
    '/versions': {
      title: i18n("Versions"),
      Component: Versions,
    },
    '/version/:id': {
      Component: Version,
    },
    '/grammar-search-key': {
      title: i18n("Grammatical Detail Search Key"),
      Component: GrammarSearchKey,
    },
    '/terms': {
      title: i18n("Terms"),
      Component: Terms,
    },
    '/privacy': {
      title: i18n("Privacy"),
      Component: Privacy,
    },
    '/subscribe': {
      title: i18n("Subscription Pricing"),
      Component: Subscribe,
    },
    '/donate': {
      title: i18n("Give a Donation"),
      Component: Donate,
    },
    '/whatever-you-can-afford': {
      title: i18n("Whatever-You-Can-Afford"),
      Component: WhateverYouCanAfford,
    },
    '/request-free-tools': {
      title: i18n("Request a Free Biblearc TOOLS Subscription"),
      Component: RequestFreeTools,
      requiresLogin: true,
    },
    '/group-subscriptions': {
      title: i18n("Group Subscriptions"),
      Component: GroupSubscriptions,
    },
    '/group-subscriptions-faq': {
      title: i18n("Group Subscriptions FAQ"),
      Component: GroupSubscriptionsFAQ,
    },
    '/action-code': {
      title: "Biblearc",
      Component: ActionCode,
    },
    '/about-my-bsb': {
      title: i18n("About My Biblearc Study Bible"),
      Component: AboutMyBSB,
      requiresLogin: false,
    },
    '/about-tools': {
      title: i18n("About Biblearc TOOLS"),
      Component: AboutTools,
      requiresLogin: false,
    },
    '/about-the-biblearc-bible': {
      title: i18n("About The Biblearc Bible"),
      Component: AboutTheBiblearcBible,
      requiresLogin: false,
    },
    '/about-the-biblearc-study-bible': {
      title: i18n("About The Biblearc Study Bible"),
      Component: AboutTheBiblearcStudyBible,
      requiresLogin: false,
    },
    '/your-my-bsb-subscription': {
      title: i18n("Your “My Biblearc Study Bible” Subscription"),
      Component: YourMyBSBSubscription,
      requiresLogin: true,
    },
    '/your-tools-subscription': {
      title: i18n("Your Biblearc TOOLS Subscription"),
      Component: YourToolsSubscription,
      requiresLogin: true,
    },
    '/your-equip-subscription': {
      title: i18n("Your Biblearc EQUIP Subscription"),
      Component: YourEquipSubscription,
      requiresLogin: true,
    },
    '/give-a-gift': {
      title: i18n("Gift Certificates"),
      Component: GiveAGift,
    },
    '/embed/:mode/:id': {
      title: "Biblearc",
      Component: Embed,
    },
    '/partnership-statement-of-faith': {
      title: i18n("Biblearc’s Partnership Statement of Faith"),
      Component: PartnershipStatementOfFaith,
    },
    '/church-bibles': {
      title: i18n("Church-Specific Study Bibles"),
      Component: ChurchBibles,
    },
    '/about-church-bibles': {
      title: i18n("About Church-Specific Study Bibles"),
      Component: AboutChurchBibles,
    },
    '/church-bibles-faq': {
      title: i18n("Church-Specific Study Bibles FAQ"),
      Component: ChurchBiblesFAQ,
    },
    '/create-a-church-bible': {
      title: i18n("Create a Study Bible App for Your Church"),
      Component: CreateAChurchBible,
    },
    '/church/:id': {
      Component: Channel,
    },
    '/church/:id/admin': {
      title: i18n("Admin Settings"),
      Component: ChannelAdmin,
    },
    '/church/:id/faq': {
      title: i18n("Church-Specific Study Bibles FAQ"),
      Component: ChurchBiblesFAQ,
    },
    '/church/:id/install': {
      title: i18n("Install"),
      Component: Install,
    },
    // the rest of the items are common to CSSB and the normal app and must be added to NavLinkOrAWithDisable and SearchModal as well (search for "message|map|settings|tag")
    '/church/:id/message/:id': {
      Component: MessagePage,
    },
    '/church/:id/map': {
      title: i18n("Bible Map + Timeline"),
      Component: BibleMap,
    },
    '/church/:id/settings': {
      title: i18n("Settings"),
      Component: Settings,
      exact: false,
    },
    '/church/:id/tag': {
      title: i18n("Tag Hebrew and Greek"),
      Component: Tagger,
    },
    '/church/:id/version/:id': {
      Component: Version,
    },
  }

  if(!path) return routeInfoByPath

  return(
    routeInfoByPath[path]
    || routeInfoByPath[path.replace(/[^/]+$/, ':id')]
    || routeInfoByPath[path.replace(/^\/church\/[^/]+\//, '/church/:id/')]
  )
}

const routesToForward = [
  {
    pathRegex: /^\/church$/,
    replace: `/church-bibles`,
  },
  {
    pathRegex: /^\/original-language-words$/,
    replace: `/`,
  },
]

const Routes = ({ ...props }) => {

  const location = useLocation()
  const routeInfoByPath = getRouteInfoByPath()
  const history = useHistory()
  const isLoggedIn = useIsLoggedIn()

  const [ printPreviewOrDownloadInfo, setPrintPreviewOrDownloadInfo ] = useState()
  const getPrintPreviewOrDownloadInfo = useInstanceValue(printPreviewOrDownloadInfo)
  const closePrintPreviewOrDownload = useCallback(
    () => setPrintPreviewOrDownloadInfo({
      ...getPrintPreviewOrDownloadInfo(),
      closing: true,
    }),
    [ getPrintPreviewOrDownloadInfo ],
  )

  const [ sketchInfo, setSketchInfo ] = useState()
  const getSketchInfo = useInstanceValue(sketchInfo)
  const closeSketch = useCallback(
    () => setSketchInfo({
      ...getSketchInfo(),
      closing: true,
    }),
    [ getSketchInfo ],
  )

  const [ installOutcome, setInstallOutcome ] = useState()

  const { channelIdInPWA, setChannelIdInPWA } = useContext(ChannelIdInPWAContext)

  const channelId = (location.pathname.match(/^\/church\/([^/]+)/) || [])[1]
  const { channel } = useDataQuery({
    channelQuery,
    variables: {
      id: channelId,
    },
    sticky: true,
    skip: !channelId,
  })

  useEffectAsync(
    () => {
      let { title } = getRouteInfoByPath(location.pathname) || {}
      if(title) {
        if(title !== 'Biblearc') {
          title += ' | Biblearc'
        }
        document.title = title
      }
    },
    [ location ],
  )

  useEffectAsync(
    () => {
      if(IS_EMBED && !/^\/embed\//.test(location.pathname)) {
        throw new Error(`Cannot navigate a Biblearc task.`)
      }
    },
    [ location ],
  )

  const { requiresLogin } = getRouteInfoByPath(location.pathname) || {}
  const needsRedirect = !!(requiresLogin && !isLoggedIn)
  const inTools = /^\/project\/[^/]+$/.test(location.pathname)
  const hasTrailingSlash = /\/$/.test(location.pathname)

  useLayoutEffectAsync(
    async () => {
      const channelIdOnLoad = (location.pathname.match(/^\/church\/([^/]+)/) || [])[1]
      const searchParams = new URLSearchParams(location.search)
      if(isPWA && channelIdOnLoad && searchParams.get('church_app') === ``) {
        // for iOS, it only need to get to this line on the first load (see ChannelIdInPWA)
        setChannelIdInPWA(channelIdOnLoad)
      } else if(isPWA && isAndroid && channelIdOnLoad) {
        setTimeout(async () => {
          await updateRelatedApps()
          setChannelIdInPWA(
            getBsbIsAlreadyInstalledAsPWA()
              ? null
              : channelIdOnLoad
          )
        }, 300)
      } else {
        setChannelIdInPWA(null)
      }
      // Note: if I need this back for some reason, I must put it in a timeout
      // if(channelIdOnLoad) {
      //   searchParams.delete(`church_app`)
      //   history.replace({
      //     ...location,
      //     search: searchParams.toString(),
      //   })
      // }
    },
    [],
  )

  useLayoutEffectAsync(
    () => {
      if(hasTrailingSlash) {
        history.replace({
          ...location,
          pathname: location.pathname.replace(/\/$/, ``),
        })
      }
    },
    [ hasTrailingSlash ],
  )

  useEffectAsync(
    () => {
      if(needsRedirect) {
        const searchParams = new URLSearchParams(window.location.search)
        if(searchParams.get(`autologin`) === null) {
          history.replace(`/`)
        }
      }
    },
    [ needsRedirect ],
  )

  useEffectAsync(
    () => {
      const routeToForward = routesToForward.find(({ pathRegex }) => pathRegex.test(location.pathname))
      if(routeToForward) {
        history.replace({
          ...location,
          pathname: location.pathname.replace(routeToForward.pathRegex, routeToForward.replace),
        })
      }
    },
    [ location.pathname ],
  )

  const isOnInstallPage = /^\/install$/.test(location.pathname)
  const installChannelId = (location.pathname.match(/^\/church\/([^/]+)\/install$/) || [])[1]
  let updatedAtForInstallChannel = ``
  if(channel && channel.id === installChannelId) {
    // to prevent an old cached version from being used, use the manifest with -updatedAt
    updatedAtForInstallChannel = channel.updatedAt
  }
  const bsbAlreadyInstalled = new URLSearchParams(location.search).get('bsb_already_installed')

  useLayoutEffect(  // prompt for BSB or channel install
    () => {

      if(installChannelId && !updatedAtForInstallChannel) return
      
      if(bsbAlreadyInstalled === ``) {
        setInstallOutcome(`bsb-already-installed`)
        return
      }

      const manifestUrl = (
        installChannelId
        ? `/manifests/${installChannelId}-${updatedAtForInstallChannel}.json`
        : BSB_MANIFEST_URL
      )
      const manifestEl = document.head.querySelector(`link[rel="manifest"]`)
      manifestEl.setAttribute(`href`, manifestUrl)

      if(!isOnInstallPage && !installChannelId) return

      let eventWasFired
      const onBeforeInstallPrompt = async event => {
        eventWasFired = true
        window.removeEventListener("beforeinstallprompt", onBeforeInstallPrompt)
        event.preventDefault()
        try {
          const { outcome } = await event.prompt()
          setInstallOutcome(outcome)
        } catch(e) {}  // catch just in case this is a direct load on Android (not expected)
      }
      window.addEventListener("beforeinstallprompt", onBeforeInstallPrompt)

      // If the app is already installed, the event will never fire.
      // Attempt to check the situation by fetching the manifest, waiting a moment, then assuming it is already installed.
      fetch(manifestUrl).then(() => {
        setTimeout(() => {
          if(!eventWasFired) {
            setInstallOutcome(`already-installed`)
          }
        }, 1000)
      })

      return () => window.removeEventListener("beforeinstallprompt", onBeforeInstallPrompt)

    },
    [ isOnInstallPage, installChannelId, updatedAtForInstallChannel, bsbAlreadyInstalled ],
  )

  useLayoutEffectAsync(  // disallow direct load to install or channel install page on Android
    () => {
      if(isAndroid && (isOnInstallPage || installChannelId)) {
        history.replace(`/`)
      }
    },
    [],
  )

  useLayoutEffectAsync(  // just in case: prevent nav to install or channel install from within pwa
    () => {
      if(isPWA && (isOnInstallPage || installChannelId)) {
        history.replace(channelIdInPWA ? `/church/${channelIdInPWA}` : `/`)
      }
    },
    [ isOnInstallPage, installChannelId ],
  )
 
  useLayoutEffectAsync(  // update ios splash images
    async () => {
      if(isIOS && (channelIdInPWA || installChannelId)) {
        // iOS PWA splashscreens
        document.head.querySelectorAll(`link[rel="apple-touch-startup-image"]`).forEach(el => {
          el.setAttribute(
            `href`,
            (
              el.getAttribute(`href`)
                .replace(
                  /\/splashscreens\//,
                  `/cssb_splashscreens/`,
                )
            ),
          )
        })
      }
    },
    [],
  )

  useEffectAsync(  // update favicon
    () => {
      if(channel) {
        const { id, updatedAt } = channel
        document.head.querySelector(`link[rel="icon"]`).setAttribute(`href`, `${process.env.REACT_APP_ASSETS_URI}${id}-favicon_144-${updatedAt}.png`)
      } else {
        document.head.querySelector(`link[rel="icon"]`).setAttribute(`href`, `/favicon_144.png`)
      }
    },
    [ channel ],
  )

  useEffectAsync(  // update ios app icon
    () => {
      if(channel && channel.id === installChannelId) {
        const { id, updatedAt } = channel || {}
        const appleTouchIconEl = document.head.querySelector(`link[rel="apple-touch-icon"]`)
        appleTouchIconEl.setAttribute(`href`, `${process.env.REACT_APP_ASSETS_URI}${id}-app_icon_ios_1024-${updatedAt}.png`)
      }
    },
    [ channel, installChannelId ],
  )

  if(needsRedirect) return <Loading />

  const printOrDownloadDialogOpen = printPreviewOrDownloadInfo && !printPreviewOrDownloadInfo.closing
  const sketchDialogOpen = sketchInfo && !sketchInfo.closing

  return (
    <>

      <DarkModeGlobalStyle />

      <AppContainer
        className="Routes-AppContainer"
        $inTools={inTools}
      >
        <Switch>

          {Object.keys(routeInfoByPath).map(path => {
            const { Component, exact=true, ...routeInfo } = routeInfoByPath[path]
            return (
              <Route
                key={path}
                exact={exact}
                path={path}
              >
                <Component
                  {...routeInfo}
                  {...props}
                  goPrintOrDownload={setPrintPreviewOrDownloadInfo}
                  goSketch={setSketchInfo}
                  printOrDownloadDialogOpen={printOrDownloadDialogOpen}
                  sketchDialogOpen={sketchDialogOpen}
                  installOutcome={installOutcome}
                />
              </Route>
            )
          })}

          <Route>
            <NotFound {...props} />
          </Route>

        </Switch>
      </AppContainer>

      <PrintPreviewOrDownload
        info={printPreviewOrDownloadInfo}
        close={closePrintPreviewOrDownload}
      />

      <Sketch
        key={((sketchInfo || {}).module || {}).id || `none`}
        info={sketchInfo}
        close={closeSketch}
      />

      <StudyBibleItemPopover />
      <ChannelItemSnackbar />
      <NotificationsSnackbar />

      {inTools && !printOrDownloadDialogOpen &&
        <PrintViaModuleMenu>
          {i18n("To print, click a module or notes title button and select “Print” in the Actions section.")}
        </PrintViaModuleMenu>
      }

      {inTools && printOrDownloadDialogOpen &&
        <PrintViaModuleMenu>
          {i18n("Cancel here and click the “Next” button to print.")}
        </PrintViaModuleMenu>
      }

    </>
  )
}

export default memo(Routes)