import { memo, useCallback } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import Button from '@material-ui/core/Button'

import Header from "../../common/Header"
import LinkButton from '../../common/LinkButton'
import { isAndroid, isIOS } from '../../../utils/misc'
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const ANDROID_APP_ICON_SIZE = 80
const IOS_APP_ICON_SIZE = 72

const PageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;

  @media (max-width: 850px) {
    display: none;
  }
`

const MobilePageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;
  display: none;

  @media (max-width: 850px) {
    display: block;
  }
`

const HeaderLogoImg = styled.img`
  height: 36px;
  vertical-align: top;
  margin: 5px -4px 0 -6px;
`

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px calc(100vh - 250px);
  background-color: white;
`

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LogoImg = styled.img`
  height: 180px;
  margin: 24px 0 10px;
`

const BigIdea = styled.div`
  text-align: center;
  font-weight: 300;
  margin: -10px 0 10px;
`

const Status = styled.div`
  text-align: center;
  font-weight: 200;
  font-size: 16px;
  margin: 0 0 10px;
`

const GetApp = styled.div`
  text-align: center;
  font-weight: 500;
  font-size: 22px;
  margin: 40px 0 10px;
`

const GetAppContainer = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 20px;
`

const FeaturesHeading = styled.div`
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  &::before, &::after {
    content: "";
    width: 30px;
    height: 1px;
    margin: 0 5px;
    background-image: linear-gradient(to right, rgb(0 0 0/.3) 0%, transparent 100%);
  }

  &::before {
    transform: rotate(180deg);
  }

`

const Features = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
`

const Feature = styled.div`
  margin-top: 30px;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
`
const Gif = styled.img`
  margin: 15px 0 30px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  min-height: min(calc((100vw - 20px) * .5625), 339.5px);
  width: 602px;
  max-width: calc(100vw - 20px);
  display: block;
`

const GoToBiblearcBibleButton = styled(LinkButton)`
  display: block;
  margin: 0 auto 10px;
`

const Note = styled.div`
  margin: 15px 0;
  text-align: center;
  font-size: 15px;
`

const Preview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 5px 0;
`

const AndroidPreview = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #999;
`

const AndroidPreviewImage = styled.img`
  width: ${ANDROID_APP_ICON_SIZE * 1.1}px;
  height: ${ANDROID_APP_ICON_SIZE * 1.1}px;
  margin: -${ANDROID_APP_ICON_SIZE * .1}px;
`

const IOSPreviewImage = styled.img`
  width: ${IOS_APP_ICON_SIZE}px;
  height: ${IOS_APP_ICON_SIZE}px;
  vertical-align: middle;
`

const PreviewText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${IOS_APP_ICON_SIZE * 1.2}px;
  margin-top: 5px;
  font-size: 15px;
`

const Platform = styled.div`
  white-space: nowrap;
  max-width: ${IOS_APP_ICON_SIZE * 1.2}px;
  margin: 0 0 10px;
  font-size: 15px;
  font-weight: 300;
`

const NotInAppStoresContainer = styled.div`
  display: flex;
  align-items: stretch;
  max-width: 600px;
  margin: 10px 0 20px;
`

const LeftBracket = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-width: 1px 0 1px 1px;
  width: 10px;
`

const RightBracket = styled(LeftBracket)`
  border-width: 1px 1px 1px 0;
`

const NotInAppStoresText = styled.div`
  flex: 1;
  padding: 20px 20px 30px;
`

const NotInAppStoresTextLine1 = styled.div`
  text-align: center;
  margin-bottom: 5px;
`

const NotInAppStoresTextLine2 = styled.div`
  text-align: center;
  font-size: 13px;
  font-weight: 300;
`

const Not = styled.span`
  font-weight: bold;
  font-size: 18px;
`

const InstallTheAppButton = styled(Button)`
  padding: 0 5px;
  font-size: 9px;
  line-height: 15px;
  border-radius: 3px;
  vertical-align: top;
  margin: 0 2px 0 1px;
`

const AboutTheBiblearcStudyBible = ({ ...props }) => {

  const { setUserSelectedTourType } = props

  const goTour = useCallback(tourId => setUserSelectedTourType(tourId), [ setUserSelectedTourType ])
  const goBSBTour = useCallback(() => goTour(`bsb`), [ goTour ])
  const goMapTour = useCallback(() => goTour(`map`), [ goTour ])

  return (
    <>

      <Header {...props}>
        <PageTitle>
          {i18nReact("About {{logo}}", {
            logo: <HeaderLogoImg src="/the_biblearc_study_bible_1.svg" />
          })}
        </PageTitle>
        <MobilePageTitle>
          {i18n("About")}
        </MobilePageTitle>
      </Header>

      <Container>
        <Content>

          <LogoImg src="/the_biblearc_study_bible_2.svg" />

          <BigIdea>
            {i18n("Superpowered Bible reading and searching, with maps, a timeline, and study notes.")}
          </BigIdea>

          <GetApp>
            {i18n("Get the app!")}
          </GetApp>

          <GetAppContainer>

            {isIOS &&
              <Preview>
                <IOSPreviewImage
                  src={`/favicon_192.png`}
                  className="dark-mode-exempt"
                />
                <PreviewText>
                  {`Biblearc`}
                </PreviewText>
              </Preview>
            }

            {isAndroid &&
              <Preview>
                <AndroidPreview>
                  <AndroidPreviewImage
                    src={`/app_icon_maskable_512.png`}
                    className="dark-mode-exempt"
                  />
                </AndroidPreview>
                <PreviewText>
                  {`Biblearc`}
                </PreviewText>
              </Preview>
            }

            {!isIOS && !isAndroid &&
              <>
                <Preview>
                  <Platform>
                    {i18n("iOS")}
                  </Platform>
                  <IOSPreviewImage
                    src={`/favicon_192.png`}
                    className="dark-mode-exempt"
                  />
                  <PreviewText>
                    {`Biblearc`}
                  </PreviewText>
                </Preview>
                <Preview>
                  <Platform>
                    {i18n("Android")}
                  </Platform>
                  <AndroidPreview>
                    <AndroidPreviewImage
                      src={`/app_icon_maskable_512.png`}
                      className="dark-mode-exempt"
                    />
                  </AndroidPreview>
                  <PreviewText>
                    {`Biblearc`}
                  </PreviewText>
                </Preview>
              </>
            }

          </GetAppContainer>

          <NotInAppStoresContainer>
            <LeftBracket />
            <NotInAppStoresText>
              <NotInAppStoresTextLine1>
                {i18nReact("{{not}} in the app stores.", {
                  not: (
                    <Not>
                      {i18n("NOT")}
                    </Not>
                  ),
                })}
              </NotInAppStoresTextLine1>
              <NotInAppStoresTextLine2>
                {i18nReact("Just open {{biblearc_com}} on your phone and click {{install_the_app_button}}.", {
                  biblearc_com: (
                    <NavLinkOrAWithDisable to="/">
                      biblearc.com
                    </NavLinkOrAWithDisable>  
                  ),
                  install_the_app_button: (
                    <InstallTheAppButton
                      variant="contained"
                      disableElevation
                      color="secondary"
                      size="small"
                    >
                      {i18n("Install the App")}
                    </InstallTheAppButton>
                  ),
                })}
              </NotInAppStoresTextLine2>
            </NotInAppStoresText>
            <RightBracket />
          </NotInAppStoresContainer>

          <Features>

            <FeaturesHeading>
              {i18n("Features")}
            </FeaturesHeading>

            <Feature>
              {i18n("Curated study Bible entries with graphically displayed insights")}
              <Status>
                {i18n("(NT now available. OT in development.)")}
              </Status>
              <Gif src="/features_entries.gif" />
            </Feature>
            <Feature>
              {i18n("An interactive Map + Timeline for all biblical history")}
              <Gif src="/features_map.gif" />
            </Feature>
            <Feature>
              {i18n("Read from 150+ versions")}
              <Gif src="/features_versions.gif" />
            </Feature>
            <Feature>
              {i18n("Utilize publisher cross-references and notes")}
              <Gif src="/features_cfs.gif" />
            </Feature>
            <Feature>
              {i18n("Compare versions and passages")}
              <Gif src="/features_compare.gif" />
            </Feature>
            <Feature>
              {i18n("Study with the original languages, including full Greek and Hebrew parsing")}
              <Gif src="/features_original.gif" />
            </Feature>
            <Feature>
              {i18n("Access our exhaustive ancient variant apparatus for the Greek NT")}
              <Gif src="/features_variants.gif" />
            </Feature>
            <Feature>
              {i18n("Search anything")}
              <Gif src="/features_search.gif" />
            </Feature>

          </Features>

          <Note>
            {i18nReact("Note: The Biblearc Study Bible does {{not}} require a subscription (or even an account).", {
              not: (
                <b>
                  {i18n("NOT")}
                </b>
              ),
            })}
          </Note>

          <GoToBiblearcBibleButton
            to="/"
            variant="contained"
            color="primary"
            disableElevation
          >
            {i18n("Go to The Biblearc Study Bible")}
          </GoToBiblearcBibleButton>

          <GoToBiblearcBibleButton
            to="/"
            variant="outlined"
            color="primary"
            onClick={goBSBTour}
          >
            {i18n("Tour the Study Bible")}
          </GoToBiblearcBibleButton>

          <GoToBiblearcBibleButton
            to="/map"
            variant="outlined"
            color="primary"
            onClick={goMapTour}
          >
            {i18n("Tour the Map + Timeline")}
          </GoToBiblearcBibleButton>

        </Content>
      </Container>

    </>
  )
}


export default memo(AboutTheBiblearcStudyBible)