const fields = `
  id
  note
  info
  shared
  fromLoc
  fromCharacterNumber
  toLoc
  toCharacterNumber
  createdAt
  savedAt
  userId
  versionId
`

export default fields