import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'

import useIsLoggedIn from '../../../hooks/useIsLoggedIn'
import useEffectAsync from '../../../hooks/useEffectAsync'

import Header from "../../common/Header"
import LinkButton from '../../common/LinkButton'
import Loading from '../../common/Loading'

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px 80px;
  background-color: white;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-image: url("https://s3.amazonaws.com/cdn.gospelpaths.com/tenants/5/1638548421112-caleydimmockHCpwe1Prcunsplash1.jpg");

`

const Content = styled.div`
  flex: 1;
  display: flex;
  z-index: 1;
  position: relative;
`

const CheckoutContainer = styled.div`
  width: 516px;
  margin-top: 10px;
  padding-bottom: 20px;
  position: relative;
  border-radius: 7px;
  overflow: hidden;
`

const Checkout = styled.div`
  position: relative;
  min-height: 400px;
`

const PromoContent = styled.div`
  flex: 1;
  max-width: 800px;
  min-width: 40px;
  margin: 30px auto 0 40px;
  font-size: 16px;

  @media (max-width: 850px) {
    margin-left: 10px;
    display: flex;
    transform: rotate(90deg) translate(0, -40px);
    transform-origin: 0 0;
    white-space: nowrap;
    align-items: baseline;
  }
`

const WhiteBGText = styled.div`
  display: inline-block;
  background-color: rgb(255 255 255/.6);
  padding: 5px 10px;
  border-radius: 4px;
`

// const Discount = styled.div`
//   font-size: 55px;
//   line-height: .9;
//   font-weight: 100;
//   color: ${({ theme }) => theme.palette.tertiary.main};

//   @media (max-width: 850px) {
//     font-size: 30px;
//     font-weight: 400;
//   }
// `

// const LimitedTime = styled.div`
//   margin-top: 0;
//   line-height: 15px;
//   font-weight: 100;

//   @media (max-width: 850px) {
//     font-size: 14px;
//   }
// `

const Encouragement = styled.div`
  margin-top: 15px;
  font-size: 20px;

  @media (max-width: 850px) {
    display: none;
  }
`

const About = styled.div`
  margin-top: 15px;
  font-size: 17px;

  @media (max-width: 850px) {
    display: none;
  }
`

const ContactUsMessage = styled.div`
  position: relative;
  margin: 50px 0 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
`

const ContactButton = styled(LinkButton)`
  display: block;
  margin: 0 auto;
`

const GiveAGift = ({ ...props }) => {

  const isLoggedIn = useIsLoggedIn()

  const autologin = isLoggedIn ? `?autologin` : ``

  useEffectAsync(
    () => {
      (function (g, i, f, t, u, p, s) {
          g[u] = g[u] || function() { (g[u].q = g[u].q || []).push(arguments) };
          p = i.createElement(f);
          p.async = 1;
          p.src = t;
          s = i.getElementsByTagName(f)[0];
          s.parentNode.insertBefore(p, s);
      })(window, document, "script", "https://cdn.giftup.app/dist/gift-up.js", "giftup");
    },
    [],
  )

  return (
    <>

      <Header {...props} />

      <Container>

        <Content>

          <CheckoutContainer>

            <Loading />

            <Checkout
              className="gift-up-target"
              data-site-id="ac5f85b2-eba4-46a8-8461-1898eacceadc"
              data-platform="Other"
            />

            <ContactUsMessage>
              {i18n("Need help or have a question?")}
            </ContactUsMessage>

            <ContactButton
              href={`https://equip.biblearc.com/contact${autologin}`}
              color="secondary"
              variant="contained"
            >
              {i18n("Contact us")}
            </ContactButton>

          </CheckoutContainer>

          <PromoContent>
            {/* <Discount>
              <WhiteBGText>
                {i18n("25% off")}
              </WhiteBGText>
            </Discount>

            <LimitedTime>
              <WhiteBGText>
                {i18n("For a limited time.")}
              </WhiteBGText>
            </LimitedTime> */}

            <Encouragement>
              <WhiteBGText>
                {i18n("Strengthen your pastor, enrich your brother or sister in Christ, or provide for a friend to join you in the deep study of Scripture.")}
              </WhiteBGText>
            </Encouragement>

            <About>
              <WhiteBGText>
                {i18n("Biblearc TOOLS is a subscription service for creating Bible study projects with the proven, graphical study methods of Arcing, Bracketing, Phrasing, and Diagramming.")}
              </WhiteBGText>
            </About>

            <About>
              <WhiteBGText>
                {i18n("Biblearc EQUIP is a subscription service for live and self-paced courses in Bible study, along with a learning resources video library. Courses are dynamic and ever-expanding, with multiple levels of interactivity including personal coaching. Course topics range from Bible study methods to book studies to the original languages.")}
              </WhiteBGText>
            </About>

          </PromoContent>

        </Content>
      </Container>

    </>
  )
}


export default memo(GiveAGift)