import { memo, useCallback, useContext } from 'react'
import { i18n } from 'inline-i18n'
import { useLocation, useHistory } from "react-router-dom"
import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import LaunchIcon from '@material-ui/icons/Launch'
import CheckIcon from '@material-ui/icons/Check'
import ShareIcon from '@material-ui/icons/Share'
import LinkIcon from '@material-ui/icons/Link'

import { LoggedInUserContext } from '../../context/LoggedInUser'
import { ChannelIdInPWAContext } from '../../context/ChannelIdInPWA'
import useGoUpdateProject from '../../hooks/useGoUpdateProject'
import useDataQuery from '../../hooks/useDataQuery'
import useAssignmentBlock from '../../hooks/useAssignmentBlock'
import useAppSize from '../../hooks/useAppSize'
import useShareOrCopy from '../../hooks/useShareOrCopy'
import useModalAnchor from '../../hooks/useModalAnchor'
import { shouldRecommendInstall } from '../../utils/misc'

import SubscribedInfoIcon from "./SubscribedInfoIcon"
import SubscribedIndicator from "./SubscribedIndicator"
import NavLinkOrAWithDisable from "./NavLinkOrAWithDisable"
import RecentProjects from "./RecentProjects"
import RecentChurchBibles from './RecentChurchBibles'
import LinkIconButton from './LinkIconButton'
import MainMenuListeningHistory from './MainMenuListeningHistory'
import MainMenuMyBookmarks from './MainMenuMyBookmarks'
import SecondaryHeaderItems from './SecondaryHeaderItems'
import MainMenuHighlights from './MainMenuHighlights'
// import MainMenuSketches from './MainMenuSketches'
import MainMenuMapSnapshots from './MainMenuMapSnapshots'
import MainMenuMapNotes from './MainMenuMapNotes'
import OptionsPopover from './OptionsPopover'

import projectsQuery from '../../graphql/queries/projects'

const Container = styled.div`
  width: 300px;
  max-width: calc(100vw - 50px);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

const MenuItemNavLink = styled(NavLinkOrAWithDisable)`
  ${({ $ignoreActive, theme }) => $ignoreActive !== undefined ? `` : `

    &.active .MuiListItem-button {
      border-left: 5px solid ${theme.palette.primary.dark};
      background-color: transparent;
      cursor: default;
      padding-left: 11px;
    }

    &.active .MuiButtonBase-root {
      display: block;
    }

  `}
`

const FillSpace = styled.div`
  flex: 1;
`

const Copyright = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.palette.text.secondary};
  padding: 20px 0;
`

const SmallLinksLine = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0;
`

const SmallNavLink = styled(NavLinkOrAWithDisable)`
  margin: 0 7px;
  user-select: none;
`

const ResetButtonContainer = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
`

const LogoImg = styled.img`
  height: ${({ $small }) => $small ? 22 : 40}px;
  vertical-align: top;
  margin: 18px;
  align-self: flex-start;
  user-select: none;
`

const MyLogoImg = styled.img`
  height: 30px;
  vertical-align: middle;
  margin: -5px -7px -4px;
  user-select: none;
`

const StyledList = styled(List)`
  position: relative;
  padding: 12px 0;
  z-index: 2;
  border-right: 4px solid ${({ theme }) => theme.palette.grey[400]};
`

const StudyBibleStyledList = styled(StyledList)`
  border-right: 4px solid ${({ theme }) => theme.palette.tertiary.main};

  .MuiListItemText-root .MuiSvgIcon-root {
    opacity: .65;
  }
`

const ToolsStyledList = styled(StyledList)`
  z-index: 1;
  border-right: 4px solid ${({ theme }) => theme.palette.secondary.main};
`

const EquipStyledList = styled(StyledList)`
  z-index: 1;
  border-right: 4px solid ${({ theme }) => theme.palette.primary.main};
`

const SubTypeLogoContainer = styled.div`
  position: relative;
  margin: -6px 0 6px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SectionLogoImg = styled.img`
  height: 30px;
  user-select: none;
`

const MyStudyBibleLogoImg = styled.img`
  height: 32px;
  user-select: none;
  margin: -7.25px -7px -10px;
`

const GetMy = styled.span`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.tertiary.main};
`

const GetTools = styled.span`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.secondary.main};
`

const GetEquip = styled.span`
  font-weight: 300;
  color: ${({ theme }) => theme.palette.primary.main};
`

const MenuItemOpenInNewIcon = styled(OpenInNewIcon)`
  height: 15px;
  color: ${({ theme }) => theme.palette.grey[700]};
  margin-right: -5px;
`

const NewProjectButton = styled(Button)`
  margin: 10px 15px;
  width: calc(100% - 30px);
`

// const ComingSoon = styled.div`
//   text-align: center;
//   color: ${({ theme }) => theme.palette.grey[500]};
//   padding: 30px 0 40px;
// `

const StyledListItemText = styled(ListItemText)`
  p {
    font-size: 11px;
    margin-top: -3px;
    font-weight: 300;
  }
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const LogoContainer2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ $miniHeaderSize }) => $miniHeaderSize ? `flex-start` : `space-between`};
`

const ItemsExcludedInMiniHeader = styled.div`
  align-self: flex-end;
  margin: -10px 15px 10px;
  display: flex;
  align-items: center;
`

const LaunchLinkIconButton = styled(LinkIconButton)`
  margin: 0 10px 0 0;

  .MuiSvgIcon-root {
    font-size: 14px;
  }

  ${({ $miniHeaderSize }) => !$miniHeaderSize ? `` : `
    margin: 1px 0 0 -22px;

    .MuiSvgIcon-root {
      font-size: 12px;
    }
  `}
`

const ItemContent = styled.span`
  white-space: nowrap;
  display: flex;
  align-items: center;

  && .MuiSvgIcon-root {
    opacity: 1;
  }
`

const BSBAppImage = styled.img`
  width: 26px;
  height: 26px;
  margin: -1px 7px -1px 0;
  object-fit: cover;
  border-radius: 5px;
`

const StyledMapIcon = styled.img`
  width: 31px;
  height: 31px;
  margin: -4px 5px -4px -3px;
`

const PoweredByContainer = styled.div`
  display: flex;
  align-items: center;
`

const PoweredBy = styled.div`
  margin: 2.5px -18px 0 16px;
  font-size: 13px;

`

const StyledIconButton = styled(IconButton)`
  padding: 10px;
  margin-left: 2px;
  display: block;

  .MuiSvgIcon-root {
    font-size: 20px;
  }
`

const MainMenu = ({
  toggleDrawerOpen,
  setResetApp,
  setSnackbarInfo,
  setUserSelectedTourType,
  ...otherProps
}) => {

  const { assignmentBlock } = useAssignmentBlock()
  const { channelIdInPWA, channelInPWA } = useContext(ChannelIdInPWAContext)
  const { miniHeaderSize } = useAppSize()
  const location = useLocation()
  const history = useHistory()
  const { anchorEl, openModal, closeModal } = useModalAnchor()

  const user = useContext(LoggedInUserContext)
  const { hasMyPlan, hasToolsPlan, hasEquipPlan } = user || {}

  const autologin = user ? `?autologin` : ``

  const { copyOrShare, willShare, justCopiedOrShared } = useShareOrCopy({
    shareData: (
      channelInPWA
        ? {
          title: i18n("The {{short_name}} Study Bible", { short_name: (channelInPWA || {}).shortName }),
          text: i18n("A Bible app with our church’s sermons next to the passages they teach on!"),
          url: `${document.location.origin}/church/${(channelInPWA || {}).id}`,
        }
        : {
          title: i18n("The Biblearc Study Bible"),
          text: i18n("Read and search the Bible with Greek and Hebrew tools and personalized study Bible entries. For Deeper Study of Scripture."),
          url: document.location.origin,
        }
    ),
  })

  const [ goCreateProject ] = useGoUpdateProject()

  const { projects: { count=0 }={} } = useDataQuery({
    projectsQuery,
    variables: {
      limit: 3,
    },
    skip: !user,
  })

  const goCreateProjectAndShutDrawer = useCallback(
    () => {
      goCreateProject()
      toggleDrawerOpen(false)
    },
    [ goCreateProject, toggleDrawerOpen ],
  )

  const goTour = useCallback(
    () => {
      if(location.pathname !== (channelIdInPWA ? `/church/${channelIdInPWA}` : `/`)) {
        history.push(channelIdInPWA ? `/church/${channelIdInPWA}` : `/`)
      }
      setUserSelectedTourType(channelIdInPWA ? `cssb` : `bsb`)
      toggleDrawerOpen(false)
    },
    [ setUserSelectedTourType, toggleDrawerOpen, channelIdInPWA, history, location.pathname ],
  )

  const goMapTour = useCallback(
    () => {
      if(location.pathname !== `/map`) {
        history.push(`/map`)
      }
      setUserSelectedTourType(`map`)
      toggleDrawerOpen(false)
    },
    [ setUserSelectedTourType, toggleDrawerOpen, history, location.pathname ],
  )

  return (
    <Container>

      {assignmentBlock}

      {!assignmentBlock &&
        <LogoContainer>
          <LogoContainer2 $miniHeaderSize={miniHeaderSize} >
            {!channelIdInPWA && <LogoImg src="/logo.svg" />}
            {!!channelIdInPWA &&
              <>
                <PoweredByContainer>
                  <PoweredBy>
                    {i18n("Powered by")}
                  </PoweredBy>
                  <LogoImg src="/logo.svg" $small />
                </PoweredByContainer>
                <LaunchLinkIconButton
                  $miniHeaderSize={miniHeaderSize}
                  href={process.env.REACT_APP_BASE_URI}
                >
                  <LaunchIcon />
                </LaunchLinkIconButton>
              </>
            }
          </LogoContainer2>
          {miniHeaderSize &&
            <ItemsExcludedInMiniHeader>
              <StyledIconButton
                onClick={copyOrShare}
                color={justCopiedOrShared ? `primary` : `default`}
              >
                {justCopiedOrShared ? <CheckIcon /> : (willShare ? <ShareIcon /> : <LinkIcon />)}
              </StyledIconButton>
              <SecondaryHeaderItems
                showBibleDisplaySettings
                inMenu
                {...otherProps}
              />
            </ItemsExcludedInMiniHeader>
          }
        </LogoContainer>
      }

      <Divider />

      <StudyBibleStyledList>

        {!channelIdInPWA &&
          <>

            {hasMyPlan && <SubscribedInfoIcon to="/your-my-bsb-subscription" onClick={toggleDrawerOpen} />}

            {!hasMyPlan && <SubscribedInfoIcon to="/about-the-biblearc-study-bible" onClick={toggleDrawerOpen} />}

            {hasMyPlan && <SubscribedIndicator $color="tertiary" />}

            <SubTypeLogoContainer>

              {hasMyPlan && <SectionLogoImg src="/my_biblearc_study_bible_1.svg" />}
              {!hasMyPlan && <SectionLogoImg src="/the_biblearc_study_bible_1.svg" />}

            </SubTypeLogoContainer>

            <MenuItemNavLink exact to="/">
              <ListItem
                button
                onClick={toggleDrawerOpen}
              >
                <StyledListItemText
                  primary={
                    <>
                      <ItemContent>
                        <BSBAppImage
                          src={`/favicon_192.png`}
                          className="dark-mode-exempt"
                        />
                        {hasMyPlan
                          ? <MyStudyBibleLogoImg src="/my_study_bible_2.svg" />
                          : i18n("The Study Bible")
                        }
                      </ItemContent>
                    </>
                  }
                />
              </ListItem>
            </MenuItemNavLink>

          </>
        }

        <RecentChurchBibles
          toggleDrawerOpen={toggleDrawerOpen}
          MenuItemNavLink={MenuItemNavLink}
          fixedChurchBible={channelInPWA}
        />

        {hasMyPlan && !channelIdInPWA &&
          <>

            <MainMenuHighlights
              channelIdInPWA={channelIdInPWA}
              toggleDrawerOpen={toggleDrawerOpen}
              setSnackbarInfo={setSnackbarInfo}
            />

            {/* <MainMenuSketches
              channelIdInPWA={channelIdInPWA}
              toggleDrawerOpen={toggleDrawerOpen}
              setSnackbarInfo={setSnackbarInfo}
            /> */}

          </>
        }

        {(hasMyPlan || !!channelIdInPWA) &&
          <>

            <MainMenuListeningHistory
              channelIdInPWA={channelIdInPWA}
              toggleDrawerOpen={toggleDrawerOpen}
              setSnackbarInfo={setSnackbarInfo}
            />

            <MainMenuMyBookmarks
              channelIdInPWA={channelIdInPWA}
              toggleDrawerOpen={toggleDrawerOpen}
              setSnackbarInfo={setSnackbarInfo}
            />

          </>
        }

        <MenuItemNavLink exact to="/map">
          <ListItem
            button
            onClick={toggleDrawerOpen}
          >
            <StyledListItemText
              primary={
                <ItemContent>
                  <StyledMapIcon
                    src="/map.svg"
                    className="dark-mode-exempt"
                  />
                  {i18n("Bible Map + Timeline")}
                </ItemContent>
              }
            />
          </ListItem>
        </MenuItemNavLink>

        {hasMyPlan && !channelIdInPWA &&
          <>

            <MainMenuMapSnapshots
              channelIdInPWA={channelIdInPWA}
              toggleDrawerOpen={toggleDrawerOpen}
              setSnackbarInfo={setSnackbarInfo}
            />

            <MainMenuMapNotes
              channelIdInPWA={channelIdInPWA}
              toggleDrawerOpen={toggleDrawerOpen}
              setSnackbarInfo={setSnackbarInfo}
            />

          </>
        }

        {!hasMyPlan && !channelIdInPWA &&
          <>

            <MenuItemNavLink to="/about-my-bsb">
              <ListItem
                button
                onClick={toggleDrawerOpen}
              >
                <StyledListItemText
                  primary={
                    i18nReact("About {{logo}}", {
                      logo: (
                        <MyLogoImg src="/my_biblearc_study_bible_1.svg" />
                      ),
                    })
                  }
                />
              </ListItem>
            </MenuItemNavLink>

            <MenuItemNavLink
              exact
              to="/subscribe"
              $ignoreActive
              onClick={toggleDrawerOpen}
            >
              <ListItem
                button
              >
                <StyledListItemText
                  primary={
                    <GetMy>
                      {i18n("Get MY BSB")}
                    </GetMy>
                  }
                />
              </ListItem>
            </MenuItemNavLink>

          </>
        }

      </StudyBibleStyledList>

      {!channelIdInPWA &&
        <>

          <Divider />

          <ToolsStyledList>

            {hasToolsPlan && <SubscribedInfoIcon to="/your-tools-subscription" onClick={toggleDrawerOpen} />}

            {hasToolsPlan && <SubscribedIndicator $color="secondary" />}

            <SubTypeLogoContainer>

              <SectionLogoImg src="/biblearc_tools_1.svg" />

            </SubTypeLogoContainer>

            {!hasToolsPlan &&
              <>

                <MenuItemNavLink to="/about-tools">
                  <ListItem
                    button
                    onClick={toggleDrawerOpen}
                  >
                    <StyledListItemText primary={i18n("What is it?")} />
                  </ListItem>
                </MenuItemNavLink>

                {count > 0 &&
                  <MenuItemNavLink to="/projects">
                    <ListItem
                      button
                      onClick={toggleDrawerOpen}
                    >
                      <StyledListItemText primary={i18n("My Projects (View-Only)")} />
                    </ListItem>
                  </MenuItemNavLink>
                }

                {/* <MenuItemNavLink exact to="/project/1">
                  <ListItem
                    button
                    onClick={toggleDrawerOpen}
                  >
                    <StyledListItemText primary="Example Project" />
                  </ListItem>
                </MenuItemNavLink> */}

                <MenuItemNavLink
                  exact
                  to="/subscribe"
                  $ignoreActive
                  onClick={toggleDrawerOpen}
                >
                  <ListItem
                    button
                  >
                    <StyledListItemText
                      primary={
                        <GetTools>
                          {i18n("Get TOOLS")}
                        </GetTools>
                      }
                    />
                  </ListItem>
                </MenuItemNavLink>

              </>
            }

            {hasToolsPlan &&
              <>

                <MenuItemNavLink to="/projects">
                  <ListItem
                    button
                    onClick={toggleDrawerOpen}
                  >
                    <StyledListItemText primary={i18n("All Projects")} />
                  </ListItem>
                </MenuItemNavLink>

                <RecentProjects
                  toggleDrawerOpen={toggleDrawerOpen}
                  MenuItemNavLink={MenuItemNavLink}
                />

                <NewProjectButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  disableElevation
                  onClick={goCreateProjectAndShutDrawer}
                >
                  {i18n("Create a new project")}
                </NewProjectButton>

              </>
            }

          </ToolsStyledList>

          <Divider />

          <EquipStyledList>

            {hasToolsPlan && <SubscribedInfoIcon to="/your-equip-subscription" onClick={toggleDrawerOpen} />}

            {hasEquipPlan && <SubscribedIndicator />}

            <SubTypeLogoContainer>

              <SectionLogoImg src="/biblearc_equip_1.svg" />

            </SubTypeLogoContainer>

            {/* <MenuItemNavLink to={`https://equip.biblearc.com/course/interpretation/79707${autologin}`}>
              <ListItem
                button
              >
                <StyledListItemText
                  primary={
                    i18nReact("My Course: {{course_name}}", {
                      course_name: (
                        <ItemName>
                          Interpretation
                        </ItemName>
                      ),
                    })
                  }
                />
                <MenuItemOpenInNewIcon />
              </ListItem>
            </MenuItemNavLink> */}

            {!hasEquipPlan &&
              <>

                <MenuItemNavLink to={`https://equip.biblearc.com${autologin}`}>
                  <ListItem
                    button
                  >
                    <StyledListItemText primary={i18n("About")} />
                    <MenuItemOpenInNewIcon />
                  </ListItem>
                </MenuItemNavLink>

                <MenuItemNavLink to={`https://equip.biblearc.com/learningresources${autologin}`}>
                  <ListItem
                    button
                  >
                    <StyledListItemText primary={i18n("Learning Resources")} />
                    <MenuItemOpenInNewIcon />
                  </ListItem>
                </MenuItemNavLink>

                <MenuItemNavLink
                  exact
                  to="/subscribe"
                  $ignoreActive
                  onClick={toggleDrawerOpen}
                >
                  <ListItem
                    button
                  >
                    <StyledListItemText
                      primary={
                        <GetEquip>
                          {i18n("Get EQUIP")}
                        </GetEquip>
                      }
                    />
                  </ListItem>
                </MenuItemNavLink>

              </>
            }

            {hasEquipPlan &&
              <>

                <MenuItemNavLink to={`https://equip.biblearc.com/all-courses${autologin}`}>
                  <ListItem
                    button
                  >
                    <StyledListItemText primary={i18n("All Courses")} />
                    <MenuItemOpenInNewIcon />
                  </ListItem>
                </MenuItemNavLink>

                <MenuItemNavLink to={`https://equip.biblearc.com/learningresources${autologin}`}>
                  <ListItem
                    button
                  >
                    <StyledListItemText primary={i18n("Learning Resources")} />
                    <MenuItemOpenInNewIcon />
                  </ListItem>
                </MenuItemNavLink>

              </>
            }

          </EquipStyledList>

        </>
      }

      <Divider />

      <StyledList>

        <MenuItemNavLink to="/settings">
          <ListItem
            button
            onClick={toggleDrawerOpen}
          >
            <StyledListItemText primary={i18n("Settings")} />
          </ListItem>
        </MenuItemNavLink>

        {!shouldRecommendInstall &&
          <>
            <ListItem
              button
              onClick={openModal}
            >
              <StyledListItemText
                primary={i18n("Take a Tour")}
                secondary={
                  channelIdInPWA
                    ? i18n("Learn to use your study Bible")
                    : i18n("Learn to use The Biblearc Study Bible")
                }
              />
            </ListItem>
            <OptionsPopover
              anchorEl={anchorEl}
              onClose={closeModal}
            >
              <MenuList>
                <MenuItem
                  onClick={goTour}
                >
                  {i18n("Tour the Study Bible")}
                </MenuItem>
                <MenuItem
                  onClick={goMapTour}
                >
                  {i18n("Tour the Map + Timeline")}
                </MenuItem>
              </MenuList>
            </OptionsPopover>
          </>
        }

        {!channelIdInPWA &&
          <MenuItemNavLink exact to="/church-bibles">
            <ListItem
              button
              onClick={toggleDrawerOpen}
            >
              <StyledListItemText
                primary={i18n("Church Study Bibles")}
                secondary={i18n("Look-up your church’s study Bible...")}
              />
            </ListItem>
          </MenuItemNavLink>
        }

        <MenuItemNavLink exact to="/tag">
          <ListItem
            button
            onClick={toggleDrawerOpen}
          >
            <StyledListItemText
              primary={i18n("Tag Hebrew and Greek")}
              secondary={i18n("Help make the original language tools even better.")}
            />
          </ListItem>
        </MenuItemNavLink>

        {!channelIdInPWA &&
          <>

            {/* <MenuItemNavLink to={`https://equip.biblearc.com/give-scholarships${autologin}`}>
              <ListItem
                button
              >
                <StyledListItemText primary={i18n("Give a Scholarship")} />
                <MenuItemOpenInNewIcon />
              </ListItem>
            </MenuItemNavLink> */}

            <MenuItemNavLink exact to="/give-a-gift">
              <ListItem
                button
                onClick={toggleDrawerOpen}
              >
                <StyledListItemText
                  primary={i18n("Gift Certificates")}
                  secondary={i18n("Give deeper study of Scripture")}
                />
              </ListItem>
            </MenuItemNavLink>

            <MenuItemNavLink exact to="/group-subscriptions">
              <ListItem
                button
                onClick={toggleDrawerOpen}
              >
                <StyledListItemText
                  primary={i18n("Group Subscriptions")}
                  secondary={i18n("For your family, church, or college")}
                />
              </ListItem>
            </MenuItemNavLink>

            {!hasToolsPlan &&
              <MenuItemNavLink exact to="/whatever-you-can-afford">
                <ListItem
                  button
                  onClick={toggleDrawerOpen}
                >
                  <StyledListItemText
                    primary={i18n("Biblearc TOOLS for Free")}
                    secondary={i18n("For select countries in the developing world")}
                  />
                </ListItem>
              </MenuItemNavLink>
            }

            <MenuItemNavLink exact to="/donate">
              <ListItem
                button
                onClick={toggleDrawerOpen}
              >
                <StyledListItemText
                  primary={i18n("Give a Donation")}
                  secondary={i18n("Goes toward scholarships and feature additions")}
                />
              </ListItem>
            </MenuItemNavLink>

            {/* <MenuItemNavLink exact to="/faq">
              <ListItem
                button
                onClick={toggleDrawerOpen}
              >
                <StyledListItemText primary={i18n("FAQ")} />
              </ListItem>
            </MenuItemNavLink> */}

          </>
        }

      </StyledList>

      <Divider />
      <FillSpace />

      <ResetButtonContainer>
        <Button
          variant="contained"
          size="small"
          disableElevation
          onClick={setResetApp}
        >
          {i18n("Reset app")}
        </Button>
      </ResetButtonContainer>

      <SmallLinksLine>

        <SmallNavLink to={`https://equip.biblearc.com/contact`}>
          {channelIdInPWA ? i18n("Report a Bug") : i18n("Contact")}
        </SmallNavLink>

        <SmallNavLink exact to="/terms" onClick={toggleDrawerOpen}>
          {i18n("Terms")}
        </SmallNavLink>

        <SmallNavLink exact to="/privacy" onClick={toggleDrawerOpen}>
          {i18n("Privacy")}
        </SmallNavLink>

        <SmallNavLink to={`https://equip.biblearc.com/partners`}>
          {i18n("Partners")}
        </SmallNavLink>

      </SmallLinksLine>

      <Copyright>
        {`© ${new Date().getFullYear()} Bethlehem College & Seminary`}
      </Copyright>

    </Container>
  )
}

export default memo(MainMenu)