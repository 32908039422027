import React from 'react'
import { i18n } from 'inline-i18n'
import { getLocFromRef, getCorrespondingRefs, getOriginalLocsFromRange } from '@bibletags/bibletags-versification'
import { grammarColors } from "@bibletags/bibletags-ui-helper"

import {
  DEFAULT_PASSAGE_TEXT_SIZE_MULTIPLIER,
  DEFAULT_PASSAGE_LINE_SPACING_SIZE_MULTIPLIER,
  DEFAULT_MODULE_COLUMN_TEXT_SIZE_MULTIPLIER,
  DEFAULT_PHRASING_COLUMN_TAB_MULTIPLIER,
  IS_EMBED,
  BSB_MANIFEST_URL,
} from './constants'
import { db } from './database'
import { getHasEqualPassageRanges } from '../hooks/useHasEqualPassageRanges'
import { getNakedStrongs } from '../hooks/useDefinition'
import { getHebrewPrefixDefinition, getHebrewSuffixGloss } from './hebrewPrefixAndSuffixInfo'

import versionQuery from '../graphql/queries/version'

Element.prototype.requestFullscreen = Element.prototype.requestFullscreen || Element.prototype.webkitRequestFullscreen
Document.prototype.exitFullscreen = Document.prototype.exitFullscreen || Document.prototype.webkitExitFullscreen
export const HAS_FULLSCREEN_OPTION = !!(Element.prototype.requestFullscreen && Document.prototype.exitFullscreen)

export const pascalToCamelCase = str => `${(str[0] || "").toLowerCase()}${str.substr(1)}`
export const camelToDashCase = str => str.replace(/[A-Z]/g, uppercaseLetter => `-${uppercaseLetter.toLowerCase()}`)
export const dashToCamelCase = str => str.replace(/-[a-z]/g, dashAndLowercaseLetter => dashAndLowercaseLetter.slice(1).toUpperCase())
export const capitalize = str => `${(str[0] || "").toUpperCase()}${str.substr(1)}`

export const safeJSONParse = (str, defaultValue) => {
  let value = defaultValue
  try {
    value = JSON.parse(str)
  } catch(err) {}
  return value
}

// do not save to localStorage if embed
export const setLocalStorage = (key, value) => !IS_EMBED && localStorage.setItem(key, JSON.stringify(value))
export const removeLocalStorage = key => !IS_EMBED && localStorage.removeItem(key)
export const getLocalStorage = (key, defaultValue) => {
  if(IS_EMBED) return defaultValue
  const value = safeJSONParse(localStorage.getItem(key), defaultValue)
  return (
    value === null
      ? defaultValue
      : value
  )
}

export const setOfflineModeOn = async ({ saveStatus }={}) => {

  setLocalStorage('updatedSince:userUpdate', 0)

  await db.localInfo.put({
    id: 'offlineSetupStatus',
    value: (
      saveStatus === `saved`
        ? 'on-query-sync'
        : 'on-mutation-sync'
    ),
  })

}

export const setOfflineModeOff = async () => {

  setLocalStorage('updatedSince:userUpdate', 'use-from-memory')

  await db.transaction(
    'rw',
    db.tables,
    () => {
      // following run in parallel given that there is no async/await
      db.localInfo.put({
        id: 'offlineSetupStatus',
        value: 'off',
      })
      db.formattingKeys.clear()
      db.highlights.clear()
      db.projects.clear()
      db.folders.clear()
      db.tags.clear()
      db.moduleByProjects.clear()
      db.modules.clear()
      db.modulePassages.clear()
      db.moduleSettings.clear()
      db.modulePieces.clear()
      db.moduleDots.clear()
      db.moduleMarkups.clear()
      db.projectPassageHistoryItems.clear()
    },
  )
}

export const logoutCallback = async () => {
  removeLocalStorage('user.id')
  removeLocalStorage('user.image')
  removeLocalStorage('user.name')
  removeLocalStorage('updatedSince:userUpdate')
  removeLocalStorage('updatedSince:accountSettings')
  await Promise.all(db.tables.map(async table => {
    if(![ 'offlineVersions' ].includes(table.name)) {
      await table.clear()
    }
  }))
}

export const loginCallback = async (user={ id: "0" }) => {
  setLocalStorage('user.id', user.id)
  setLocalStorage('user.image', user.image)
  setLocalStorage('user.name', user.name)
  await db.transaction(
    'rw',
    db.users,
    async () => {
      await db.users.clear()
      await db.users.put(user)
    },
  )
}

const sortRowsById = obj => {
  if((obj || {}).rows) {
    return {
      ...obj,
      rows: [ ...obj.rows ].sort((a, b) => a.id > b.id ? 1 : -1),
    }
  }
  return obj
}

const JSONstringifyOrder = (obj, space) => {
  const allKeys = []
  const seen = {}
  JSON.stringify(obj, (key, value) => {
    if(!(key in seen)) {
      allKeys.push(key)
      seen[key] = null
    }
    return value
  })
  allKeys.sort()
  return JSON.stringify(obj, allKeys, space)
}

// Given that floats are not precise, truly equal objects with floats might come back as unequal.
const reduceDecimals = json => (
  !json
    ? json
    : (
      json
        // For DOUBLE's, this is avoided by only comparing them to 8 decimals (given that we increase
        // ordering by 10k each time and do not expect more than 1000 increases).
        .replace(/("(?:ordering|scrollYFraction)":-?[0-9]+\.[0-9]{8})[0-9]+/g, '$1')
        // For FLOAT's representing percentages, this is avoided by only comparing them to 3 decimals
        .replace(/(Percentage":-?[0-9]+\.[0-9]{3})[0-9]+/g, '$1')
        // For FLOAT's representing fractions, this is avoided by only comparing them to 5 decimals
        .replace(/("fraction(?:Left|Top)":-?[0-9]+\.[0-9]{5})[0-9]+/g, '$1')
        // For FLOAT's representing monetary amounts, this is avoided by comparing them rounded to 2 decimals
        .replace(/((?:Balance|Amount)":)(-?[0-9]+(?:\.[0-9]+)?)/g, (x, label, percentage) => (
          `${label}${(Math.round(parseFloat(percentage) * 100) / 100).toFixed(2)}`
        ))
    )
)

export const cloneObj = obj => JSON.parse(JSON.stringify(obj))
export const equalObjs = (obj1, obj2) => reduceDecimals(JSONstringifyOrder(obj1)) === reduceDecimals(JSONstringifyOrder(obj2))
export const equalQueryResultObjs = (obj1, obj2) => equalObjs(sortRowsById(obj1), sortRowsById(obj2))

export const equalObjsWithIgnores = (obj1, obj2, ignores) => {
  try {
    const o1 = cloneObj(obj1)
    const o2 = cloneObj(obj2)
    ignores.forEach(ignoreKey => {
      delete o1[ignoreKey]
      delete o2[ignoreKey]
    })
    return equalObjs(o1, o2)
  } catch(e) {
    return obj1 === obj2
  }
}

let updatedSinceUserUpdateInMemory = Date.now()
export const getUpdatedSinceUserUpdateInMemory = () => updatedSinceUserUpdateInMemory
export const setUpdatedSinceUserUpdateInMemory = u => { updatedSinceUserUpdateInMemory = u }

// This function should match its counterpart in biblearc-data
export const getQueryAndWhere = ({ args={}, FLAG_MAP=[] }) => {
  let { query='' } = args

  // extract special query flags
  const where = {}
  const whereByModel = {}
  const flagRegex = /(\s|^)([-a-z]+:(?:"[^"]*"|[-\w]+))(?=\s|$)/i

  query = (query || "")
    .split(flagRegex)
    .filter(piece => {

      if(flagRegex.test(piece)) {
        const [ flagWithPossibleNegation, ...flagValuePieces ] = piece.split(':')
        const [ x, negated, flag ] = flagWithPossibleNegation.match(/^(not-)?(.*)$/)  // eslint-disable-line no-unused-vars
        const flagValue = flagValuePieces.join(':').replace(/^"(.*)"$/, '$1')

        if(FLAG_MAP[flag]) {
          const { special, column, isBoolean, multiValue, model } = FLAG_MAP[flag]

          let whereValue

          if(special === 'not null') {
            whereValue = row => (
              (flagValue === 'yes') === (row[column] !== null)
            )
          } else if(isBoolean) {
            whereValue = row => (
              row[column] === (flagValue !== `false`)
            )
          } else if(multiValue) {
            whereValue = row => (
              flagValue.split(' ').includes(row[column]) === !negated
            )
          } else {
            whereValue = row => (
              (flagValue === 'yes') === (row[column] === flagValue)
            )
          }

          if(model) {
            whereByModel[model] = whereByModel[model] || {}
            whereByModel[model][column] = whereValue
          } else {
            where[column] = whereValue
          }

        }

        return false

      } else {
        return true
      }

    })
    .join('')
    .replace(/  +/g, ' ')
    .trim()

  return { query, where, whereByModel }
}

// This function should match its counterpart in biblearc-data
export const getSubsetArgs = ({ args={}, orderOptions, defaultLimit=10, maxLimit=50 }) => {
  const { order=orderOptions[0], offset=0, limit=defaultLimit } = args

  if(!orderOptions.includes(order)) throw new Error(`order argument invalid (should be one of the following: ${orderOptions.join(', ')})`)
  if(limit > maxLimit) throw new Error(`limit argument exceeds maximum (${maxLimit})`)
  if(offset < 0) throw new Error(`offset cannot be negative`)

  return {
    order: order.split(',').map(colWithFlag => colWithFlag.split(' ')),
    offset,
    limit,
  }
}

export const escapeStrForRegex = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

export const callOnFocus = func => {
  let lastRun = 0
  const runFunc = () => {
    if(Date.now() - lastRun > 1000) {  // just in case, to prevent vicious infinite loop
      func()
      lastRun = Date.now()
    }
  }

  window.addEventListener('focus', runFunc)
  return () => window.removeEventListener('focus', runFunc)
}

export const getNewOrdering = (prevOrdering, followingOrdering) => {
  // assumes prevOrdering < followingOrdering, or one/both of them is null/undefined

  const prev = parseFloat(prevOrdering, 10) || 0
  const following = parseFloat(followingOrdering, 10) || 0
  const endAdjustment = 10000

  // do "fuzzy" adjustment up to 0.5% of the way to closest neighbor
  // this prevents duplicate orderings if two devices are working offline at the same time
  const getFuzzyAdj = differenceToClosestOrdering => differenceToClosestOrdering * .01 * (Math.random() - .5)

  if(prevOrdering == null) {
    return (following - endAdjustment) + getFuzzyAdj(endAdjustment)
  } else if(followingOrdering == null) {
    return (prev + endAdjustment) + getFuzzyAdj(endAdjustment)
  } else {
    const halfTheDifference = (following - prev) / 2
    return prev + halfTheDifference + getFuzzyAdj(halfTheDifference)
  }
}

export const getModuleTypeLabel = ({ type }) => ({
  NOTES: i18n("Notes", "", "notes"),
  DISCOURSE: i18n("Arc/Bracket", "", "discourse"),
  PHRASING: i18n("Phrase", "", "phrasing"),
  DIAGRAMMING: i18n("Diagram", "", "diagramming"),
  "WORD-STUDY": i18n("Word Study", "", "word-study"),
  OUTLINE: i18n("Outline", "", "outline"),
  MARKUP: i18n("Markup", "", "markup"),
})[type]

export const getOrigVersionInfo = () => ({
  id: 'original',
  abbr: i18n("Heb+Grk"),
  name: i18n("unfoldingWord Hebrew Bible + unfoldingWord Greek New Testament"),
  type: 'ORIGINAL',
  copyright: (
    'This work is designed by unfoldingWord® and developed by the Door43 World Missions Community; it is licensed under a [Creative Commons Attribution-ShareAlike 4.0 International License (CC BY-SA 4.0)](https://creativecommons.org/licenses/by-sa/4.0/). The UHB is based on the Open Scriptures Hebrew Bible. The UGNT is a derivative of the Bunning Heuristic Prototype (BHP) released under the same CC-BY-SA license by Alan Bunning with the [Center for New Testament Restoration (CNTR)](https://greekcntr.org/).'
    + '\n\nThe Greek apparatus is primarily derived from data made available by the Center for New Testament Restoration, also under the same license mentioned above. Changes to the Greek apparatus can be found [here](https://github.com/educational-resources-and-services/bibletags-usfm).'
  ),
  versificationModel: 'original',
  hebrewOrdering: true,
  languageId: 'heb+grc',
  doNotRemove: true,
})

export const getLXXVersionInfo = () => ({
  id: 'lxx',
  abbr: i18n("LXX"),
  name: i18n("Rahlfs’ Septuagint – Canonical Portions"),
  type: 'LXX',
  copyright: 'Septuaginta, ed. A. Rahlfs (Stuttgart: WŸrttembergische Bibelanstalt, 1935; repr. in 9th ed., 1971).',
  versificationModel: 'lxx',
  hebrewOrdering: false,
  partialScope: 'ot',
  languageId: 'grc',
  doNotRemove: true,
})

export const getOrigFromAndToLocs = ({ version, refs }) => {

  const getConvertedLoc = ({ ref, directionToTryIfSkipped=`next`, atIdx=0 }) => (
    getLocFromRef(
      getCorrespondingRefs({
        baseVersion: {
          ref,
          info: version,
        },
        lookupVersionInfo: getOrigVersionInfo(),
        directionToTryIfSkipped,
      }).at(atIdx)
    ).split(':')[0]
  )

  return {
    fromLoc: getConvertedLoc({ ref: refs[0] }),
    toLoc: getConvertedLoc({ ref: refs[1] || refs[0], directionToTryIfSkipped: `previous`, atIdx: -1 }),
  }
}

export const getOrderingFromOriginalLoc = loc => parseInt((loc || `0`).split(':')[0], 10)
export const getBaseLocFromOrdering = ordering => `0${parseInt(ordering, 10)}`.slice(-8)

export const numSort = (a, b) => a - b

export const getOrderingsByRowIndex = ({ modulePassages, defaultBlockSize }) => {

  const orderings = []
  const originalLocs = {}
  const hasEqualPassageRanges = getHasEqualPassageRanges(modulePassages)

  modulePassages.forEach(modulePassage => {

    const { id, fromLoc, toLoc, info: { rowIndexesWithAdjustedNumVerses={} } } = modulePassage

    originalLocs[`${fromLoc}-${toLoc}`] = originalLocs[`${fromLoc}-${toLoc}`] || getOriginalLocsFromRange(fromLoc, toLoc)

    const addOrdering = (locOrLocs, idx) => {
      const locs = locOrLocs instanceof Array ? locOrLocs : [ locOrLocs ]
      orderings[idx] = {
        ...(orderings[idx] || {}),
        [id]: {
          orderingFrom: getOrderingFromOriginalLoc(locs[0]),
          orderingTo: getOrderingFromOriginalLoc(locs.at(-1)),
        },
      }
    }

    const originalLocsGroupedInBlocks = []

    let idx = 0
    while(idx < originalLocs[`${fromLoc}-${toLoc}`].length) {
      let blockSize = rowIndexesWithAdjustedNumVerses[originalLocsGroupedInBlocks.length]
      if(blockSize === undefined || hasEqualPassageRanges) {
        blockSize = defaultBlockSize
      }
      originalLocsGroupedInBlocks.push(
        originalLocs[`${fromLoc}-${toLoc}`].slice(idx, idx + blockSize)
      )
      idx += blockSize
    }

    originalLocsGroupedInBlocks.forEach(addOrdering)

  })

  return orderings

}

export const getPassageTextSize = ({ textSizes, languageId }) => (
  (Math.pow(textSizes.base, 2) * Math.pow(textSizes[languageId] || DEFAULT_PASSAGE_TEXT_SIZE_MULTIPLIER, 2)) / 3
)

export const getPassageLineSpacing = ({ lineSpacingSizes, languageId }) => (
  (Math.pow(lineSpacingSizes.base, 2) * Math.pow(lineSpacingSizes[languageId] || DEFAULT_PASSAGE_LINE_SPACING_SIZE_MULTIPLIER, 2)) / 25
)

export const getModuleTextFontSize = multiplier => (
  (Math.pow(multiplier, 2) * Math.pow(DEFAULT_MODULE_COLUMN_TEXT_SIZE_MULTIPLIER, 2)) / 3
)

export const getModuleTextSizeMultiplier = textSize => (
  Math.pow((3 * textSize) / Math.pow(DEFAULT_MODULE_COLUMN_TEXT_SIZE_MULTIPLIER, 2), .5)
)

export const getModuleLineSpacing = multiplier => (
  (Math.pow(multiplier, 2) * Math.pow(DEFAULT_PASSAGE_LINE_SPACING_SIZE_MULTIPLIER, 2)) / 25
)

export const getModuleLineSpacingMultiplier = lineSpacing => (
  Math.pow((25 * lineSpacing) / Math.pow(DEFAULT_PASSAGE_LINE_SPACING_SIZE_MULTIPLIER, 2), .5)
)

export const getPhrasingIndentSize = multiplier => multiplier * DEFAULT_PHRASING_COLUMN_TAB_MULTIPLIER

const cachedOriginalWordsForSearch = {}
export const addOriginalWordsForSearch = originalWords => {
  for(let strongs in originalWords) {
    cachedOriginalWordsForSearch[strongs] = cachedOriginalWordsForSearch[strongs] || originalWords[strongs]
  }
}
export const getOriginalWordsForSearch = () => cachedOriginalWordsForSearch

export const preventDefaultEvent = event => {
  event.preventDefault()
  event.stopPropagation()
}

export const stopPropagationEvent = event => {
  event.stopPropagation()
}

export const blurActiveElement = () => {
  if(document.activeElement) {
    document.activeElement.blur()
  }
}

export const getRandomMargin = max => parseInt(Math.random() * max, 10)

export const reorder = ({ list, startIndex, endIndex }) => {
  const result = Array.from(list)
  const [ removed ] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const replaceWithJSX = (text, regexStr, getReplacement) => {
  let idx = 0

  return (
    text
      .split(new RegExp(`(${regexStr.replace(/\(([^?])/g, '(?:$1')})`, 'g'))
      .map(piece => {

        const matchInfo = piece.match(new RegExp(`^${regexStr}$`))

        if(matchInfo) {
          return (
            <React.Fragment key={idx++}>
              {getReplacement(...matchInfo)}
            </React.Fragment>
          )
        }

        return piece
      })
  )

}

export const textToJsx = text => (
  text.split(/\n+/g).map((line, idx) => (
    <p key={idx}>
      {replaceWithJSX(line, '<a href="([^"]+)">([^<]+)</a>|<i>([^<]+)</i>|<(?:b|strong)>([^<]+)</(?:b|strong)>|<[^>]+>', (x, href, linkText, italicText, boldText) => {
        if(href) {
          return (
            <a
              href={href}
              target="_blank"
              rel="noreferrer"
            >
              {linkText}
            </a>
          )
        } else if(italicText) {
          return <i>{italicText}</i>
        } else if(boldText) {
          return <b>{boldText}</b>
        } else if(boldText) {
          return <b>{boldText}</b>
        }
      })}
    </p>
  ))
)

export const getObjFromArrayOfObjs = (array, key='id', valueKey) => {
  if(!array) return null
  const itemsByKey = {}
  array.forEach(item => {
    itemsByKey[item[key]] = valueKey ? item[valueKey] : item
  })
  return itemsByKey
}

export const getVersionInfoFromVersion = version => (
  version
    ? {
      id: version.id,
      safeVersionAbbr: (
        (version || {}).abbr
        || version.id.toUpperCase()
      ),
      version,
    }
    : null
)

export const getDefaultVersionInfoFromVersionId = versionId => ({
  id: versionId || ``,
  safeVersionAbbr: (versionId || ``).toUpperCase()
})

const coreVersions = [ 'original', 'uhb', 'ugnt', 'lxx' ]

export const getCoreVersionInfo = versionId => (
  coreVersions.includes(versionId)
    ? (
      getVersionInfoFromVersion(
        coreVersions.indexOf(versionId) === 3
          ? getLXXVersionInfo()
          : getOrigVersionInfo()
      )
    )
    : null
)

export const getVersionInfoByIdSync = ({ versionId, client }) => {
  const coreVersionInfo = getCoreVersionInfo(versionId)
  if(coreVersionInfo) return coreVersionInfo

  const { version } = client.readQuery({
    query: versionQuery,
    variables: {
      id: versionId,
    },
  }) || {}
  return getVersionInfoFromVersion(version)
}

export const getVersionByIdSync = (...props) => (getVersionInfoByIdSync(...props) || {}).version

export const getVersionInfoByIdAsync = async ({ versionId, client, offlineSetupStatus }) => {
  const coreVersionInfo = getCoreVersionInfo(versionId)
  if(coreVersionInfo) return coreVersionInfo

  const { data: { version }={} } = await client.query({
    query: versionQuery,
    variables: {
      id: versionId,
    },
    context: {
      offlineSetupStatus,
    },
  })
  return getVersionInfoFromVersion(version)
}

export const getVersionByIdAsync = async (...props) => {
  const { version } = await getVersionInfoByIdAsync(...props) || {}
  return version
}

export const normalizeVersionId = versionId => [ `uhb`, `ugnt` ].includes(versionId) ? `original` : versionId

export const isLegacyOriginalVersion = versionId => [ `na27`, `na28`, `sbl`, `wlc` ].includes(versionId)

export const getVersionIdForBibleTags = ({ versionId, bookId }) => (
  versionId === `original`
    ? (
      bookId <= 39
        ? `uhb`
        : `ugnt`
    )
    : versionId
)

export const getLinkFromManuscript = (id, ref) => {
  const baseUrl = `https://greekcntr.org/manuscripts/index.htm`
  const loc = getLocFromRef(ref)
  return (
    /^𝔓[0-9]+$/.test(id)
      ? `${baseUrl}?w=1G1${`000${id.replace(/^𝔓/,'')}`.slice(-4)}&v=${loc}`
      : (
        /^0[0-9]+$/.test(id)
          ? `${baseUrl}?w=1G2${`000${id.substr(1)}`.slice(-4)}&v=${loc}`
          : null
      )
      
  )
}

export const getManuscriptAbbr = origVersionAbbr => (
  {
    Q: i18n("Qere"),
    K: i18n("Ketiv"),
  }[origVersionAbbr] || origVersionAbbr
)

export const getSubscriptionTypeLanguage = type => (
  {
    "STRIPE": i18n("Paid subscription"),
    "GROUP-MEMBER": i18n("Group subscription"),
    "SCHOLARSHIP": i18n("Scholarship"),
    "DEVELOPING-WORLD": i18n("Free subscription"),
    "HONORARY": i18n("Complimentary subscription"),
    "GIVE-AWAY": i18n("Promotional subscription"),
    "GIFT-CERTIFICATE": i18n("Subscription purchased from a gift certificate"),
  }[type]
)

export const getSubscriptionPlanImageSrc = plan => (
  {
    MY: `/my_biblearc_study_bible_1.svg`,
    TOOLS: `/biblearc_tools_1.svg`,
    EQUIP: `/biblearc_equip_1.svg`,
  }[plan]
)

export const getSubscriptionPlanLanguage = plan => (
  {
    MY: `My Biblearc Study Bible`,
    TOOLS: `Biblearc TOOLS`,
    EQUIP: `Biblearc EQUIP`,
  }[plan]
)

export const getSubscriptionTermLanguage = term => (
  {
    ANNUALLY: i18n("Billed annually"),
    MONTHLY: i18n("Billed monthly"),
  }[term]
)

export const getAdminLevelText = adminLevel => (
  {
    ADMIN: i18n("Super Admin"),
    EDITOR: i18n("Admin Level: Study Bible Editor"),
    CONTRIBUTOR: i18n("Admin Level: Study Bible Contributor"),
    REPORTING: i18n("Admin Level: Reports"),
    MARKETING: i18n("Admin Level: Marketing"),
    TESTER: i18n("Tester"),
  }[adminLevel] || adminLevel
)

export const isValidEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const fullMonthDiff = (d1, d2) => {
  d1 = typeof d1 === "object" ? d1 : new Date(d1)
  d2 = typeof d2 === "object" ? d2 : new Date(d2)
  let months
  months = (d2.getFullYear() - d1.getFullYear()) * 12
  months -= d1.getMonth() + 1
  months += d2.getMonth()
  return months <= 0 ? 0 : months
}

export const getDiscountText = ({ term, amountOff, percentOff, durationInMonths, end, doRemainingPeriod }) => {
  const months = doRemainingPeriod ? (end && fullMonthDiff(Date.now(), end)) : durationInMonths
  const getDiscountSwaps = () => ({
    discount: (
      amountOff
        ? i18n("${{amount}}", { amount: (amountOff / 100).toFixed(2) })  // eslint-disable-line no-template-curly-in-string
        : i18n("{{percent}}%", { percent: percentOff })
    ),
    period: (
      term === 'ANNUALLY'
        ? i18n("a year")
        : (
          months > 1
            ? i18n("{{number}} months", { number: months })
            : i18n("a month")
        )
    ),
    remaining_period: (
      months > 1
        ? i18n("{{number}} more months", { number: months })
        : i18n("one more month")
    )
  })

  return (
    !months
      ? i18n("{{discount}} off {{period}}", getDiscountSwaps())
      : (
        doRemainingPeriod
          ? i18n("{{discount}} off for {{remaining_period}}", getDiscountSwaps())
          : i18n("{{discount}} off for {{period}}", getDiscountSwaps())
      )
  )
}

export const sortModules = (a,b) => {
  // put module thumbnails in canonical order, then module type order, then createdAt time
  const fromLocA = (a.module.modulePassages[0] || {}).fromLoc || 'x'
  const fromLocB = (b.module.modulePassages[0] || {}).fromLoc || 'x'
  const moduleIndexA = [ 'OUTLINE', 'MARKUP', 'DIAGRAMMING', 'WORD-STUDY', 'PHRASING', 'DISCOURSE', 'NOTES' ].indexOf(a.module.type)
  const moduleIndexB = [ 'OUTLINE', 'MARKUP', 'DIAGRAMMING', 'WORD-STUDY', 'PHRASING', 'DISCOURSE', 'NOTES' ].indexOf(b.module.type)
  return (
    fromLocA < fromLocB
    || (
      fromLocA === fromLocB
      && (
        moduleIndexA < moduleIndexB
        || (
          moduleIndexA === moduleIndexB
          && (
            a.module.type === `NOTES`
              ? a.ordering < b.ordering
              : a.module.createdAt < b.module.createdAt
          )
        )
      )
    )
      ? -1
      : 1
  )
}

const platform = (navigator.userAgentData || {}).platform || navigator.platform || 'unknown'
export const isApplePlatform = /^Mac|iPhone|iPad/i.test(platform)
export const getModifierChar = () => isApplePlatform ? `⌘` : i18n("ctrl + ")
export const usingModifierKey = ({ metaKey, ctrlKey }={}) => isApplePlatform ? metaKey : ctrlKey

export const isIOS = (
  /iphone|ipod|ipad/.test(navigator.userAgent)
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)  // iPadOS
)
export const isAndroid = /android/i.test(navigator.userAgent)

export const isPWA = (
  !!navigator.standalone  // iOS and Safari
  || window.matchMedia(`(display-mode: standalone)`).matches
)
export const iOSCanInstall = isIOS && navigator.standalone !== undefined
let relatedApps = []  // cannot be known for iOS
export const updateRelatedApps = async () => {
  try {
    const manifestEl = document.head.querySelector(`link[rel="manifest"]`)
    if(manifestEl.getAttribute(`href`) === BSB_MANIFEST_URL) {
      relatedApps = await navigator.getInstalledRelatedApps()
    }
  } catch(e) {}
}
if(!isPWA && isAndroid) {
  setTimeout(updateRelatedApps, 300)
  setTimeout(updateRelatedApps, 1500)
  setInterval(updateRelatedApps, 1000*30)
}
export const getBsbIsAlreadyInstalledAsPWA = () => (relatedApps.length >= 1)

export const shouldRecommendInstall = ((isIOS && iOSCanInstall) || isAndroid) && !isPWA

// TODO: Check what PWAs are installed on Android
// https://web.dev/articles/get-installed-related-apps#:~:text=The%20getInstalledRelatedApps()%20makes%20it,user%20experience%20if%20it%20is.

export const isChrome = /(?:chrome|android).*safari/i.test(navigator.userAgent)
export const isFirefox = /firefox/i.test(navigator.userAgent)
export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const isNativeApp = (isAndroid || isIOS) && !isPWA && !isChrome && !isFirefox && !isSafari && !/safari/i.test(navigator.userAgent)

export const selectTextOfEl = elementOrSelector => {
  const el = (
    typeof elementOrSelector === `string`
      ? document.querySelector(elementOrSelector)
      : elementOrSelector
  )
  if(!el) return
  const range = document.createRange()
  range.selectNodeContents(el)
  const selection = window.getSelection()
  selection.empty()
  selection.addRange(range)
  el.focus()
}

export const downloadLink = ({ href, name }) => {
  const link = document.createElement('a')
  link.download = name
  link.href = href
  link.click()
}

export const doWhitespaceTrim = text => (
  text
    .replace(/^\s+|\s+$/g, ``)
    .replace(/\n\n+/g, `\n`)
    .replace(/  +/g, ` `)
)

export const getSecondsFromTimeString = timeString => (
  String(timeString || '')
    .split(":")
    .reverse()
    .reduce(
      (total, timeSegment, idx) => (
        total + (parseInt(timeSegment || 0, 10) || 0) * [1, 60, 60*60, 60*60*24][idx]
      ),
      0
    )
)

export const getTimeStringFromSeconds = seconds => {
  seconds = parseInt(seconds, 10) || 0
  const h = parseInt(seconds / (60*60))
  const m = parseInt((seconds % (60*60)) / 60)
  const s = (seconds % (60*60)) % 60
  if(h) {
    return `${h}:${`0${m}`.slice(-2)}:${`0${s}`.slice(-2)}`
  } else {
    return `${m}:${`0${s}`.slice(-2)}`
  }
}

export const isTouchDevice = () => (
  ('ontouchstart' in window)
  || (navigator.maxTouchPoints > 0)
  || (navigator.msMaxTouchPoints > 0)
)

export const dedup = (ary, keyCreationFunc=JSON.stringify) => {
  const keys = {}
  return ary.filter(item => {
    const key = keyCreationFunc(item)
    if(keys[key]) {
      return false
    } else {
      keys[key] = true
      return true
    }
  })
}

export const findBookmarkInsertIdx = ({ myReactionTypes, bookmarkMs }) => myReactionTypes.filter(ms => parseInt(ms.split(`:`)[1], 10) < bookmarkMs).length

export const getBoundedValue = (value, { min=-Infinity, max=Infinity }) => Math.min(Math.max(value, min), max)

const DAYS_PER_MONTH = 30
const MONTHS_PER_YEAR = 12
const DAYS_PER_YEAR = DAYS_PER_MONTH * MONTHS_PER_YEAR
const DATE_REGEX = /^(AD )?([0-9]{1,4})( BC)?(?: \[?([0-9]+)\/([0-9]+)\]?)?$/

export const getEarlierDate = (date1, date2) => {

  if(/ BC$/.test(date1) !== / BC$/.test(date2)) {
    return (
      / BC$/.test(date1)
        ? date1
        : date2
    )
  }

  if(/ BC$/.test(date1)) {
    return (
      parseInt(date1.slice(0,-3), 10) > parseInt(date2.slice(0,-3), 10)
        ? date1
        : date2
    )
  } else {
    return (
      parseInt(date1.slice(3), 10) < parseInt(date2.slice(3), 10)
        ? date1
        : date2
    )
  }

}

export const getLaterDate = (date1, date2) => getEarlierDate(date1, date2) === date1 ? date2 : date1

export const timeBetweenMapDates = (date1, date2) => {

  const getIntVal = date => {
    let [ x1, ad, dateYr, bc, dateMo, dateDay ] = date.match(DATE_REGEX) || []  // eslint-disable-line no-unused-vars
    // use a pseudo start date of 5000 BC
    dateYr = bc ? (5000 - parseInt(dateYr, 10)) : (5000 + parseInt(dateYr, 10) - 1)
    return (
      dateYr * DAYS_PER_YEAR
      + (parseInt(dateMo || 1, 10) - 1) * DAYS_PER_MONTH
      + (parseInt(dateDay || 1, 10) - 1)
    )
  }

  const totalDays = (getIntVal(date2) || 0) - (getIntVal(date1) || 0)
  const years = parseInt(totalDays / DAYS_PER_YEAR, 10)

  return {
    years,
    months: parseInt((totalDays - years * DAYS_PER_YEAR) / DAYS_PER_MONTH, 10),
    days: totalDays % DAYS_PER_MONTH,
    totalDays,
  }

}

export const sortEventsByDate = ({ events }) => {
  events.sort((a,b) => {
    const [ a1, a2 ] = getPrimaryDate(a).split(` - `)
    const [ b1, b2 ] = getPrimaryDate(b).split(` - `)
    return (
      (a1 === b1 && timeBetweenMapDates(a2 || a1, b2 || b1).totalDays * -1)
      || timeBetweenMapDates(a1, b1).totalDays * -1
    )
  })
}

export const addJourneyColorToEvents = ({ places, events, colorByJourneyId }) => {
  events = events || places.map(({ events }) => events).flat()
  events.forEach(event => {
    event.color = colorByJourneyId[event.journeyId]
  })
}

export const getDateAsString = ({ date }) => {
  date = date.replace(/ \[.*$/, ``)
  const [ startDate, endDate ] = date.split(` - `)
  if(endDate && / BC$/.test(startDate) === / BC$/.test(endDate)) {
    date = date.replace(/ BC - | - AD /, `-`)
  }
  date = date.replace(/-/, `–`)
  return date
}

export const addDaysToDate = ({ date, days, minDate, maxDate }) => {

  let [ x1, ad, dateYr, bc, dateMo=1, dateDay=1 ] = date.match(DATE_REGEX) || []  // eslint-disable-line no-unused-vars

  dateYr = bc ? (5000 - parseInt(dateYr, 10)) : (5000 + parseInt(dateYr, 10) - 1)

  const yearsAdjustment = parseInt(days / DAYS_PER_YEAR, 10)
  dateYr += yearsAdjustment
  dateMo = parseInt(dateMo, 10) + parseInt((days - yearsAdjustment * DAYS_PER_YEAR) / DAYS_PER_MONTH, 10)
  dateDay = parseInt(dateDay, 10) + days % DAYS_PER_MONTH  

  while(dateDay < 1) {
    dateMo--
    dateDay += DAYS_PER_MONTH
  }

  while(dateDay > DAYS_PER_MONTH) {
    dateMo++
    dateDay -= DAYS_PER_MONTH
  }

  while(dateMo < 1) {
    dateYr--
    dateMo += MONTHS_PER_YEAR
  }

  while(dateMo > MONTHS_PER_YEAR) {
    dateYr++
    dateMo -= MONTHS_PER_YEAR
  }

  if(dateYr >= 5000) {
    dateYr = dateYr - 5000 + 1
    bc = false
  } else {
    dateYr = 5000 - dateYr
    bc = true
  }


  const newDate = `${!bc ? 'AD ' : ''}${dateYr}${bc ? ' BC' : ''} [${dateMo}/${dateDay}]`

  if(timeBetweenMapDates(minDate, newDate).totalDays < 0) {
    return minDate
  } else if(timeBetweenMapDates(newDate, maxDate).totalDays < 0) {
    return maxDate
  } else {
    return newDate
  }

}

export const getPrimaryName = (item, { newlines }={}) => {
  let primaryName = (((item || {}).names || [])[0] || {}).name || ``
  if(newlines) {
    primaryName = primaryName.replace(/\\/g, `\n`) 
  } else {
    primaryName = primaryName.replace(/\\/g, ` `) 
  }
  return primaryName
}

export const getPrimaryDate = item => (((item || {}).dates || [])[0] || {}).date || ``

export const getGrammarColorCSSRules = ({ selector=`.TextContent-tag-w`, filter=`none` }={}) => (
  [ ...new Set(Object.values(grammarColors)) ]
    .map(color => `
      ${selector} [data-color="${color}"] {
        color: ${color};
        filter: ${filter};
      }
    `)
    .join('')
)

export const getGloss = ({ piece, idx, definitionsByStrong }) => {

  const { children=[{}], strong, morph } = piece
  const strongParts = strong.split(/:/g)
  const morphParts = morph.split(`,`)[1].split(/:/g)
  const morphSuffixParts = morphParts.filter(mPart => /^S/.test(mPart))
  const part = children[idx]

  const nakedStrongs = getNakedStrongs(strong)
  let { gloss=`` } = (
    definitionsByStrong[nakedStrongs]
    || getHebrewPrefixDefinition(nakedStrongs)
    || {}
  )

  if(part.color) {

    if(children.length - idx <= morphSuffixParts.length) {

      gloss = getHebrewSuffixGloss({
        morphPart: morphSuffixParts.at(idx - children.length),
        includeGender: true,
      })

    } else {

      gloss = (getHebrewPrefixDefinition(strongParts[idx]) || {}).gloss

      if(morphParts[idx] === `Rd`) {
        gloss += ` + ${getHebrewPrefixDefinition(`d`).gloss}`
      }

    }

  }

  return gloss
}

export const removeUnassociatedTags = tags => (tags || []).filter(({ o, t }) => (o.length > 0 && t.length > 0))

export const equalTags = (tags1, tags2) => (
  equalObjs(
    sortTagSetTags(removeUnassociatedTags(tags1)),
    sortTagSetTags(removeUnassociatedTags(tags2)),
  )
)

// tag order doesn’t really matter except that it must be consistent (should match function in biblearc-data repo)
export const sortTagSetTags = tags => {
  const newTags = cloneObj(tags || [])
  newTags.forEach(tag => {
    const { o, t } = tag
    o.sort()
    t.sort((a,b) => a-b)
    for(let key in tag) {
      delete tag[key]
    }
    tag.o = o
    tag.t = t
  })
  newTags.sort((a,b) => {
    const aT1 = a.t[0] || Infinity
    const bT1 = b.t[0] || Infinity
    if(aT1 === bT1) {
      return a.o[0] > b.o[0] ? 1 : -1
    } else if(aT1 > bT1) {
      return 1
    } else {
      return -1
    }
  })
  return newTags
}

export const vibrate = (length=100) => {
  if(navigator.vibrate) {
    try {
      navigator.vibrate(length)
    } catch(err) {}
  }
}

export const cutOffString = (str, length) => {
  str = str || ``
  return (
    str.length > length
      ? `${str.slice(0, length-3)}...`
      : str
  )
}

export const getEaseInOutValue = k => (Math.sin((k - .5) * Math.PI) + 1) / 2

export const getIsTagSearch = searchText => searchText.split(/ +/g).some(word => /^#(?:\p{L}|[-_+&/%])+$/u.test(word))

// write urlB64ToUint8Array to convert base64 string to Uint8Array
export const urlB64ToUint8Array = base64String => {
  const padding = `=`.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding).replace(/-/g, `+`).replace(/_/g, `/`)
  const rawData = atob(base64)
  const outputArray = new Uint8Array(rawData.length)
  for(let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

// Note: I am leaving this here in case I need it AND to remind myself that compressing strings is not worth it!
// export const convertBase = (str, fromBase, toBase) => {  // base from 2-128
//   // based off of function found here: https://stackoverflow.com/questions/1337419/how-do-you-convert-numbers-between-different-bases-in-javascript

//   const DIGITS = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ+/\x00\x01\x02\x03\x04\x05\x06\x07\b\t\n\v\f\r\x0E\x0F\x10\x11\x12\x13\x14\x15\x16\x17\x18\x19\x1A\x1B\x1C\x1D\x1E\x1F !"#$%&\'()*,-.:;<=>?@[\\]^_`{|}~\x7F';

//   const add = (x, y, base) => {
//       let z = [];
//       const n = Math.max(x.length, y.length);
//       let carry = 0;
//       let i = 0;
//       while (i < n || carry) {
//           const xi = i < x.length ? x[i] : 0;
//           const yi = i < y.length ? y[i] : 0;
//           const zi = carry + xi + yi;
//           z.push(zi % base);
//           carry = Math.floor(zi / base);
//           i++;
//       }
//       return z;
//   }

//   const multiplyByNumber = (num, x, base) => {
//       if (num < 0) return null;
//       if (num == 0) return [];

//       let result = [];
//       let power = x;
//       while (true) {
//           num & 1 && (result = add(result, power, base));
//           num = num >> 1;
//           if (num === 0) break;
//           power = add(power, power, base);
//       }

//       return result;
//   }

//   const parseToDigitsArray = (str, base) => {
//       const digits = str.split('');
//       let arr = [];
//       for (let i = digits.length - 1; i >= 0; i--) {
//           const n = DIGITS.indexOf(digits[i])
//           if (n == -1) return null;
//           arr.push(n);
//       }
//       return arr;
//   }

//   const digits = parseToDigitsArray(str, fromBase);
//   if (digits === null) return null;

//   let outArray = [];
//   let power = [1];
//   for (let i = 0; i < digits.length; i++) {
//       digits[i] && (outArray = add(outArray, multiplyByNumber(digits[i], power, toBase), toBase));
//       power = multiplyByNumber(fromBase, power, toBase);
//   }

//   let out = '';
//   for (let i = outArray.length - 1; i >= 0; i--)
//       out += DIGITS[outArray[i]];

//   return out;
// }