import { memo, useCallback } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { getDateAsString } from '../../../utils/misc'

const Container = styled.div`
  position: absolute;
  left: 14px;
  right: 10px;
  display: flex;
  transition: opacity .15s ease-in-out;

  ${({ onClick }) => !onClick ? `` : `
    @media (hover: hover) {
      &:hover {
        cursor: pointer;
        opacity: .8;
      }
    }
  `}
`

const Content = styled.div`
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: ${({ $color, theme }) => $color ? `${$color}bf` : theme.palette.mapColors.event};
  backdrop-filter: blur(2px);
  box-shadow: 0 0 10px rgb(255 255 255/.3);
  border-radius: 50px;
  color: white;
  padding: 0 10px;
  line-height: 22px;
  user-select: none;
  pointer-events: all;
`

const Date = styled.span`
  font-size: 11px;
  opacity: .5;
  margin-left: 1px;
`

const Name = styled.span`
  font-size: 13px;
  opacity: .9;
`

const BibleMapEvent = ({
  top,
  id,
  type,
  dates,
  names,
  passages,
  personIds,
  color,
  updateMapTimelineForItem,
}) => {

  const onClick = useCallback(
    () => {
      if(type === `journey`) {
        updateMapTimelineForItem({ journeyId: id })
      } else {
        updateMapTimelineForItem({ eventId: id })
      }
    },
    [ id, updateMapTimelineForItem, type ],
  )

  return (
    <Container
      className={[ `event`, `journey` ].includes(type) ? `BibleMapEvent-Container` : null}
      style={{ top }}
      onClick={[ `event`, `journey` ].includes(type) ? onClick : null}
    >
      <Content
        className="BibleMapEvent-Content"
        $color={color}
      >
        <Name
          className="BibleMapEvent-Name"
        >
          {names[0].name}
        </Name>
        {` `}
        <Date
          className="BibleMapEvent-Date"
        >
          {getDateAsString(dates[0])}
        </Date>
      </Content>
    </Container>
  )
}

export default memo(BibleMapEvent)