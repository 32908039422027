import { memo } from 'react'
import { i18n } from 'inline-i18n'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import FadedLoading from './FadedLoading'

const InfoDialog = ({
  onOkay,
  title=i18n("Note"),
  explanation,
  okayButtonLabel=i18n("Okay"),
  color="primary",
  loading,
  ...props
}) => {

  return (
    <Dialog
      {...props}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {typeof explanation === 'string'
          ?
            <DialogContentText>
              {explanation}
            </DialogContentText>
          : explanation
        }
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onOkay}
          variant="contained"
          color={color}
        >
          {okayButtonLabel}
        </Button>
      </DialogActions>
      {loading && <FadedLoading />}
    </Dialog>
  )
}

export default memo(InfoDialog)