import { memo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
// import { i18nReact } from 'inline-i18n/build/i18nReact'
import Snackbar from '@material-ui/core/Snackbar'
// import IconButton from '@material-ui/core/IconButton'
// import CloseIcon from '@material-ui/icons/Close'
// import CheckIcon from '@material-ui/icons/Check'

// import { LoggedInUserContext } from '../../../context/LoggedInUser'

import useSimpleToggle from '../../../hooks/useSimpleToggle'
import { isAndroid, isIOS, shouldRecommendInstall } from '../../../utils/misc'
import { TransitionUp } from '../../common/CustomSnackbar'

import LinkButton from '../../common/LinkButton'
import useOnInstall from '../../../hooks/useOnInstall'

const APP_ICON_SIZE = 60

const StyledSnackbar = styled(Snackbar)`
  .MuiSnackbarContent-message {
    flex: 1;
  }

  .MuiSnackbarContent-root {
    padding: 6px 14px;
  }
`

const Content = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

// const CloseSnackbarIconButton = styled(IconButton)`
//   padding: 5px;
//   position: absolute;
//   top: 7px;
//   right: 7px;

//   &:hover {
//     background: rgb(255 255 255/.1);
//   }
// `

const StyledLinkButton = styled(LinkButton)`

`

// const StyledCloseIcon = styled(CloseIcon)`
//   color: white;
//   height: 16px;
//   width: 16px;
// `

const Preview = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 5px 0;
`

const AndroidPreview = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #999;
`

const AndroidPreviewImage = styled.img`
  width: ${APP_ICON_SIZE * 1.1}px;
  height: ${APP_ICON_SIZE * 1.1}px;
  margin: -${APP_ICON_SIZE * .1}px;
`

const IOSPreviewImage = styled.img`
  width: ${APP_ICON_SIZE}px;
  height: ${APP_ICON_SIZE}px;
  vertical-align: middle;
`

// const AlreadyInstalled = styled.div`
//   display: flex;
//   align-items: center;
//   font-size: 13px;
//   margin-bottom: 3px;

//   .MuiSvgIcon-root {
//     margin-right: 1px;
//     font-size: 17px;
//     color: ${({ theme }) => theme.palette.fourthly.light};
//   }
// `

const PreviewText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: ${APP_ICON_SIZE * 1.2}px;
  margin-top: 5px;
  font-size: 13px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const BSBInstallSnackbar = () => {

  const [ snackbarOpen ] = useSimpleToggle(shouldRecommendInstall)  // eslint-disable-line no-unused-vars

  const onInstall = useOnInstall()

  return (
    <StyledSnackbar
      open={snackbarOpen}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      message={
        <>

          <Content>

            {isIOS &&
              <Preview>
                <IOSPreviewImage
                  src={`/favicon_192.png`}
                  className="dark-mode-exempt"
                />
                <PreviewText>
                  {`Biblearc`}
                </PreviewText>
              </Preview>
            }

            {isAndroid &&
              <Preview>
                <AndroidPreview>
                  <AndroidPreviewImage
                    src={`/app_icon_maskable_512.png`}
                    className="dark-mode-exempt"
                  />
                </AndroidPreview>
                <PreviewText>
                  {`Biblearc`}
                </PreviewText>
              </Preview>
            }

            <ButtonContainer>

              {/* {appAlreadyInstalled &&
                <AlreadyInstalled>
                  <CheckIcon />
                  {i18n("Installed")}
                </AlreadyInstalled>
              } */}

              <StyledLinkButton
                color="secondary"
                size="small"
                disableElevation
                variant="contained"
                navInSameTab
                href={`/install`}
                onClick={onInstall}
              >
                {i18n("Install the App")}
              </StyledLinkButton>

            </ButtonContainer>

          </Content>

          {/* <CloseSnackbarIconButton
            onClick={toggleSnackbarOpen}
          >
            <StyledCloseIcon />
          </CloseSnackbarIconButton> */}

        </>
      }
      TransitionComponent={TransitionUp}
    />
  )
}

export default memo(BSBInstallSnackbar)