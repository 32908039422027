import { memo, useCallback, useState } from 'react'
import { i18n } from 'inline-i18n'
// import { i18nReact } from 'inline-i18n/build/i18nReact'
import styled from 'styled-components'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuList from '@material-ui/core/MenuList'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import Tooltip from '@material-ui/core/Tooltip'
import SubjectIcon from '@material-ui/icons/Subject'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete'

import useModalAnchor from '../../hooks/useModalAnchor'
import useDataQuery from '../../hooks/useDataQuery'
import { preventDefaultEvent } from '../../utils/misc'
import useAppSize from '../../hooks/useAppSize'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import useIsLoggedIn from '../../hooks/useIsLoggedIn'
import useLogInCallback from '../../hooks/useLogInCallback'

import OptionsPopover from './OptionsPopover'
import MainMenuBookmark from './MainMenuBookmark'
import FadedLoading from './FadedLoading'

import myBookmarkedChannelItemsQuery from '../../graphql/queries/myBookmarkedChannelItems'

const FILL_SCREEN_MAX_WIDTH = 499
const FETCH_MORE_LIMIT = 10

const Content = styled.div`
  max-height: 600px;
  display: flex;
  flex-direction: column;
  width: min(calc(100vw - 40px), 400px);

  ${({ $isMini }) => !$isMini ? `` : `
    max-height: none;
    height: calc(100vh - 10px);
    width: calc(100vw - 10px);
  `}
`

const StyledSubjectIcon = styled(SubjectIcon)`
  font-size: 21px;
  margin: 2px 9px 1px 3px;
`

const ListenHistoryHeader = styled.div`
  background: ${({ theme }) => theme.palette.grey[200]};
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 22px;
`

const HeaderText = styled.div`
  flex: 1;
`

const ItemContent = styled.span`
  flex: 1;
  min-width: 240px;
  font-weight: 300;
  white-space: nowrap;
  display: flex;
  align-items: center;
  max-width: min(calc(100vw - 40px), 300px);

  ${({ $isMini }) => !$isMini ? `` : `
    width: calc(100vw - 10px);
    max-width: none;
  `}
`

const ItemText = styled.div`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.05;
  padding: 1px 0 2px;
`

const StyledMenuList = styled(MenuList)`
  overflow: auto;
  padding-bottom: 30px;
  min-height: 115px;
`

const StyledIconButton = styled(IconButton)`
  padding: 9px;
  
  .MuiSvgIcon-root {
    font-size: 22px;
  }
`

const None = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  text-align: center;
  font-weight: 300;
  margin: 30px 0 25px;
`

const Message = styled.div`
  margin: 5px 0 0;
  font-size: 13px;
  font-weight: 200;
  text-align: center;
`

const StyledButton = styled(Button)`
  margin: 5px 0 0;
  align-self: center;
`

const DeleteIconButton = styled(StyledIconButton)`
  margin: 0 -5px 0 0;
  ${({ $deleteMode, theme }) => !$deleteMode ? `` : `
    color: ${theme.palette.tertiary.main};
  `}
`

const LoadMoreContainer = styled.div`
  display: flex;
  justify-content: center;
`

const StyledTextField = styled(TextField)`
  flex: 1;
`

const TopLine = styled.div`
  margin: 20px 15px 0;
  display: flex;
  align-items: center;
  gap: 5px;
`

const NeedLogin = styled.div`
  padding: 35px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  text-align: center;
`

const MainMenuMapNotes = ({
  channelIdInPWA,
  toggleDrawerOpen,
  setSnackbarInfo,
}) => {

  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const { width } = useAppSize()
  const [ searchStr, setSearchStr ] = useState(``)
  const [ deleteMode, toggleDeleteMode ] = useSimpleToggle()
  const isLoggedIn = useIsLoggedIn()
  const { logIn, navigatingToLogin } = useLogInCallback()

  const isMini = width <= FILL_SCREEN_MAX_WIDTH

  let query = searchStr.replace(/:([^ ])/g, `: $1`).replace(/  +/g, ` `).trim()
  if(channelIdInPWA) {
    query += ` channelId:${channelIdInPWA}`
  }
  const { myBookmarkedChannelItems: myBookmarkedChannelItemsAndCount, fetchMore } = useDataQuery({
    myBookmarkedChannelItemsQuery,
    variables: {
      query,
    },
    skip: !isLoggedIn,
    fetchPolicy: `cache-and-network`,
    nextFetchPolicy: 'cache-first',
  })
  const { myBookmarkedChannelItems, count } = myBookmarkedChannelItemsAndCount || {}
  const numShowing = (myBookmarkedChannelItems || []).length

  const onChange = useCallback(({ target }) => setSearchStr(target.value), [])

  const goFetchMore = useCallback(
    () => {
      fetchMore({
        variables: {
          query,
          limit: FETCH_MORE_LIMIT,
          offset: numShowing,
        },
        fetchPolicy: 'network-only',
      })
    },
    [ fetchMore, numShowing, query ],
  )

  const onClickMyBookmarks = useCallback(
    event => {
      preventDefaultEvent(event)
      openModal(event)
    },
    [ openModal ],
  )

  return (
    <>

      <ListItem
        button
        onClick={onClickMyBookmarks}
        className="MainMenuMapNotes-ListItem"
        disabled
      >
        <ListItemText primary={
          <ItemContent>
            <StyledSubjectIcon />
            <ItemText>
              {i18n("Map Notes")}
            </ItemText>
          </ItemContent>
        } />
      </ListItem>

      <OptionsPopover
        anchorEl={anchorEl}
        onClose={closeModal}
        hideArrow
      >
        <Content $isMini={isMini}>

          {isMini &&
            <ListenHistoryHeader>
              <HeaderText>
                {i18n("Map Notes")}
              </HeaderText>
              <StyledIconButton onClick={closeModal}>
                <CloseIcon />
              </StyledIconButton>
            </ListenHistoryHeader>
          }

          {!isLoggedIn &&
            <NeedLogin>
              {i18n("You must be logged in to use bookmarks.")}
              <Button
                onClick={logIn}
                variant="contained"
                disableElevation
                color="primary"
              >
                {i18n("Sign in / create an account")}
              </Button>
              {navigatingToLogin && <FadedLoading size={20} />}
            </NeedLogin>
          }

          {isLoggedIn &&
            <>

              <TopLine>

                <StyledTextField
                  value={searchStr}
                  variant="outlined"
                  onChange={onChange}
                  size="small"
                  placeholder={i18n("Search message titles")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <Tooltip
                  title={
                    deleteMode
                      ? i18n("Toggle off delete mode")
                      : i18n("Toggle on delete mode")
                  }
                >
                  <DeleteIconButton
                    $deleteMode={deleteMode}
                    onClick={toggleDeleteMode}
                  >
                    <DeleteIcon />
                  </DeleteIconButton>
                </Tooltip>
              </TopLine>

              <StyledMenuList>

                {(myBookmarkedChannelItems || []).map(channelItem => (
                  <MainMenuBookmark
                    key={channelItem.id}
                    channelItem={channelItem}
                    channelIdInPWA={channelIdInPWA}
                    toggleDrawerOpen={toggleDrawerOpen}
                    setSnackbarInfo={setSnackbarInfo}
                    deleteMode={deleteMode}
                  />
                ))}

                {!!myBookmarkedChannelItems && numShowing === 0 && 
                  <None>
                    {i18n("None found")}
                  </None>
                }

                {!!myBookmarkedChannelItems && numShowing > 0 &&
                  <Message>
                    {i18n("Showing {{number}} of {{total}}", {
                      number: numShowing,
                      total: count,
                    })}
                  </Message>
                }

                {numShowing < count &&
                  <LoadMoreContainer>
                    <StyledButton
                      onClick={goFetchMore}
                      variant="contained"
                      disableElevation
                      size="small"
                    >
                      {i18n("Load more")}
                    </StyledButton>
                  </LoadMoreContainer>
                }

                {!myBookmarkedChannelItems && <FadedLoading size={20} />}

              </StyledMenuList>

            </>
          }

        </Content>
      </OptionsPopover>

    </>
  )
}

export default memo(MainMenuMapNotes)