import { useMemo } from 'react'
import { getLocFromRef, getNextTranslationRef, getRefFromLoc } from '@bibletags/bibletags-versification'

import { KJV_VERSION } from '../utils/constants'

const usePassageRefPopperInfo = ({
  contextRefs,
  version,
}) => {

  const info = useMemo(
    () => {
      if((contextRefs || []).length === 0) return {}

      const locsToHighlight = []
      let loc = getLocFromRef(contextRefs[0]).split(':')[0]
      const lastLoc = getLocFromRef(contextRefs.at(-1)).split(':')[0]
      if(loc > lastLoc) return {}  // just in case

      for(let idx=0; loc <= lastLoc && idx<200; idx++) {  // 200 verse max, just in case
        locsToHighlight.push(loc)
        loc = getLocFromRef(
          getNextTranslationRef({
            ref: getRefFromLoc(loc),
            info: version || KJV_VERSION,
          })
        )
      }

      const specialMarkup = [{
        start: {
          loc: locsToHighlight[0],
        },
        end: {
          loc: locsToHighlight.at(-1),
        },
        type: "NORMAL-HIGHLIGHT",
        color: "SYSTEM",
      }]

      return {
        locsToHighlight,
        specialMarkup,
      }

    },
    [ contextRefs, version ],
  )

  return info

}

export default usePassageRefPopperInfo