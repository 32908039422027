import { memo, useContext, useMemo } from 'react'
import styled from 'styled-components'
import { i18n } from 'inline-i18n'
import Divider from '@material-ui/core/Divider'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

import useInstanceValuesCallback from '../../../hooks/useInstanceValuesCallback'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useAccountSetting from '../../../hooks/useAccountSetting'
import { NotificationsContext } from '../../../context/Notifications'
import { isAndroid, isIOS } from '../../../utils/misc'
import { ChannelIdInPWAContext } from '../../../context/ChannelIdInPWA'
import { LoggedInUserContext } from '../../../context/LoggedInUser'

import SettingsListHeader from "./SettingsListHeader"
import CustomRadioGroup from '../../common/CustomRadioGroup'
import FadedLoading from '../../common/FadedLoading'
import InfoDialog from '../../common/InfoDialog'
import JoinTheMailingListButton from '../../common/JoinTheMailingListButton'
import CustomSwitch from '../../common/CustomSwitch'

const ListContainer = styled.div`
  min-width: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
  margin-right: -1px;
`

const StyledNotificationsActive = styled(NotificationsActiveIcon)`
  height: 26px;
  width: auto;
  margin: 0 10px 0 1px;
`

const Options = styled.div`
  min-height: 0;
  flex: 1;
  overflow: auto;
  padding: 25px 20px calc(100vh - 350px);
`

const Blocks = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`

const Info = styled.div`
  font-size: 16px;
  font-weight: 300;
  opacity: .6;
`

const NotificationMethodHeading = styled.div`
  color: ${({ theme }) => theme.palette.grey[600]};
  font-size: 19px;
  font-weight: 300;

  .MuiSvgIcon-root {
    font-size: 20px;
    vertical-align: middle;
    margin: -10px 0 -8px 6px;
    opacity: .8;
  }
`

const NotificationMethodSubheading = styled.div`
  font-size: 13px;
  margin-bottom: 15px;
`

const EmailNote = styled.div`
  font-weight: 300;
  margin: 10px 0 0;
`

const BSBImage = styled.img`
  height: 30px;
  margin: 12px -8px 0;
`

const ToolsImage = styled.img`
  height: 30px;
  margin: 12px -8px 0;
`

const Spacer = styled.div`
  height: 10px;
`

const RequiresLogin = styled.span`
  color: ${({ theme }) => theme.palette.tertiary.dark};
  margin-left: 4px;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
`

const StyledCustomSwitch = styled(CustomSwitch)`
  .MuiFormControlLabel-label {
    font-size: 16px;
    line-height: 1.1;
  }
`

const StyledCustomRadioGroup = styled(CustomRadioGroup)`
  gap: 4px;

  .MuiFormControlLabel-label {
    line-height: 1.1;
  }
`

const SettingsNotifications = ({
  expandLeftPanel,
}) => {

  const user = useContext(LoggedInUserContext)
  const { hasMyPlan } = user || {}
  const { channelIdInPWA } = useContext(ChannelIdInPWAContext)
  const [ dialogOpen, toggleDialogOpen ] = useSimpleToggle(false)
  const [ inAppAlertsPref, setInAppAlertsPref ] = useAccountSetting(`in-app-alerts-pref`, `ALL`)
  const [ emailPref, setEmailPref ] = useAccountSetting(`email-pref`, `ANNOUNCEMENTS`)
  const [ alertCurated, setAlertCurated ] = useAccountSetting(`alert-curated`, true)
  const [ alertChannels, setAlertChannels ] = useAccountSetting(`alert-channels`, true)
  const [ alertDiscussions, setAlertDiscussions ] = useAccountSetting(`alert-discussions`, true)
  const [ alertSharing, setAlertSharing ] = useAccountSetting(`alert-sharing`, true)

  const {
    checkingNotificationsStatus,
    notificationsStatus,
    getNotificationsStatus,
    updateNotificationsStatus,
    updatingNotificationsStatus,
    notificationsUnavailable,
  } = useContext(NotificationsContext)

  const deviceNotificationsRadios = useMemo(
    () => (
      [
        {
          value: `ALL`,
          label: i18n("Receive the types of notifications indicated above + Biblearc announcements"),
        },
        {
          value: `ANNOUNCEMENTS`,
          label: i18n("Receive Biblearc announcements only"),
        },
        {
          value: `NONE`,
          label: i18n("Off"),
        },
      ]
    ),
    [],
  )

  const alertsRadios = useMemo(
    () => (
      [
        {
          value: `ALL`,
          label: i18n("Receive the types of notifications indicated above + Biblearc announcements"),
        },
        {
          value: `ANNOUNCEMENTS`,
          label: i18n("Receive Biblearc announcements only"),
        },
      ]
    ),
    [],
  )

  const onDeviceNotificationsChange = useInstanceValuesCallback(
    async ({ target }) => {
      await updateNotificationsStatus(target.value)
      setTimeout(() => {
        if(target.value !== getNotificationsStatus()) {
          toggleDialogOpen({ force: true })
        }
      })
    },
  )

  const onAlertsChange = useInstanceValuesCallback(({ target }) => setInAppAlertsPref(target.value))
  const onEmailChange = useInstanceValuesCallback(({ target }) => setEmailPref(target.value))

  return (
    <>

      <ListContainer>

        <SettingsListHeader
          icon={<StyledNotificationsActive />}
          expandLeftPanel={expandLeftPanel}
        >
          {i18n("Notifications")}
        </SettingsListHeader>

        <Divider />

        <Options>

          <Blocks>

            <Block>

              <NotificationMethodHeading>
                {i18n("What Type of Notifications Do You Want?")}
              </NotificationMethodHeading>

              {!channelIdInPWA && <BSBImage src="/the_biblearc_study_bible_1.svg" />}
              {channelIdInPWA && <Spacer />}

              {!channelIdInPWA &&
                <StyledCustomSwitch
                  checked={alertCurated}
                  onSet={setAlertCurated}
                  label={i18n("New and highlighted content from the Biblearc team")}
                />
              }

              <StyledCustomSwitch
                checked={alertChannels}
                onSet={setAlertChannels}
                label={
                  <>
                    {channelIdInPWA
                      ? i18n("New and trending content from your church (e.g. newly added sermons)")
                      : i18n("New and trending content from channels you follow (e.g. newly added sermons)")
                    }
                    {!hasMyPlan && <>{` `}<RequiresLogin>{i18n("Requires subscription")}</RequiresLogin></>}
                  </>
                }
              />

              <StyledCustomSwitch
                checked={alertDiscussions}
                onSet={setAlertDiscussions}
                label={
                  <>
                    {i18n("Updates to discussions you are engaged in")}
                    {!user && <>{` `}<RequiresLogin>{i18n("Requires login")}</RequiresLogin></>}
                  </>
                }
              />

              {!channelIdInPWA && <ToolsImage src="/biblearc_tools_1.svg" />}

              {!channelIdInPWA &&
                <StyledCustomSwitch
                  checked={alertSharing}
                  onSet={setAlertSharing}
                  label={
                    <>
                      {i18n("Modules shared with me")}
                      {!user && <>{` `}<RequiresLogin>{i18n("Requires login")}</RequiresLogin></>}
                    </>
                  }
                />
              }

            </Block>

            <Block>

              {updatingNotificationsStatus && <FadedLoading />}

              <NotificationMethodHeading>
                {i18n("Device Notifications")}
                <PhoneAndroidIcon />
              </NotificationMethodHeading>

              <NotificationMethodSubheading>
                {i18n("Affects this device only")}
              </NotificationMethodSubheading>

              {notificationsUnavailable &&
                <Info>
                  {
                    (isIOS || isAndroid)
                      ? i18n("Notifications are not available on this device.")
                      : i18n("Notifications are not available in this browser.")
                  }
                </Info>
              }

              {!notificationsUnavailable &&
                <StyledCustomRadioGroup
                  value={checkingNotificationsStatus ? `` : notificationsStatus}
                  onChange={onDeviceNotificationsChange}
                  radios={deviceNotificationsRadios}
                  disabled={checkingNotificationsStatus || updatingNotificationsStatus}
                />
              }

            </Block>

            {!!user &&
              <Block>

                <NotificationMethodHeading>
                  {i18n("In App Alerts")}
                  <NotificationsNoneIcon />
                </NotificationMethodHeading>

                <NotificationMethodSubheading>
                  {i18n("Affects your account on all devices")}
                </NotificationMethodSubheading>

                {!notificationsUnavailable &&
                  <StyledCustomRadioGroup
                    value={inAppAlertsPref}
                    onChange={onAlertsChange}
                    radios={alertsRadios}
                  />
                }

              </Block>
            }

            {!!user &&
              <Block>

                <NotificationMethodHeading>
                  {i18n("Email Alerts")}
                  <MailOutlineIcon />
                </NotificationMethodHeading>

                <NotificationMethodSubheading>
                  {i18n("Affects your account on all devices")}
                </NotificationMethodSubheading>

                {!notificationsUnavailable &&
                  <StyledCustomRadioGroup
                    value={emailPref}
                    onChange={onEmailChange}
                    radios={alertsRadios}
                  />
                }

                <EmailNote>
                  {i18n("Note: Biblearc announcement emails only sent to users who have opted in to our mailing list.")}
                  {` `}
                  <JoinTheMailingListButton displayAsLink />
                </EmailNote>

              </Block>
            }

          </Blocks>

        </Options>

      </ListContainer>

      <InfoDialog
        open={dialogOpen}
        onOkay={toggleDialogOpen}
        title={
          [ `NONE` ].includes(notificationsStatus)
            ? i18n("Failed to Turn On Notifications")
            : i18n("Failed to Update")
        }
        explanation={
          (isIOS || isAndroid)
            ? (
              i18n("To allow notifications for this app, go to {{settings_sequence}} on your device.", {
                settings_sequence: (
                  isIOS
                    ? i18n("Settings > Notifications")
                    : i18n("Settings > Notifications > App notifications")
                ),
              })
            )
            : i18n("This is likely due to rejecting notifications in the system prompt, whether just now or previously. In that case, you will first need to enable notification through the browser's interface. (Note: Notifications are not available in private browsing mode.)")
        }
      />

    </>
  )
}

export default memo(SettingsNotifications)