import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

import BasicPopover from './BasicPopover'

const StyledPopover = styled(BasicPopover)`
  .MuiPaper-root {
    border-radius: 0;
    transition: opacity ${({ transitionDuration }) => transitionDuration}ms ease-in-out,
                transform ${({ transitionDuration }) => transitionDuration}ms ease-in-out;
  }
  .expand-popover-appear {
    opacity: 0;
    transform: scale(0);
  }
  .expand-popover-appear-active {
    opacity: 1;
    transform: scale(1);
  }
  .expand-popover-exit {
    opacity: 1;
    transform: scale(1);
  }
  .expand-popover-exit-active {
    opacity: 0;
    transform: scale(0);
  }

`

const ExpandPopover = ({
  anchorEl,
  anchorSide,
  transitionDuration=300,
  ...otherProps
}) => {

  const origins = useMemo(
    () => ({
      anchorOrigin: {
        vertical: 'center',
        horizontal: anchorSide || `center`,
      },
      transformOrigin: {
        vertical: 'center',
        horizontal: anchorSide || `center`,
      },
    }),
    [ anchorSide ],
  )

  return (
    <StyledPopover
      elevation={24}
      open={!!anchorEl}
      anchorEl={anchorEl}
      marginThreshold={15}
      transitionDuration={transitionDuration}
      TransitionComponent={CSSTransition}
      TransitionProps={{
        classNames: "expand-popover",
      }}
      {...origins}
      {...otherProps}
    />
  )
}

export default memo(ExpandPopover)