import { memo, useState, useCallback, useContext } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { i18n } from 'inline-i18n'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import useAppSize from '../../../hooks/useAppSize'
import { LoggedInUserContext } from '../../../context/LoggedInUser'

import Header from "../../common/Header"
import CustomSwitch from '../../common/CustomSwitch'
import BibleMapPopover from './BibleMapPopover'

const StyledHeader = styled(Header)`
  position: relative;
  z-index: 2;
  background-color: rgb(255 255 255/.4);
  backdrop-filter: blur(3px);
`

const PublishButton = styled(Button)`
  margin: 5px;
`

const StyledCustomSwitch = styled(CustomSwitch)`
  margin: 5px 10px;

  .MuiFormControlLabel-label {
    white-space: nowrap;
  }
`

const Era = styled.div`
  display: inline-block;
  font-weight: bold;
`

const TitleButton = styled(Button)`
  height: 38px;
  text-transform: none;
  font-size: 18px;
  margin: 5px 0;
`

const MapName = styled.span`
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 3px;
  font-weight: 400;
`

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
  color: rgb(0 0 0/.5);
`

const BibleMapHeader = ({
  viewDraft,
  placeOpen,
  hasUnpublishedDraft,
  toggleShowConfirmPublish,
  toggleViewDraft,
  getEraLabel,
  eraId,
  setEraId,
  showJourneys,
  showDistance,
  showJourneyKey,
  setShowJourneys,
  setShowDistance,
  setShowJourneyKey,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  updateMapTimelineForItem,
  ...otherProps
}) => {

  const user = useContext(LoggedInUserContext)

  const { mobileSize } = useAppSize()

  const [ showOptions, setShowOptions ] = useState(false)

  const openBibleMapPopover = useCallback(() => setShowOptions(true), [])
  const closeBibleMapPopover = useCallback(() => setShowOptions(false), [])

  let title = i18n("Bible Map + Timeline")
  if(eraId !== `bible`) {
    title += ` ${i18n("({{parenthetical_text}})", { parenthetical_text: getEraLabel() })}`
  }

  const titleJsx = (
    mobileSize
      ? i18n("Map")
      : (
        <>
          {i18n("Bible Map + Timeline")}
          {eraId !== `bible` && 
            <>
              {` `}
              <Era>
                {i18n("({{parenthetical_text}})", {
                  parenthetical_text: getEraLabel(),
                })}
              </Era>
            </>
          }
        </>
      )
  )

  return (
    <>

      <StyledHeader
        className="BibleMapHeader-StyledHeader"
        {...otherProps}
        rightExtraComponent={
          [ 'ADMIN', 'EDITOR' ].includes((user || {}).adminLevel)
            ? (
              <>
                {viewDraft &&
                  <PublishButton
                    color="secondary"
                    variant="contained"
                    disableElevation
                    disabled={placeOpen || !hasUnpublishedDraft}
                    onClick={toggleShowConfirmPublish}
                  >
                    {i18n("Publish")}
                  </PublishButton>
                }
                <StyledCustomSwitch
                  checked={viewDraft}
                  onChange={toggleViewDraft}
                  label={i18n("Draft Mode")}
                />
              </>
            )
            : null
        }
        title={title}
      >

        <TitleButton
          className="BibleMapHeader-TitleButton"
          onClick={openBibleMapPopover}
        >

          <MapName>
            {titleJsx}
          </MapName>

          <StyledExpandMoreIcon />

        </TitleButton>

      </StyledHeader>

      <BibleMapPopover
        open={showOptions}
        onClose={closeBibleMapPopover}
        eraId={eraId}
        setEraId={setEraId}
        title={titleJsx}
        showJourneys={showJourneys}
        showDistance={showDistance}
        showJourneyKey={showJourneyKey}
        setShowJourneys={setShowJourneys}
        setShowDistance={setShowDistance}
        setShowJourneyKey={setShowJourneyKey}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        updateMapTimelineForItem={updateMapTimelineForItem}
      />

    </>
  )
}


export default memo(BibleMapHeader)