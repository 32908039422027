import { memo, useCallback, useState } from 'react'
// import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'

import useDragMap from '../../../hooks/useDragMap'
import useSimpleToggle from '../../../hooks/useSimpleToggle'
import useEffectAsync from '../../../hooks/useEffectAsync'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useRefState from '../../../hooks/useRefState'
import useMapLayers from '../../../hooks/useMapLayers'
import useInteractiveTimeline from '../../../hooks/useInteractiveTimeline'
import useStickyRefState from '../../../hooks/useStickyRefState'
import useContextAdjustedMapLayer from '../../../hooks/useContextAdjustedMapLayer'
import useJourneyInfo from '../../../hooks/useJourneyInfo'
import usePersonInfo from '../../../hooks/usePersonInfo'
import useUpdateMapTimelineFromQueryString from '../../../hooks/useUpdateMapTimelineFromQueryString'
import { placeTypeOptions } from './mapDataIsValid'
import { preventDefaultEvent, equalObjs } from '../../../utils/misc'
import eras from './eras'

import AppContent from "../../common/AppContent"
import BibleMapSnackbar from './BibleMapSnackbar'
import BibleMapPlace from './BibleMapPlace'
import BibleMapTimeline from './BibleMapTimeline'
import JourneyArrow from './JourneyArrow'
import BibleMapKey from './BibleMapKey'
import BibleMapHeader from './BibleMapHeader'
import FadedLoading from '../../common/FadedLoading'

export const getDefaultNames = (name=``) => ([{
  id: uuidv4(),
  name,
}])

export const GET_DEFAULT_PLACE = () => ({
  names: getDefaultNames(),
  events: [],
  type: placeTypeOptions[0],
})

export const GET_DEFAULT_EVENT = () => ({
  names: getDefaultNames(),
  dates: [{}],
  passages: [],
  personIds: [],
})

export const GET_DEFAULT_JOURNEY = name => ({
  id: uuidv4(),
  names: getDefaultNames(name),
})

export const GET_DEFAULT_PERSON = name => ({
  id: uuidv4(),
  names: getDefaultNames(name),
})

const StyledAppContent = styled(AppContent)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  transition: opacity .25s ease-in-out;
  opacity: ${({ $opacity }) => $opacity};
  user-select: none;
`

const MapBGPositionAdjuster = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform-origin: 0 0;
`

const MapBGScaleAdjuster = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transform-origin: 0 0;
`

const MapImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: ${({ $stage }) => $stage === `PUBLISHED` ? `move` : `pointer`};
`

const BibleMap = ({ ...props }) => {

  const [ showJourneys, setShowJourneys ] = useStickyRefState(({ id: `BibleMap:showJourneys`, defaultValue: true }))
  const [ showJourneyKey, setShowJourneyKey ] = useStickyRefState(({ id: `BibleMap:showJourneyKey`, defaultValue: true }))
  const [ showDistance, setShowDistance ] = useStickyRefState(({ id: `BibleMap:showDistance`, defaultValue: true }))
  const layer = `BIBLE`  // TODO: possibly expand to other layers as well
  const [ eraId, setEraId, getEraId ] = useStickyRefState(({ id: `BibleMap:eraId`, defaultValue: `bible` }))
  const { timelineStartDate, timelineEndDate, getEraLabel } = eras.find(({ id }) => id === eraId) || eras[0]

  const [ viewDraft, toggleViewDraft ] = useSimpleToggle()
  const stage = viewDraft ? `DRAFT` : `PUBLISHED`

  const [ mapImgLoaded, setMapImgLoaded ] = useSimpleToggle()
  const onMapImgLoad = useCallback(() => setMapImgLoaded(true), [ setMapImgLoaded ])
  const [ selectedPlace, setSelectedPlace, getSelectedPlace ] = useRefState()
  const [ selectedJourney, setSelectedJourney ] = useState()
  const [ selectedPerson, setSelectedPerson ] = useState()
  const [ expandedEventId, setExpandedEventId ] = useState()
  const closeSelected = useCallback(() => setSelectedPlace({ ...getSelectedPlace(), closed: true }), [ setSelectedPlace, getSelectedPlace ])
  const { positionAdjusterStyle, scaleAdjusterStyle, getPinStyle, getPlaceInfo, mapViewInfo, moving, goCenterAndScale, ...containerProps } = useDragMap({ stage })
  const timelineProps = useInteractiveTimeline({ timelineStartDate, timelineEndDate })
  const { fromDate, toDate, setFromDate, setToDate, safeSetDetailsWidth } = timelineProps
  const getMapViewInfo = useInstanceValue(mapViewInfo)
  const [ movingPlace, toggleMovingPlace ] = useSimpleToggle()

  const { getMapLayerState, getMapLayerId, toggleShowConfirmPublish, confirmDialog } = useMapLayers({ layer, stage, toggleViewDraft })
  const mapLayerId = getMapLayerId({ layer, stage })
  const [ mapLayer, setMapLayer ] = getMapLayerState({ layer, stage })
  const { eventsByJourneyId, colorByJourneyId, journeyDateRangeById } = useJourneyInfo({ mapLayer })
  const { eventsByPersonId } = usePersonInfo({ mapLayer })
  const { places, allPlaces, journeys, allJourneys, persons } = useContextAdjustedMapLayer({ mapLayer, stage, fromDate, toDate, getPlaceInfo, selectedPlace, journeyDateRangeById, colorByJourneyId, showJourneys, eventsByJourneyId })

  const createNewPlace = useCallback(
    event => {
      preventDefaultEvent(event)
      const { scale, translateX, translateY, top, left, baseMapWidth, baseMapHeight } = getMapViewInfo()

      const totalWidth = baseMapWidth * scale
      const totalHeight = baseMapHeight * scale

      const x = (event.clientX - left - translateX) / totalWidth
      const y = (event.clientY - top - translateY) / totalHeight

      if(movingPlace) {
        setSelectedPlace({
          ...getSelectedPlace(),
          locations: [{
            id: uuidv4(),
            x,
            y,
          }],
        })
      } else {
        setSelectedPlace({
          ...GET_DEFAULT_PLACE(),
          locations: [{
            id: uuidv4(),
            x,
            y,
          }],
        })
      }

    },
    [ getMapViewInfo, setSelectedPlace, getSelectedPlace, movingPlace ],
  )

  const placeOpen = !!selectedPlace && !selectedPlace.closed
  const editingNewPlace = placeOpen && !selectedPlace.id

  const publishedMapLayer = getMapLayerState({ layer, stage: `PUBLISHED` })[0]
  const hasUnpublishedDraft = (
    !!mapLayer
    && !!publishedMapLayer
    && !equalObjs(
      mapLayer.data,
      publishedMapLayer.data,
    )
  )

  useEffectAsync(
    () => {
      if(!viewDraft && placeOpen) {
        closeSelected()
      }
    },
    [ viewDraft ],
  )

  const updateMapTimelineForItem = useUpdateMapTimelineFromQueryString({
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    placeOpen,
    selectedPlace,
    setSelectedPlace,
    setExpandedEventId,
    setSelectedJourney,
    setSelectedPerson,
    closeSelected,
    allPlaces,
    allJourneys,
    persons,
    eventsByJourneyId,
    eventsByPersonId,
    getEraId,
    setEraId,
    mapViewInfo,
    goCenterAndScale,
    safeSetDetailsWidth,
  })

  const nthRouteBetweenThesePlaces = {}

  return (
    <>

      <BibleMapHeader
        {...props}
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        viewDraft={viewDraft}
        placeOpen={placeOpen}
        hasUnpublishedDraft={hasUnpublishedDraft}
        toggleShowConfirmPublish={toggleShowConfirmPublish}
        toggleViewDraft={toggleViewDraft}
        getEraLabel={getEraLabel}
        eraId={eraId}
        setEraId={setEraId}
        showJourneys={showJourneys}
        showDistance={showDistance}
        showJourneyKey={showJourneyKey}
        setShowJourneys={setShowJourneys}
        setShowDistance={setShowDistance}
        setShowJourneyKey={setShowJourneyKey}
        updateMapTimelineForItem={updateMapTimelineForItem}
      />

      <StyledAppContent
        className="dark-mode-exempt"
        {...containerProps}
      >

        {!mapImgLoaded && <FadedLoading />}

        {!!scaleAdjusterStyle &&
          <MapBGPositionAdjuster
            style={positionAdjusterStyle}
          >
            <MapBGScaleAdjuster
              style={scaleAdjusterStyle}
            >
              <MapImg
                className={`BibleMap-MapImg BibleMap-MapImg-${mapImgLoaded ? `loaded` : `loading`}`}
                src="/study_bible_map.svg"
                onClick={moving ? null : ((viewDraft && (!placeOpen || movingPlace)) ? createNewPlace : closeSelected)}
                onLoad={onMapImgLoad}
                $stage={stage}
              />
              {mapImgLoaded && places.map(place => {
                const selected = placeOpen && place.id === (selectedPlace || {}).id
                if(selected && movingPlace) return null
                return (
                  <BibleMapPlace
                    key={place.id}
                    place={place}
                    setSelectedPlace={setSelectedPlace}
                    getStyle={getPinStyle}
                    $selected={selected}
                    stage={stage}
                    mapLayerId={mapLayerId}
                    {...(movingPlace ? { onClick: createNewPlace } : {})}
                  />
                )
              })}
              {editingNewPlace &&
                <BibleMapPlace
                  place={selectedPlace}
                  getStyle={getPinStyle}
                  $selected
                  stage={stage}
                  mapLayerId={mapLayerId}
                  {...(movingPlace ? { onClick: createNewPlace } : {})}
                />
              }
              {mapImgLoaded && journeys.map(journey => (
                eventsByJourneyId[journey.id]
                  .filter(({ place }, idx) => (  // get rid of consecutive events going from one place to another
                    idx === 0
                    || place.id !== eventsByJourneyId[journey.id][idx-1].place.id
                  ))
                  .map(({ place }, idx, ary) => {
                    if(idx === 0) return null
                    const placeIds = `${ary[idx-1].place.id} ${place.id}`
                    nthRouteBetweenThesePlaces[placeIds] = nthRouteBetweenThesePlaces[placeIds] || 1
                    nthRouteBetweenThesePlaces[placeIds]++
                    return (
                      <JourneyArrow
                        key={`${place.id} ${idx}`}
                        placeA={ary[idx-1].place}
                        placeB={place}
                        getPlaceInfo={getPlaceInfo}
                        color={`${colorByJourneyId[journey.id]}bf`}
                        scale={mapViewInfo.scale}
                        nthRouteBetweenThesePlaces={nthRouteBetweenThesePlaces[placeIds]}
                        $opacity={journey.opacity}
                      />
                    )
                  })
              ))}
            </MapBGScaleAdjuster>
          </MapBGPositionAdjuster>
        }

      </StyledAppContent>

      <BibleMapKey
        showDistance={showDistance}
        showJourneyKey={showJourneyKey}
        journeys={journeys}
        eventsByJourneyId={eventsByJourneyId}
        colorByJourneyId={colorByJourneyId}
        mapViewInfo={mapViewInfo}
        updateMapTimelineForItem={updateMapTimelineForItem}
      />

      <BibleMapTimeline
        timelineStartDate={timelineStartDate}
        timelineEndDate={timelineEndDate}
        mapLayer={mapLayer}
        colorByJourneyId={colorByJourneyId}
        eraId={eraId}
        updateMapTimelineForItem={updateMapTimelineForItem}
        {...timelineProps}
      />

      <BibleMapSnackbar
        open={placeOpen}
        viewDraft={viewDraft}
        mapLayerId={mapLayerId}
        closeSelected={closeSelected}
        places={places}
        journeys={allJourneys}
        persons={persons}
        setMapLayer={setMapLayer}
        movingPlace={movingPlace}
        toggleMovingPlace={toggleMovingPlace}
        eventsByJourneyId={eventsByJourneyId}
        selectedPlace={selectedPlace}
        selectedJourney={selectedJourney}
        selectedPerson={selectedPerson}
        expandedEventId={expandedEventId}
        setSelectedPlace={setSelectedPlace}
        setSelectedJourney={setSelectedJourney}
        setSelectedPerson={setSelectedPerson}
        setExpandedEventId={setExpandedEventId}
      />

      {confirmDialog}

    </>
  )
}

export default memo(BibleMap)