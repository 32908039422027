import { memo, useCallback, useState, useMemo } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { i18n } from 'inline-i18n'
import { v4 as uuidv4 } from 'uuid'
import { useMutation } from '@apollo/client'

import useDataQuery from '../../../hooks/useDataQuery'
import useRefState from '../../../hooks/useRefState'
import useMutationContext from '../../../hooks/useMutationContext'

import GlobalAlertsItemRow from './GlobalAlertsItemRow'
import Loading from '../../common/Loading'
import NotificationsItem from '../../common/NotificationsItem'
import AlertsItem from '../../common/AlertsItem'

import globalAlertsItemsQuery from '../../../graphql/queries/globalAlertsItems'
import updateGlobalAlertsItemMutation from '../../../graphql/mutations/updateGlobalAlertsItem'

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Container = styled.div`
  align-self: center;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const NewAlertContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 5px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledTextField = styled(TextField)`
  width: 300px;
  max-width: calc(100vw - 80px);
  margin-bottom: 20px;
`

const StyledTextField2 = styled(StyledTextField)`
  width: 500px;
  font-size: 14px;
`

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  margin-bottom: 20px;

  .MuiInputBase-root {
    padding: 0;
  }

  .MuiInputBase-input {
    padding-top: 10.5px;
    padding-bottom: 10.5px;
  }
`

const Heading = styled.div`
  margin: 35px 0 15px;
  font-size: 18px;
  font-weight: 500;
`

const PastAlerts = styled.div`
  position: relative;
  min-height: 150px;
  min-width: 150px;
  margin-top: 20px;
`

const None = styled.div`
`

const PreviewLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
`

const AlertsItemContainer = styled.div`
  width: 270px;
  margin-bottom: 15px;
`

const NotificationItemContainer = styled.div`
  margin-bottom: 15px;
`

const initialNewGlobalAlertItem = {
  title: ``,
  content: ``,
  notificationBody: ``,
  importance: `ALERT`,
  expirationDate: new Date(Date.now() + 1000*60*60*24*7),
  notificationClickUrl: ``,
}

const AlertsTab = () => {

  const [ search, setSearch ] = useState(``)
  const [ updating, setUpdating ] = useState(false)
  const [ searchInEdit, setSearchInEdit, getSearchInEdit ] = useRefState(``)

  const [ newGlobalAlert, setNewGlobalAlert, getNewGlobalAlert ] = useRefState(initialNewGlobalAlertItem)

  const [ updateGlobalAlertsItem ] = useMutation(updateGlobalAlertsItemMutation)
  const context = useMutationContext()

  const { globalAlertsItems, loading, refetch } = useDataQuery({
    globalAlertsItemsQuery,
    variables: {
      query: search,
    },
    fetchPolicyNetworkNoRerun: true,
  })

  const commonStyledTextFieldProps = useMemo(
    () => ({
      variant: "outlined",
      size: "small",
      InputLabelProps: {
        shrink: true,
      },
      disabled: loading || updating,
    }),
    [ loading, updating ],
  )

  const onChange = useCallback(
    eventOrDate => {
      const { target } = eventOrDate
      const updatedGlobalAlert = { ...getNewGlobalAlert() }

      if(!target) {
        updatedGlobalAlert.expirationDate = eventOrDate
        setNewGlobalAlert(updatedGlobalAlert)
        return
      }

      const key = target.closest ? target.closest('[data-key]').getAttribute('data-key') : `importance`
      updatedGlobalAlert[key] = target.value

      setNewGlobalAlert(updatedGlobalAlert)
    },
    [ getNewGlobalAlert, setNewGlobalAlert ],
  )

  const onChangeSearch = useCallback(({ target }) => setSearchInEdit(target.value), [ setSearchInEdit ])

  const onBlurSearch = useCallback(
    () => setSearch(getSearchInEdit().trim()),
    [ getSearchInEdit ],
  )

  const onKeyPressSearch = useCallback(
    ({ target, key }) => {
      if(key === 'Enter') {
        target.blur()
      }
    },
    [],
  )

  const goCreateGlobalAlert = useCallback(
    async () => {

      setUpdating(true)

      const input = { ...getNewGlobalAlert() }
      const id = `global-alert\n${input.notificationClickUrl}\n${uuidv4()}`
      delete input.notificationClickUrl
      input.expiresAt = input.expirationDate.getTime()
      delete input.expirationDate
      input.info = {
        notificationBody: input.notificationBody,
      }
      if(input.notificationIcon) { input.info.icon = input.notificationIcon }
      if(input.notificationImage) { input.info.image = input.notificationImage }
      delete input.notificationBody
      delete input.notificationIcon
      delete input.notificationImage

      await updateGlobalAlertsItem({
        variables: {
          id,
          input,
        },
        context,
      })

      setNewGlobalAlert(initialNewGlobalAlertItem)
      await refetch()

      setUpdating(false)

    },
    [ getNewGlobalAlert, updateGlobalAlertsItem, context, setUpdating, setNewGlobalAlert, refetch ],
  )

  const readyToCreate = !!(
    newGlobalAlert.title.trim()
    && newGlobalAlert.content.trim()
    && newGlobalAlert.notificationBody.trim()
    && newGlobalAlert.notificationClickUrl.trim()
  )

  return (
    <OuterContainer>
      <Container>

        <NewAlertContainer>

          <StyledTextField
            {...commonStyledTextFieldProps}
            label={i18n("Title (Alert, Notification, Email)", "", "admin")}
            placeholder={i18n("E.g. New Course", "", "admin")}
            value={newGlobalAlert.title}
            inputProps={{ maxLength: 50 }}
            onChange={onChange}
            data-key="title"
          />

          {!!newGlobalAlert.title &&
            <>
              <StyledTextField
                {...commonStyledTextFieldProps}
                label={i18n("Notification Message", "", "admin")}
                value={newGlobalAlert.notificationBody}
                multiline
                inputProps={{ maxLength: 150 }}
                onChange={onChange}
                data-key="notificationBody"
              />
              <StyledTextField
                {...commonStyledTextFieldProps}
                label={i18n("Notification Click Relative URL", "", "admin")}
                value={newGlobalAlert.notificationClickUrl}
                onChange={onChange}
                data-key="notificationClickUrl"
                placeholder={i18n("E.g. /study-bible/123", "", "admin")}
              />
              <StyledTextField
                {...commonStyledTextFieldProps}
                label={i18n("Notification Icon Full URL", "", "admin")}
                value={newGlobalAlert.notificationIcon}
                onChange={onChange}
                data-key="notificationIcon"
                placeholder={i18n("(Optional)", "", "admin")}
              />
              <StyledTextField
                {...commonStyledTextFieldProps}
                label={i18n("Notification Image Full URL", "", "admin")}
                value={newGlobalAlert.notificationImage}
                onChange={onChange}
                data-key="notificationImage"
                placeholder={i18n("(Optional)", "", "admin")}
              />
              <StyledTextField2
                {...commonStyledTextFieldProps}
                label={i18n("Content (Alert, Email)", "", "admin")}
                placeholder={`E.g.\n\n*A Study in Job* now open for enrollment!\n\n[![](https://a.com/img.jpg)](https://equip.biblearc.com/course/job)\n\n[Enroll now!](https://equip.biblearc.com/course/job)`}
                value={newGlobalAlert.content}
                multiline
                minRows={3}
                onChange={onChange}
                data-key="content"
              />
              <StyledTextField
                {...commonStyledTextFieldProps}
                label={i18n("Importance (Alert)", "", "admin")}
                select
                value={newGlobalAlert.importance}
                onChange={onChange}
              >
                <MenuItem value="ALERT">
                  {i18n("Alert (GREY)", "", "admin")}
                </MenuItem>
                <MenuItem value="EMPHASIZED">
                  {i18n("Emphasized (BLUE)", "", "admin")}
                </MenuItem>
                <MenuItem value="URGENT">
                  {i18n("Urgent (RED)", "", "admin")}
                </MenuItem>
              </StyledTextField>
              <StyledKeyboardDatePicker
                views={['date']}
                label={i18n("Expiration Date (Alert)", "", "admin")}
                minDate={new Date()}
                maxDate={new Date(Date.now() + 1000*60*60*24*365)}
                value={newGlobalAlert.expirationDate}
                onChange={onChange}
                inputProps={{
                  disabled: true,
                }}
                inputVariant="outlined"
                slots={{
                  textField: StyledTextField,
                }}
                KeyboardButtonProps={{
                  disabled: loading || updating,
                }}
              />
              <PreviewLabel>
                {i18n("Notification Preview")}
              </PreviewLabel>
              <NotificationItemContainer>
                <NotificationsItem
                  {...newGlobalAlert}
                />
              </NotificationItemContainer>
              <PreviewLabel>
                {i18n("Alert Preview")}
              </PreviewLabel>
              <AlertsItemContainer>
                <AlertsItem
                  {...newGlobalAlert}
                  createdAt={Date.now()}
                />
              </AlertsItemContainer>
            </>
          }

          <Button
            onClick={goCreateGlobalAlert}
            variant="contained"
            disableElevation
            color="secondary"
            disabled={!readyToCreate}
          >
            {i18n("Add New Global Alert")}
          </Button>

        </NewAlertContainer>

        <Heading>
          {i18n("Recent Global Alerts", "", "admin")}
        </Heading>

        <StyledTextField
          {...commonStyledTextFieldProps}
          label={i18n("Search", "", "admin")}
          placeholder={i18n("Title or reference code", "", "admin")}
          value={searchInEdit}
          onChange={onChangeSearch}
          onBlur={onBlurSearch}
          onKeyPress={onKeyPressSearch}
        />

        <PastAlerts>

          {search === searchInEdit && !loading && !updating && !!globalAlertsItems && globalAlertsItems.map(globalAlertsItem => (
            <GlobalAlertsItemRow
              key={globalAlertsItem.id}
              globalAlertsItem={globalAlertsItem}
            />
          ))}

          {search === searchInEdit && !loading && !updating && !globalAlertsItems &&
            <None>
              {i18n("None found", "", "admin")}
            </None>
          }

          {(loading || updating) && <Loading />}

        </PastAlerts>

      </Container>
    </OuterContainer>
  )
}


export default memo(AlertsTab)