import { memo, useState, useContext } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import styled from 'styled-components'
import { useToggle } from 'react-use'
import { getBookIdFromUsfmBibleBookAbbr } from '@bibletags/bibletags-ui-helper'
import { v4 as uuidv4 } from 'uuid'

import { getLocalStorage, cloneObj, isPWA } from '../../../utils/misc'
import { setLocalStorage } from '../../../utils/misc'
import useInitialValueUpdater from "../../../hooks/useInitialValueUpdater"
import useInstanceValue from "../../../hooks/useInstanceValue"
import useAppSize from "../../../hooks/useAppSize"
import useIsLoggedIn from '../../../hooks/useIsLoggedIn'
import useEffectAsync from '../../../hooks/useEffectAsync'
import { LoggedInUserContext } from '../../../context/LoggedInUser'

import HomeDashboard from "./HomeDashboard"
import AppContent from "../../common/AppContent"
import DirectLoadSnackbar from "../../common/DirectLoadSnackbar"
import PassageArea, { getDefaultPassageBookmarks } from "../../passage/PassageArea"
import JoinTheMailingListButton from '../../common/JoinTheMailingListButton'
import BSBInstallSnackbar from './BSBInstallSnackbar'
import HomeHeader from './HomeHeader'

const PassageContainer = styled.div`
  flex: 1;
`

// On load, I want the slider section open if user is not logged in; but on return to
// Home, I want to use the latest value. Hence this variable
let initialHomeSliderSectionOpen = true

// The useToggle hook cannot take a function like useState can. So getting the initial value
// here to avoid checking localstorage on each render.
const getInitialHomePassageBookmarks = () => {
  const initialHomePassageBookmarks = getLocalStorage(`initialValue:homePassageBookmarks`, [])

  if((initialHomePassageBookmarks || []).length === 0) {
    return getDefaultPassageBookmarks()
  }

  return initialHomePassageBookmarks
}

const Home = ({ ...props }) => {

  const user = useContext(LoggedInUserContext)
  const { hasMyPlan } = user || {}

  const history = useHistory()
  const location = useLocation()
  const versionId = location.pathname.split('/')[2]
  const searchText = decodeURIComponent((location.hash.match(/^#search=(.*)$/) || [])[1] || "")

  const isLoggedIn = useIsLoggedIn()
  const { mobileSize } = useAppSize()

  const [ homeSliderSectionOpen, toggleHomeSliderSectionOpen ] = useToggle(initialHomeSliderSectionOpen)
  const [ homePassageBookmarks, setHomePassageBookmarks ] = useState(getInitialHomePassageBookmarks)
  const [ snackbarInfo, setSnackbarInfo ] = useState({ open: false })

  initialHomeSliderSectionOpen = homeSliderSectionOpen

  useInitialValueUpdater({
    ...(versionId ? {} : { homePassageBookmarks }),
  })

  const getHomePassageBookmarks = useInstanceValue(homePassageBookmarks)

  useEffectAsync(
    () => {

      if(versionId) {

        const newPassageBookmarks = cloneObj(getHomePassageBookmarks())
        newPassageBookmarks.forEach(newPassageBookmark => delete newPassageBookmark.width)

        const ref = {
          bookId: getBookIdFromUsfmBibleBookAbbr(location.pathname.split('/')[3]) || 1,
          chapter: parseInt(location.pathname.split('/')[4] || 1, 10),
        }

        const searchParams = new URLSearchParams(location.search)
        let specialMarkup, range
        try {
          range = searchParams.get(`range`)
          if(typeof range === `string` && /^[0-9]{8}-[0-9]{8}$/.test(range)) {
            range = {
              start: { loc: range.split(/-/g)[0] },
              end: { loc: range.split(/-/g)[1] },
            }
          } else {
            range = JSON.parse(range)
          }
        } catch(e) { console.error(e) }
        if(range) {
          specialMarkup = [{
            ...range,
            type: "NORMAL-HIGHLIGHT",
            color: "SYSTEM",
          }]
        }

        newPassageBookmarks.unshift({
          id: uuidv4(),
          width: 1,
          ref,
          versionId,
          showStudyBible: true,
          specialMarkup,
        })

        setHomePassageBookmarks(newPassageBookmarks)

        const closeSnackbar = () => setSnackbarInfo({ ...snackbarInfo, open: false })
        const onBookmarkThisSpot = () => {
          setLocalStorage(`initialValue:homePassageBookmarks`, getHomePassageBookmarks())
          history.replace(`/`)
          closeSnackbar()
        }
        const snackbarInfo = {
          open: true,
          onBackToPrevious: () => {
            history.replace(`/`)
            closeSnackbar()
          },
          onBookmarkThisSpot,
        }
        if(getLocalStorage(`initialValue:homePassageBookmarks`)) {
          setSnackbarInfo(snackbarInfo)
        } else {
          setTimeout(onBookmarkThisSpot)
        }
      }
    },
    [],
  )

  const showSliderSection = !hasMyPlan && !mobileSize

  if(isLoggedIn && !user.createdAt) return null  // prevents flash of side panel

  if(mobileSize && homeSliderSectionOpen && !hasMyPlan && !searchText && !isPWA) {
    return (
      <HomeDashboard
        fullScreen
        toggleSliderSectionOpen={toggleHomeSliderSectionOpen}
      />
    )
  }

  return (
    <>

      <HomeHeader
        homeSliderSectionOpen={homeSliderSectionOpen}
        toggleHomeSliderSectionOpen={toggleHomeSliderSectionOpen}
        homePassageBookmarks={homePassageBookmarks}
        setHomePassageBookmarks={setHomePassageBookmarks}
        getHomePassageBookmarks={getHomePassageBookmarks}
        showSliderSection={showSliderSection}
        {...props}
      />

      <AppContent>

        <PassageContainer>
          <PassageArea
            passageBookmarks={homePassageBookmarks}
            setPassageBookmarks={setHomePassageBookmarks}
            getPassageBookmarks={getHomePassageBookmarks}
            allowMultipleColumns={true}
            // display options only across app? Yes, with different text size adj option for Heb and Greek
          />
        </PassageContainer> 

        {showSliderSection &&
          <HomeDashboard
            homeSliderSectionOpen={homeSliderSectionOpen}
          />
        }

        <BSBInstallSnackbar />

      </AppContent>

      <DirectLoadSnackbar {...snackbarInfo} />
      <JoinTheMailingListButton autoShowAfterCreation />

    </>
  )
}

export default memo(Home)