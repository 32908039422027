import { memo, useContext } from 'react'
import styled from 'styled-components'
// import { NavLink } from "react-router-dom"
import { i18n } from 'inline-i18n'
import { i18nReact } from 'inline-i18n/build/i18nReact'
import CheckIcon from '@material-ui/icons/Check'
import queryString from 'query-string'
import { useApolloClient } from '@apollo/client'

import { LoggedInUserContext } from '../../../context/LoggedInUser'
import userQuery from '../../../graphql/queries/user'
import useInstanceValue from '../../../hooks/useInstanceValue'
import useSetTimeout from '../../../hooks/useSetTimeout'
import useEffectAsync from '../../../hooks/useEffectAsync'

import Header from "../../common/Header"
import NavLinkOrAWithDisable from '../../common/NavLinkOrAWithDisable'

const PageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;

  @media (max-width: 850px) {
    display: none;
  }
`

const MobilePageTitle = styled.div`
  line-height: 48px;
  font-size: 18px;
  display: none;

  @media (max-width: 850px) {
    display: block;
  }
`

const HeaderLogoImg = styled.img`
  height: 36px;
  vertical-align: top;
  margin: 5px -4px 0 -6px;
`

const Container = styled.div`
  flex: 1;
  overflow: auto;
  padding: 10px 20px calc(100vh - 150px);
  background-color: white;
`

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SuccessContainer = styled.div`
  margin: 40px 0 -30px;
  display: flex;
  justify-content: center;
`

const Success = styled.div`
  font-size: 18px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background-color: ${({ theme }) => theme.palette.grey[50]};
  align-self: center;
  padding: 10px 17px;
  border-radius: 5px;

  .MuiSvgIcon-root {
    height: 25px;
    width: auto;
    vertical-align: middle;
    color: ${({ theme }) => theme.palette.primary.main};
    margin: -4px 5px 0 -2px;
  }
`

const Features = styled.div`
  text-align: center;
  margin: 60px 0 10px;
  font-weight: 500;
  font-size: 18px;
`

const FeaturesList = styled.ol`
  max-width: 540px;
  margin: 0 auto;
  font-weight: 300;
`

// const Whoops = styled.div`
//   text-align: center;
//   font-weight: 300;
//   margin-top: 45px;
// `

const ComingSoon = styled.div`
  font-size: 32px;
  text-align: center;
  margin: 80px 0 130px;
`

const SeePromo = styled.div`
  font-size: 14px;
  margin: 10px 0;
  text-align: center;
`

const LogoImg = styled.img`
  height: 180px;
  margin: 24px 0 10px;
`

const BigIdea = styled.div`
  text-align: center;
  font-weight: 300;
  margin: -10px 0 10px;
`

const YourMyBSBSubscription = ({ ...props }) => {

  let {
    'purchase-successful': purchaseSuccessful,
    'subscription-granted': subscriptionGranted,
  } = queryString.parse(window.location.search)
  purchaseSuccessful = purchaseSuccessful !== undefined
  subscriptionGranted = subscriptionGranted !== undefined

  const client = useApolloClient()
  const user = useContext(LoggedInUserContext)
  // const { hasMyPlan } = user || {}
  const getUser = useInstanceValue(user)
  const [ setDoUserQueryTimeout ] = useSetTimeout()

  useEffectAsync(
    // Immediately after purchase, keep doing user queries until stripe subscription is found.
    () => {
      if(!purchaseSuccessful) return

      let timesChecked = 0

      const checkAndTryAfterTimeout = () => {
        setDoUserQueryTimeout(
          async () => {
            const user = getUser()
            if(!user) return

            const hasStripeSubscription = !!(user.activeSubscriptions || []).filter(({ type }) => type === 'STRIPE')[0]

            if(!hasStripeSubscription) {
              await client.query({
                context: {
                  isBackupServerQuery: true,  // forcing the await to wait until run through the server via this option
                  forceSaveServerResultToDexie: true,    
                },
                query: userQuery,
                fetchPolicy: `network-only`,
              })
              if(++timesChecked < 10) {
                checkAndTryAfterTimeout()
              }
            }
          },
          1000,
        )
      }

      checkAndTryAfterTimeout()
    },
    [],
  )

  return (
    <>

      <Header {...props}>
        <PageTitle>
          {i18nReact("About {{logo}}", {
            logo: <HeaderLogoImg src="/my_biblearc_study_bible_1.svg" />
          })}
        </PageTitle>
        <MobilePageTitle>
          {i18n("My BSB")}
        </MobilePageTitle>
      </Header>

      <Container>
        <Content>

          {purchaseSuccessful &&
            <SuccessContainer>
              <Success>
                <CheckIcon />
                {i18n("Purchase successful")}
              </Success>
            </SuccessContainer>
          }

          {subscriptionGranted &&
            <SuccessContainer>
              <Success>
                <CheckIcon />
                {i18n("Subscription granted")}
              </Success>
            </SuccessContainer>
          }

          <LogoImg src="/my_biblearc_study_bible_2.svg" />

          <BigIdea>
            {i18n("Make The Biblearc Study Bible your own.")}
          </BigIdea>

          <Features>
            {i18n("Features")}
          </Features>

          <FeaturesList>
            <li>{i18n("Integrate your church’s sermons, and videos from popular ministries.")}</li>
            <li>{i18n("Bookmark sermons, and even specific timestamps within sermons.")}</li>
            <li>{i18n("Choose from 99 different styles to markup your Bible and annotate.")}</li>
            <li>{i18n("Add your own notes to the Map + Timeline and save map snapshots.")}</li>
          </FeaturesList>

          <ComingSoon>
            {i18n("Coming Nov 20th.")}
          </ComingSoon>

          <SeePromo>
            {i18nReact("Learn about the {{the_biblearc_study_bible}}.", {
              the_biblearc_study_bible: (
                <NavLinkOrAWithDisable to="/about-the-biblearc-study-bible" exact>
                  {i18n("The Biblearc Study Bible")}
                </NavLinkOrAWithDisable>
              )
            })}
          </SeePromo>

        </Content>
      </Container>

    </>
  )
}


export default memo(YourMyBSBSubscription)


// import { combineItems } from "@bibletags/bibletags-ui-helper"

// <Point>
//   {i18n("View sermons from popular ministries of your choosing, right along side your Bible.")}
//   <PopularMinistriesList>
//     <PopularMinistriesCurrent>
//       {i18n("Currently Available:")}
//     </PopularMinistriesCurrent>
//     {` `}
//     {combineItems(...STUDY_BIBLE_CHANNELS.map(({ label }) => label))}
//   </PopularMinistriesList>
// </Point>
// 
// <Point>
//   {i18n("See the sermons from your local church right next to the passages they address.")}
// </Point>
// 
// <Point>
//   {i18n("Bookmark spots in sermons you want to remember.")}
// </Point>
// 
// <Point>
//   {i18n("Save snapshots and add notes within the Map-Timeline.")}
// </Point>
// 
// <Point>
//   {i18n("Markup your Bible with highlights, underlines, circles, and arrows, and add rich text notes.")}
// </Point>
// 
// <Point>
//   {i18n("Create a library of your own “Look at the Book”-style sketches.")}
// </Point>
