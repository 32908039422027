import { memo, useCallback, useContext } from 'react'
import { i18n } from 'inline-i18n'
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import { PassageShowNotesContext, PassageShowCfsContext, PassageTextSizesContext,
         PassageLineSpacingSizesContext, PassageShowGreekAccentsContext,
         PassageShowCantillationContext, PassageShowHebrewVowelsContext,
         PassageGreekPunctuationContext } from '../../context/LocalInfo'
import useInstanceValue from '../../hooks/useInstanceValue'
import { db } from '../../utils/database'

import OptionsPopover from '../common/OptionsPopover'
import CustomCheckbox from '../common/CustomCheckbox'
import CustomSlider from '../common/CustomSlider'
import useSimpleToggle from '../../hooks/useSimpleToggle'
import DetailAccordion from '../search/DetailAccordion'

const setLocalInfo = (id, value) => db.localInfo.put({ id, value })
const setSliderLocalInfo = (id, previousValue, value) => {
  setLocalInfo(
    id,
    {
      ...previousValue,
      base: value / 100,
    },
  )
}

const Container = styled.div`
  padding: 15px;
`

const Heading = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  margin-bottom: 12px;
`

const MinorHeading = styled.div`
  color: ${({ theme }) => theme.palette.grey[500]};
  margin: 8px 0 5px;
  font-size: 12px;
`

const StyledTextField = styled(TextField)`
  margin: 10px 0 5px;
`

const PassageDisplaySettingsPopover = ({
  inMenu,
  ...otherProps
}) => {

  const [ showHebrewGreek, toggleShowHebrewGreek ] = useSimpleToggle()

  const passageTextSizes = useContext(PassageTextSizesContext)
  const getPassageTextSizes = useInstanceValue(passageTextSizes)
  const updateBaseTextSize = useCallback((event, value) => setSliderLocalInfo('passageTextSizes', getPassageTextSizes(), value), [ getPassageTextSizes ])

  const passageLineSpacingSizes = useContext(PassageLineSpacingSizesContext)
  const getPassageLineSpacingSizes = useInstanceValue(passageLineSpacingSizes)
  const updateBaseLineSpacing = useCallback((event, value) => setSliderLocalInfo('passageLineSpacingSizes', getPassageLineSpacingSizes(), value), [ getPassageLineSpacingSizes ])

  const passageShowCfs = useContext(PassageShowCfsContext)
  const getPassageShowCfs = useInstanceValue(passageShowCfs)
  const togglePassageShowCfs = useCallback(() => setLocalInfo('passageShowCfs', !getPassageShowCfs()), [ getPassageShowCfs ])

  const passageShowNotes = useContext(PassageShowNotesContext)
  const getPassageShowNotes = useInstanceValue(passageShowNotes)
  const togglePassageShowNotes = useCallback(() => setLocalInfo('passageShowNotes', !getPassageShowNotes()), [ getPassageShowNotes ])

  const passageShowHebrewVowels = useContext(PassageShowHebrewVowelsContext)
  const getPassageShowHebrewVowels = useInstanceValue(passageShowHebrewVowels)
  const togglePassageShowHebrewVowels = useCallback(() => setLocalInfo('passageShowHebrewVowels', !getPassageShowHebrewVowels()), [ getPassageShowHebrewVowels ])

  const passageShowCantillation = useContext(PassageShowCantillationContext)
  const getPassageShowCantillation = useInstanceValue(passageShowCantillation)
  const togglePassageShowCantillation = useCallback(() => setLocalInfo('passageShowCantillation', !getPassageShowCantillation()), [ getPassageShowCantillation ])

  const passageShowGreekAccents = useContext(PassageShowGreekAccentsContext)
  const getPassageShowGreekAccents = useInstanceValue(passageShowGreekAccents)
  const togglePassageShowGreekAccents = useCallback(() => setLocalInfo('passageShowGreekAccents', !getPassageShowGreekAccents()), [ getPassageShowGreekAccents ])

  const passageGreekPunctuation = useContext(PassageGreekPunctuationContext)
  const onChangePassageGreekPunctuation = useCallback(({ target }) => setLocalInfo('passageGreekPunctuation', target.value), [])

  return (
    <OptionsPopover
      hideArrow
      $inMenu={inMenu}
      horizontal={inMenu && `center`}
      {...otherProps}
    >
      <Container>

        <Heading>
          {i18n("Bible Display Settings")}
        </Heading>

        <CustomSlider
          label={i18n("Text Size")}
          min={200}
          max={400}
          color="secondary"
          value={parseInt(passageTextSizes.base * 100)}
          onChange={updateBaseTextSize}
        />
        {/* Sliders for Hebrew and Greek should go from 2-4 also */}

        <CustomSlider
          label={i18n("Line Spacing")}
          min={200}
          max={350}
          color="secondary"
          value={parseInt(passageLineSpacingSizes.base * 100)}
          onChange={updateBaseLineSpacing}
        />
        {/* Sliders for Hebrew and Greek should go from 2-3 */}

        <CustomCheckbox
          checked={passageShowCfs}
          onChange={togglePassageShowCfs}
          label={i18n("Show cross-reference dots")}
        />

        <CustomCheckbox
          checked={passageShowNotes}
          onChange={togglePassageShowNotes}
          label={i18n("Show translation note dots")}
        />

        <DetailAccordion
          expanded={showHebrewGreek}
          onChange={toggleShowHebrewGreek}
          summary={i18n("Original Languages")}
          details={
            <>
              <MinorHeading>
                {i18n("Hebrew")}
              </MinorHeading>

              <CustomCheckbox
                checked={passageShowHebrewVowels}
                onChange={togglePassageShowHebrewVowels}
                label={i18n("Show vowels")}
              />

              <CustomCheckbox
                checked={passageShowCantillation}
                onChange={togglePassageShowCantillation}
                label={i18n("Show cantillation")}
              />

              <MinorHeading>
                {i18n("Greek")}
              </MinorHeading>

              <CustomCheckbox
                checked={passageShowGreekAccents}
                onChange={togglePassageShowGreekAccents}
                label={i18n("Show accents")}
              />

              <StyledTextField
                variant="outlined"
                size="small"
                select
                label={i18n("Punctuation")}
                value={passageGreekPunctuation}
                onChange={onChangePassageGreekPunctuation}
                fullWidth
              >
                <MenuItem
                  value="ENGLISH"
                >
                  {i18n("English")}
                </MenuItem>
                <MenuItem
                  value="GREEK"
                >
                  {i18n("Greek")}
                </MenuItem>
                <MenuItem
                  value="NONE"
                >
                  {i18n("None")}
                </MenuItem>
              </StyledTextField>

            </>
          }
        />

      </Container>
    </OptionsPopover>
  )
}

export default memo(PassageDisplaySettingsPopover)