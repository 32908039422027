import { memo, useContext } from 'react'
import styled from 'styled-components'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { i18n } from 'inline-i18n'
import Popover from '@material-ui/core/Popover'
import Fade from '@material-ui/core/Fade'

import { LoggedInUserContext } from '../../context/LoggedInUser'
import useSyncStatusItems from '../../hooks/useSyncStatusItems'
import useModalAnchor from '../../hooks/useModalAnchor'
import { fontFamily } from '../../utils/theme'

import Avatar from './Avatar'
import AccountMenu from './AccountMenu'
import Loading from './Loading'
import useLogInCallback from '../../hooks/useLogInCallback'

const StyledIconButton = styled(IconButton)`
  padding: 8px;
  margin: -4px 0;
  position: relative;
  font-family: ${fontFamily};

  @media print {
    display: none;
  }
`

const AccountLabel = styled.div`
  font-weight: 400;
`

const NameLabel = styled.div`
  margin-top: 5px;
  font-size: 12px;
`

const EmailLabel = styled.div`
  font-weight: 300;
  opacity: .7;
`

const SyncMessage = styled.div`
  margin-top: 5px;
  font-weight: 300;
`

const SyncStatusIconContainer = styled.div`
  .MuiSvgIcon-root {
    font-size: ${({ $inMenu }) => $inMenu ? 12 : 14}px;
    position: absolute;
    right: 2px;
    bottom: 2px;
  }
`

const AccountMenuButton = ({
  inMenu,
}) => {

  const user = useContext(LoggedInUserContext)
  const { logIn, navigatingToLogin } = useLogInCallback()
  const { anchorEl, openModal, closeModal } = useModalAnchor()
  const { statusMessage, iconComponent } = useSyncStatusItems()

  return (
    <>
      <Tooltip
        arrow
        title={
          user
            ? (
              <>
                <AccountLabel>
                  Account
                </AccountLabel>
                {!!user.name &&
                  <NameLabel>
                    {user.name}
                  </NameLabel>
                }
                {!!user.email &&
                  <EmailLabel>
                    {user.email}
                  </EmailLabel>
                }
                <SyncMessage>
                  {statusMessage}
                </SyncMessage>
              </>
            )
            : i18n("Sign in / create an account")
        }
      >
        <StyledIconButton
          className="AccountMenuButton-StyledIconButton"
          onClick={
            user
              ? openModal
              : logIn
          }
        >
          <Avatar size={inMenu && `small`} />
          <SyncStatusIconContainer $inMenu={inMenu}>
            {iconComponent}
          </SyncStatusIconContainer>
          {navigatingToLogin && <Loading size={20} bgOpacity={.8} />}
        </StyledIconButton>
      </Tooltip>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        marginThreshold={5}
        TransitionComponent={Fade}
        onClose={closeModal}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <AccountMenu />
      </Popover>
    </>
  )
}

export default memo(AccountMenuButton)