import React, { useMemo, useState } from 'react'
import { useMutation, useApolloClient } from '@apollo/client'

import useMutationContext from '../hooks/useMutationContext'
import useRefState from '../hooks/useRefState'
import useEffectAsync from '../hooks/useEffectAsync'
import useInstanceValuesCallback from '../hooks/useInstanceValuesCallback'
import useDataQuery from '../hooks/useDataQuery'
import useSetTimeout from '../hooks/useSetTimeout'
import useInstanceValue from '../hooks/useInstanceValue'
import { isAndroid, isIOS, urlB64ToUint8Array } from '../utils/misc'

import notificationsDeviceQuery from '../graphql/queries/notificationsDevice'
import updateNotificationsDeviceMutation from '../graphql/mutations/updateNotificationsDevice'

export const getNotificationPermission = () => {
  const permission = ("Notification" in window && Notification.permission)
  if(permission === `default`) {
    return `needs-permission`
  }
  return permission ? `permission-${permission}` : `unavailable`
}

export const NotificationsContext = React.createContext({})

export const NotificationsContextProvider = ({ children }) => {

  const [ notificationsSubscriptionState, setNotificationsSubscriptionState, getNotificationsSubscriptionState ] = useRefState(`checking`)
  const [ currentSubscription, setCurrentSubscription ] = useRefState()
  const [ updatingNotificationsStatus, setUpdatingNotificationsStatus ] = useState(false)
  const [ initialCheckDone, setInitialCheckDone ] = useState(false)
  const [ setRecheckTimeout ] = useSetTimeout()

  const client = useApolloClient()
  const context = useMutationContext()
  const [ updateNotificationsDevice ] = useMutation(updateNotificationsDeviceMutation)

  const id = currentSubscription && currentSubscription.toJSON().endpoint.slice(-255)
  const { notificationsDevice, loading } = useDataQuery({
    notificationsDeviceQuery,
    variables: {
      id,
    },
    skip: !id,
  })

  const checkingNotificationsStatus = [ `checking`, `no-service-worker` ].includes(notificationsSubscriptionState) || loading
  const notificationsStatus = (notificationsSubscriptionState === `subscribed` && (notificationsDevice || {}).status) || `NONE`
  const notificationsUnavailable = [ `unavailable`, `unsupported` ].includes(notificationsSubscriptionState)

  const getNotificationsStatus = useInstanceValue(notificationsStatus)

  const checkNotificationsSubscription = useInstanceValuesCallback(
    async () => {

      setCurrentSubscription()

      const persmission = getNotificationPermission()  // permission-granted, permission-denied, needs-permission, or unavailable
      // Note: iOS does not support permission-denied; it return needs-permission in this case

      if(persmission !== `permission-granted`) {
        setNotificationsSubscriptionState(persmission)
        return
      }

      if(
        !(
          'serviceWorker' in navigator
          && 'PushManager' in window
        )
      ) {
        setNotificationsSubscriptionState(`unsupported`)
        return
      }

      setNotificationsSubscriptionState(`checking`)

      const registration = await navigator.serviceWorker.getRegistration()

      if(!registration) {
        setNotificationsSubscriptionState(`no-service-worker`)
        setRecheckTimeout(checkNotificationsSubscription, 1000)
        return
      }

      const currentSubscription = await registration.pushManager.getSubscription()

      if(currentSubscription) {
        setNotificationsSubscriptionState(`subscribed`)
        setCurrentSubscription(currentSubscription)
      } else {
        setNotificationsSubscriptionState(`not-subscribed`)
      }

    },
  )

  const updateNotificationsStatus = useInstanceValuesCallback(
    async status => {

      setUpdatingNotificationsStatus(true)

      let persmission = getNotificationPermission()  // permission-granted, permission-denied, needs-permission, or unavailable
      if(persmission === `needs-permission`) {
        await Notification.requestPermission()
        persmission = getNotificationPermission()
      }

      if(persmission === 'permission-denied') {

        console.error('Notifications: The user explicitly denied the permission request.')
        await checkNotificationsSubscription()
        setUpdatingNotificationsStatus(false)
        return

      } else if(persmission === 'permission-granted') {

        console.info('Notifications: The user accepted the permission request.')

        const registration = await navigator.serviceWorker.getRegistration()
        if(!registration) {  // just in case
          console.info('Notifications: subscription failed due to no service worker.')
          await checkNotificationsSubscription()
          setUpdatingNotificationsStatus(false)
          return
        }

        let subscription

        try {

          subscription = await registration.pushManager.getSubscription()

          if(subscription) {
            console.info('Notifications: user already subscribed, so assuming this is an update to status.', status)
          } else {

            subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlB64ToUint8Array(process.env.REACT_APP_WEB_PUSH_PUBLIC_KEY)
            })

          }

          const id = subscription.toJSON().endpoint.slice(-255)

          await updateNotificationsDevice({
            variables: {
              id,
              input: {
                deviceType: (
                  (isIOS && `IOS`)
                  || (isAndroid && `ANDROID`)
                  || `BROWSER`
                ),
                subscription,
                status,
              },
            },
            context,
          })

          client.writeQuery({
            query: notificationsDeviceQuery,
            data: {
              notificationsDevice: {
                __typename: `NotificationsDevice`,
                id,
                status,
              },
            },
            variables: {
              id,
            },
          })

        } catch(err) {
          console.error('Notifications: subscription failed due to error:', err)  // always happens in incognito mode
          subscription && subscription.unsubscribe()
          await checkNotificationsSubscription()
          setUpdatingNotificationsStatus(false)
          return
        }

        await checkNotificationsSubscription()
        await new Promise(resolve => setTimeout(resolve, 50))  // allow notificationsDeviceQuery to get a value from the writeQuery
        setUpdatingNotificationsStatus(false)

      }

    },
  )

  useEffectAsync(
    async () => {

      await checkNotificationsSubscription()

      if('permissions' in navigator) {
        const notificationPermission = await navigator.permissions.query({ name: 'notifications' })
        notificationPermission.onchange = checkNotificationsSubscription
      }

      // This is needed since the person might turn off notifications, then turn them back on in one tab.
      // In this case, the other tab would not check after they were resubscribed.
      setInterval(
        async () => {
          if(getNotificationsSubscriptionState() === `not-subscribed`) {
            await checkNotificationsSubscription()
          }
        },
        1000 * 10,
      )

    },
    [],
  )

  useEffectAsync(
    () => {
      if(!checkingNotificationsStatus) {
        setInitialCheckDone(true)
      }
    },
    [ checkingNotificationsStatus ],
  )

  useEffectAsync(
    async () => {

      // if they just logged in or logged out, I need to have updateNotificationsDevice rerun so that the userId is updated
      // doesn't do anything if login status did not change

      if(
        initialCheckDone
        && ![ `NONE`, `INACTIVE` ].includes(notificationsStatus)
      ) {
        updateNotificationsStatus(notificationsStatus)
      }

    },
    [ initialCheckDone ],
  )

  const value = useMemo(
    () => ({
      checkingNotificationsStatus,
      notificationsStatus,
      getNotificationsStatus,
      updateNotificationsStatus,
      updatingNotificationsStatus,
      notificationsUnavailable,
    }),
    [ notificationsStatus, getNotificationsStatus, updateNotificationsStatus, checkingNotificationsStatus, updatingNotificationsStatus, notificationsUnavailable ],
  )

  // console.log('NOTIFICATIONS INFO:', { ...value, notificationsSubscriptionState, loading, notificationsDevice })

  return (
    <NotificationsContext.Provider value={value}>
      {children}
    </NotificationsContext.Provider>
  )
}