import { memo } from 'react'
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
// import styled from 'styled-components'

import useMapEditorItem from '../../../hooks/useMapEditorItem'

import FadedLoading from '../../common/FadedLoading'
import BibleMapEditorNames from './BibleMapEditorNames'
import BibleMapEditorBody from './BibleMapEditorBody'
import BibleMapEditorLevelOfImportance from './BibleMapEditorLevelOfImportance'

const BibleMapEditorPerson = ({
  mapLayerId,
  personInEdit,
  requestingLock,
  lockObtained,
  updating,
  doUpdateMapLayer,
  ...otherProps
}) => {

  const { onChange, edited, buttons } = useMapEditorItem({
    mapLayerId,
    type: `person`,
    inEdit: personInEdit,
    requestingLock,
    lockObtained,
    updating,
    doUpdateMapLayer,
    ...otherProps,
  })

  const { names, search, levelOfImportance, about } = edited  // imgUrl

  const commonProps = {
    onChange: onChange,
    disabled: !lockObtained || updating,
    variant: "outlined",
    size: "small",
    fullWidth: true,
  }

  return (
    <>

      <BibleMapEditorBody>

        <BibleMapEditorNames
          names={names}
          {...commonProps}
        />

        <TextField
          value={about || ``}
          data-keys={`about`}
          label={i18n("About")}
          multiline
          {...commonProps}
        />

        <TextField
          value={search || ``}
          data-keys={`search`}
          label={i18n("Search Text")}
          placeholder={i18n("E.g. {{example}}", { example: `joseph in:nt` })}
          {...commonProps}
        />

        <BibleMapEditorLevelOfImportance
          levelOfImportance={levelOfImportance || 1}
          {...commonProps}
        />

        {(requestingLock || updating) && <FadedLoading />}

      </BibleMapEditorBody>

      {buttons}

    </>
  )
}

export default memo(BibleMapEditorPerson)
