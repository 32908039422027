import { memo } from 'react'
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
// import MenuItem from '@material-ui/core/MenuItem'
// import Button from '@material-ui/core/Button'
// import styled from 'styled-components'

import useMapEditorItem from '../../../hooks/useMapEditorItem'
import { categoryPattern } from './mapDataIsValid'

import FadedLoading from '../../common/FadedLoading'
import BibleMapEditorNames from './BibleMapEditorNames'
import BibleMapEditorBody from './BibleMapEditorBody'
import BibleMapEditorLevelOfImportance from './BibleMapEditorLevelOfImportance'
import BibleMapEditorDates from './BibleMapEditorDates'
import BibleMapEditorPassages from './BibleMapEditorPassages'
import BibleMapEditorSelectJourney from './BibleMapEditorSelectJourney'
import BibleMapEditorSelectPersons from './BibleMapEditorSelectPersons'

const BibleMapEditorEvent = ({
  mapLayerId,
  placeInEdit,
  eventInEdit,
  requestingLock,
  lockObtained,
  updating,
  doUpdateMapLayer,
  journeys,
  persons,
  setSelectedJourney,
  setSelectedPerson,
  ...otherProps
}) => {

  const { onChange, edited, buttons } = useMapEditorItem({
    mapLayerId,
    type: `event`,
    inEdit: eventInEdit,
    requestingLock,
    lockObtained,
    updating,
    doUpdateMapLayer,
    setSelectedJourney,
    setSelectedPerson,
    ...otherProps,
  })

  const { names, category, levelOfImportance, dates, passages, journeyId, personIds, about } = edited  // imgUrl

  const commonProps = {
    onChange: onChange,
    disabled: !lockObtained || updating,
    variant: "outlined",
    size: "small",
    fullWidth: true,
  }

  return (
    <>

      <BibleMapEditorBody>

        <BibleMapEditorNames
          names={names}
          {...commonProps}
        />

        <TextField
          value={category || ``}
          data-keys={`category`}
          label={i18n("Category")}
          placeholder="e.g. Writing of Scripture"
          error={!!category && !new RegExp(categoryPattern).test(category || ``)}
          {...commonProps}
        />

        <BibleMapEditorDates
          dates={dates}
          {...commonProps}
        />

        <BibleMapEditorPassages
          passages={passages}
          {...commonProps}
        />

        {(!!journeyId || [ `CITY`, `SPOT`, `MOUNTAIN` ].includes(placeInEdit.type)) &&
          <BibleMapEditorSelectJourney
            journeyId={journeyId}
            journeys={journeys}
            setSelectedJourney={setSelectedJourney}
            doUpdateMapLayer={doUpdateMapLayer}
            onEventChange={onChange}
          />
        }

        <BibleMapEditorSelectPersons
          personIds={personIds}
          persons={persons}
          setSelectedPerson={setSelectedPerson}
          doUpdateMapLayer={doUpdateMapLayer}
          onEventChange={onChange}
        />

        <TextField
          value={about || ``}
          data-keys={`about`}
          label={i18n("About")}
          multiline
          {...commonProps}
        />

        <BibleMapEditorLevelOfImportance
          levelOfImportance={levelOfImportance || 1}
          {...commonProps}
        />

        {(requestingLock || updating) && <FadedLoading />}

      </BibleMapEditorBody>

      {buttons}

    </>
  )
}

export default memo(BibleMapEditorEvent)

// journeyId: id,
// personIds: {
//   type: "array",
//   items: {
//     type: id,
//   },
// },