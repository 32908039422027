import { memo, useState } from 'react'
import styled from 'styled-components'

import StudyBibleDots from './StudyBibleDots'
import StudyBiblePage from './StudyBiblePage'
import LazyLoadPageViewer from '../common/LazyLoadPageViewer'
import FreehandContainer from '../common/FreehandContainer'
import VerticalInsetShadow from '../common/VerticalInsetShadow'

const Image = styled.img`
  vertical-align: middle;
`

const StyledLazyLoadPageViewer = styled(LazyLoadPageViewer)`
  height: 200px;

  .LazyLoadPageViewer-PageContainer {
    background: none;
    display: flex;
    flex-direction: column;
  }
`

const StyledStudyBiblePage = styled(StudyBiblePage)`
  ${({ $dotsShown }) => !$dotsShown ? `` : `
    padding-top: 0;
  `}
  ${({ $sourceShown }) => !$sourceShown ? `` : `
    padding-bottom: 0;
  `}
`

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
`

const StudyBibleItemTimelineContent = ({
  info,
  details=[],
}) => {

  const { imageUrl, imageDimensions={}, sourceText } = info || {}

  const [ pageKey, setPageKey ] = useState(0)

  const { svgStrokes } = (details[pageKey] || {}).info || {}

  return (
    <>

      <FreehandContainer
        svgStrokes={svgStrokes}
        animate
      >
        <ImageContainer>
          <Image
            src={imageUrl}
            style={imageDimensions}
          />
          <VerticalInsetShadow />
        </ImageContainer>
      </FreehandContainer>

      {details.length > 1 &&
        <StudyBibleDots
          numDots={details.length}
          pageKey={pageKey}
          setPageKey={setPageKey}
        />
      }

      <StyledLazyLoadPageViewer
        pageKey={pageKey}
        page={
          <StyledStudyBiblePage
            detail={details[pageKey] || {}}
            $dotsShown={details.length > 1}
            $sourceShown={!!sourceText}
          />
        }
        setPageKey={setPageKey}
        previousPageKey={pageKey - 1}
        nextPageKey={pageKey + 1}
      />

    </>
  )
}

export default memo(StudyBibleItemTimelineContent)