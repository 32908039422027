import { memo, useCallback, useContext, useState } from 'react'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import { i18n } from 'inline-i18n'
import { useMutation } from '@apollo/client'
import { useNetworkState } from 'react-use'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'

import useMutationContext from '../../hooks/useMutationContext'
import useEffectAsync from '../../hooks/useEffectAsync'
import { LoggedInUserContext } from '../../context/LoggedInUser'

import ConfirmDialog from './ConfirmDialog'
import InfoDialog from './InfoDialog'

import addToMailingListMutation from '../../graphql/mutations/addToMailingList'
import { isValidEmail } from '../../utils/misc'

const JoinButton = styled(Button)`
  text-transform: none;
`

const CouponDetails = styled(DialogContentText)`
  font-size: 13px;
`

const StyledTextField = styled(TextField)`
  width: 300px;
  max-width: 100%;
  margin-top: 15px;
`

const JoinLink = styled.a`
  text-decoration: underline;
  cursor: pointer;
`

const JoinTheMailingListButton = ({
  label=i18n("Join the Mailing List"),
  title,
  displayAsLink,
  autoShowAfterCreation,
  confirmStatus,
  setConfirmStatus,
  ...otherProps
}) => {

  const [ internalConfirmStatus, setInternalConfirmStatus ] = useState(`no-show`)
  confirmStatus = confirmStatus || internalConfirmStatus
  setConfirmStatus = setConfirmStatus || setInternalConfirmStatus
  const setConfirmStatusToShow = useCallback(() => setConfirmStatus(`show`), [ setConfirmStatus ])
  const setConfirmStatusToNoShow = useCallback(() => setConfirmStatus(`no-show`), [ setConfirmStatus ])
  const [ successStatus, setSuccessStatus ] = useState(`null`)
  let [ email, setEmail ] = useState()
  const user = useContext(LoggedInUserContext)
  email = (email === undefined ? (user || {}).email : email) || ``
  const { createdAt=0 } = user || {}

  const { online } = useNetworkState()
  const [ addToMailingList ] = useMutation(addToMailingListMutation)
  const context = useMutationContext()

  const onChangeEmail = useCallback(({ target }) => setEmail(target.value.trim()), [])

  const goAddToMailingList = useCallback(
    async () => {
      setConfirmStatus(`submitting`)

      const { data: { addToMailingList: success } } = await addToMailingList({
        variables: {
          email,
        },
        context,
      })

      setSuccessStatus(JSON.stringify(success))
      setConfirmStatus(`done`)
    },
    [ setConfirmStatus, addToMailingList, email, context ],
  )

  useEffectAsync(
    () => {
      if(
        confirmStatus === `no-show`
        && autoShowAfterCreation
        && Date.now() - createdAt < 1000 * 60  // created less than a minute ago
      ) {
        setConfirmStatus(`show`)
      }
    },
    [ createdAt ],
  )

  const disabled = !isValidEmail(email)

  return (
    <>

      {!displayAsLink && !autoShowAfterCreation && online &&
        <JoinButton
          onClick={setConfirmStatusToShow}
          variant="outlined"
          disableElevation
          size="small"
          {...otherProps}
        >
          {label}
        </JoinButton>
      }

      {!!displayAsLink && !autoShowAfterCreation && online &&
        <JoinLink
          onClick={setConfirmStatusToShow}
          {...otherProps}
        >
          {label}
        </JoinLink>
      }

      <ConfirmDialog
        open={[ `show`, `submitting` ].includes(confirmStatus)}
        onCancel={setConfirmStatusToNoShow}
        onConfirm={goAddToMailingList}
        cancelButtonLabel={
          autoShowAfterCreation ? i18n("No Thanks") : undefined
        }
        title={title || label}
        explanation={
          <>
            <DialogContentText>
              {i18n("Hear about new features, new courses, and sales. Plus, get a coupon* for 50% off your first month of Biblearc TOOLS.")}
            </DialogContentText>
            <CouponDetails>
              {i18n("* New subscribers only. Cannot be combined with other sales or discounts.")}
            </CouponDetails>
            <StyledTextField
              label={i18n("Email")}
              value={email}
              onChange={onChangeEmail}
              variant="outlined"
            />
          </>
        }
        confirmButtonLabel={i18n("Join")}
        disabled={disabled}
        color="secondary"
        loading={confirmStatus === `submitting`}
      />

      <InfoDialog
        open={[ `done` ].includes(confirmStatus)}
        onOkay={setConfirmStatusToNoShow}
        title={title || label}
        explanation={{
          "true": i18n("Success! We added you to the mailing list and sent you the coupon."),
          "false": i18n("Hmm. You are already on our mailing list. (You may need to check your spam folder and mark us as “not spam.”) Still, we’ve sent you the coupon in case that is what you were looking for."),
          "null": i18n("Whoops! Something went wrong."),
        }[successStatus]}
        color="secondary"
      />

    </>
  )

}

export default memo(JoinTheMailingListButton)