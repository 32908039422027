import { ApolloLink, Observable } from '@apollo/client'

import { capitalize, cloneObj } from '../../utils/misc'
import { db } from '../../utils/database'
import user from '../dexie/user'
import project from '../dexie/project'
import projects from '../dexie/projects'
import modulePieces from '../dexie/modulePieces'

const dexieQueries = {
  user,
  project,
  projects,
  modulePieces,
}

const dexieQueryLink = new ApolloLink((operation, forward) => (
  new Observable(async observer => {

    const { variables, query } = operation
    let { id } = variables

    const context = operation.getContext()
    const { operationName, doBackupServerQuery, saveServerResultToDexie, client } = context

    let dbResult

    if(dexieQueries[operationName]) {

      dbResult = await dexieQueries[operationName](variables)

    } else if(!/s$/.test(operationName) && id) {

      dbResult = await db[`${operationName}s`].get(id)

    }

    const response = {
      data: {
        [operationName]: (
          dbResult
            ? {
              __typename: capitalize(operationName),
              ...dbResult,
            }
            : null
        ),
      },
    }


    // if(!response) {
    //   observer.error(new Error(`Invalid query with mock data setup: ${operationName}`))
    //   return
    // }

    console.log(`graphql response “returned” for ${operationName} via dexie query`, cloneObj(response))
    observer.next(response)
    observer.complete()

    if(doBackupServerQuery) {
      // requestAnimationFrame needed for it to run
      requestAnimationFrame(
        () => client.query({
          context: {
            ...context,
            isBackupServerQuery: true,
            forceSaveServerResultToDexie: !!saveServerResultToDexie,
          },
          query,
          variables,
          fetchPolicy: `network-only`,
        })
      )
    }

  })
))

export default dexieQueryLink