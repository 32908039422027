import { memo, useCallback, useRef } from 'react'
import { i18n } from 'inline-i18n'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import styled from 'styled-components'
import { ReactSortable } from "react-sortablejs"

import { GET_DEFAULT_PERSON } from './BibleMap'
import useRefState from '../../../hooks/useRefState'
import { cloneObj, getPrimaryName } from '../../../utils/misc'

const filter = createFilterOptions()

const Container = styled.div`
`

const Spacer = styled.div`
  height: 5px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const Name = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
`

const StyledAutocomplete = styled(Autocomplete)`
  flex: 1;
`

const StyledIconButton = styled(IconButton)`
  padding: 8px;

  .MuiSvgIcon-root {
    font-size: 17px;
  }
`

const Heading = styled.div`
  margin-bottom: 5px;
`

const BibleMapEditorSelectPersons = ({
  personIds,
  persons,
  setSelectedPerson,
  doUpdateMapLayer,
  onEventChange,
}) => {

  const [ value, setValue ] = useRefState(``)

  const ref = useRef()

  const setList = useCallback(
    list => {
      onEventChange({
        keys: [ `personIds` ],
        newValue: list.map(({ personId }) => personId),
      })
    },
    [ onEventChange ],
  )

  const onChange = useCallback(
    (event, newValue) => {
      if(typeof newValue === 'string') {
        const matchingPerson = persons.find(({ names }) => names[0].name.toLowerCase() === newValue.toLowerCase())
        if(matchingPerson) {
          newValue = matchingPerson
        } else {
          newValue = { inputValue: newValue }
        }
      }

      if((newValue || {}).inputValue) {
        const person = GET_DEFAULT_PERSON()
        person.names[0].name = newValue.inputValue
        const newPersonIds = cloneObj(personIds)
        newPersonIds.push(person.id)
        onEventChange({ keys: [ `personIds` ], newValue: newPersonIds })
        doUpdateMapLayer({ person })
        setTimeout(() => setSelectedPerson(person))
      } else if(newValue && !personIds.includes(newValue.id)) {
        const newPersonIds = cloneObj(personIds)
        newPersonIds.push(newValue.id)
        onEventChange({ keys: [ `personIds` ], newValue: newPersonIds })
      }

      setTimeout(() => ref.current && ref.current.querySelector(`input`).blur(), 50)
    },
    [ personIds, doUpdateMapLayer, setSelectedPerson, onEventChange, persons ],
  )

  const filterOptions = useCallback(
    (options, params) => {
      let filtered = filter(options, params)

      filtered = filtered.filter(({ id }) => !personIds.includes(id))

      if(
        params.inputValue !== ''
        && !persons.some(({ names }) => names[0].name.toLowerCase() === params.inputValue.toLowerCase())
      ) {
        filtered.push({
          inputValue: params.inputValue,
          title: i18n("Add “{{value}}”", { value: params.inputValue }),
        })
      }

      return filtered
    },
    [ persons, personIds ],
  )

  const getOptionLabel = useCallback(
    option => {
      if(typeof option === 'string') {
        return option
      }
      if(option.inputValue) {
        return option.inputValue
      }
      return option.names[0].name
    },
    [],
  )

  const renderOption = useCallback(option => option.title || option.inputValue || option.names[0].name, [])

  return (
    <Container>

      <Heading>
        {i18n("People")}
      </Heading>

      <ReactSortable
        list={cloneObj(personIds).map(personId => ({ personId }))}
        setList={setList}
        delayOnTouchStart
      >
        {personIds.map((personId, idx) => {
          const person = persons.find(({ id }) => id === personId)

          return (
            <Row key={personId}>

              <Name>
                {getPrimaryName(person)}
              </Name>

              <StyledIconButton
                onClick={() => setSelectedPerson(person)}
                disabled={!person}
              >
                <EditIcon />
              </StyledIconButton>

              <StyledIconButton
                onClick={() => {
                  const newValue = cloneObj(personIds)
                  newValue.splice(idx, 1)
                  onEventChange({ keys: [ `personIds` ], newValue })
                }}
              >
                <CloseIcon />
              </StyledIconButton>

            </Row>
          )
        })}
      </ReactSortable>

      {personIds.length > 0 && <Spacer />}

      <StyledAutocomplete
        inputValue={value}
        onInputChange={(event, newInputValue) => setValue(newInputValue)}
        onChange={onChange}
        filterOptions={filterOptions}
        options={persons}
        getOptionLabel={getOptionLabel}
        clearOnBlur
        handleHomeEndKeys
        renderOption={renderOption}
        freeSolo
        size="small"
        renderInput={params => (
          <TextField
            {...params}
            ref={ref}
            placeholder={i18n("Add Person")}
            variant="outlined"
          />
        )}
      />


    </Container>
  )
}

export default memo(BibleMapEditorSelectPersons)