/*

Three ways to keep things "sticky":

1. useStickyRefState (localstorage)

  PARTICULARS:
  * device-specific
  - syncronous
  - works offline
  - not oversized
  * no instant sync (across uses throughout code and tabs)
  - works with non-general ids
  - "unlimited" update frequency
  
  USES:
  - initial values
  - in place of useState when you want it sticky
  
2. useAccountSetting (cloud/indexedDB)

  PARTICULARS:
  * account-specific (works with no login as well)
  - asyncronous
  - works offline
  - not oversized
  * instant sync (across uses throughout code and tabs)
  - general ids only
  - limited update frequency

  USES:
  - user customization

3. localInfo (indexedDB)

  PARTICULARS:
  * device-specific
  - pseudo-syncronous
  - works offline
  - can be large
  * instant sync (across uses throughout code and tabs)
  - general ids only
  - "unlimited" update frequency
  
  USES:
  - device customization

*/

import { useCallback, useMemo } from 'react'
import { usePrevious } from 'react-use'

import { getLocalStorage, removeLocalStorage } from '../utils/misc'
import useRefState from "./useRefState"
import useInitialValueUpdater from './useInitialValueUpdater'

const useStickyRefState = ({ key, id, defaultValue, doNotRemoveDefault }) => {

  key = key || `useStickyRefState:${id}`
  const previousKey = usePrevious(key)

  let [ value, setValue, getValue ] = useRefState()  // eslint-disable-line no-unused-vars
  // do NOT use value since the useMemo is unconventionally setting this ref state on a key update

  useMemo(
    () => {
      setValue(getLocalStorage(`initialValue:${key}`, defaultValue))
    },
    [ key ],  // eslint-disable-line react-hooks/exhaustive-deps
  )

  const clear = useCallback(() => removeLocalStorage(`initialValue:${key}`), [ key ])

  useInitialValueUpdater(
    key === previousKey
      ? {
        ...(doNotRemoveDefault ? {} : {
          removeValue: defaultValue,
        }),
        [key]: getValue(),
      }
      : {}  // don't update anything if the key has changed
  )

  return [ getValue(), setValue, getValue, clear ]
}

export default useStickyRefState
