import styled from 'styled-components'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Divider from '@material-ui/core/Divider'
import { i18n } from 'inline-i18n'
import copy from 'copy-to-clipboard'
import { getRefFromLoc } from '@bibletags/bibletags-versification'
import { getUsfmBibleBookAbbr } from '@bibletags/bibletags-ui-helper'

import selectWholeWords from '../../../utils/selectWholeWords'
// import { getEmbedFullScreen } from '../../../graphql/links/embedLink'

import SearchSuggestionMenuItems from '../../common/SearchSuggestionMenuItems'

const Container = styled.div`
  direction: ltr;  // we do not want to inherit the rtl of the container if reading Hebrew
`

const selectText = async ({
  textSelectionInfo,
  // markup=[],
  // updateMarkup,
  // formattingKeyId,
  closeOptions,
  getOptionsPopperInfo,
  setOptionsPopperInfo,
  goCreateStudyBibleItem,
  setSnackbarInfo,
  versionId,
  // bookId,
  onTopOfAll,
}) => {

  // What it should do:
  // [MOVE Copy AND Search ELSEWHERE TO USE IN OTHER selectText funcs too]
  // - Copy (when clicked it copies immediately AND shows snackbar [which it also does when the user copies with ^C or a right click] where the user can change the checkboxes. changing checkboxes recopies)
  //   - snackbar says: "<b>Copied.</b> Includes: []Reference []Vs numbers []Footnotes []My notes":
  //     - reference [for html version, make the reference a link to Biblearc]
  //     - verse numbers
  //     - footnotes [for html version, make references in the footnotes link to Biblearc]
  //     - highlights ?

  const {
    selection,
    textRange,
    commonAncestorEl,
    knownToBeStillSelecting,
  } = textSelectionInfo

  if(
    !textRange
    || knownToBeStillSelecting
    || !commonAncestorEl.closest('.container-with-passage-popper')
    || commonAncestorEl.closest(`[contenteditable]`)
  ) {

    const { $type } = getOptionsPopperInfo()
    if($type === 'selection' || !window.getSelection().isCollapsed) {
      closeOptions()
    }

    return
  }

  const {
    firstWordEl,
    lastWordEl,
    copyText,
    searchStr,
    words,
    showSearchSuggestions,
    bookId,
    selectionStart,
    selectionEnd,
    completeVersesSelection,
  } = selectWholeWords({ selection, textSelectionInfo })

  // this block needed for search results, which do not send in versionId
  if(!versionId && firstWordEl) {
    const versionIdEl = firstWordEl.closest(`[data-versionid]`)
    if(versionIdEl) {
      versionId = versionIdEl.getAttribute(`data-versionid`)
    }
  }

  const boundingClientRect = textRange.getBoundingClientRect()
  const scrollContainerEl = onTopOfAll ? document.body : commonAncestorEl.closest('.PassageContent-ScrollContainer, .scroll-container-for-passage-popper')
  if(!scrollContainerEl) return {}
  const containerBoundingClientRect = scrollContainerEl.getBoundingClientRect()

  const pseudoElStyle = {
    top: boundingClientRect.top - containerBoundingClientRect.top + scrollContainerEl.scrollTop,
    left: boundingClientRect.left - containerBoundingClientRect.left,
    width: boundingClientRect.width,
    height: boundingClientRect.height,
  }

  if(
    boundingClientRect.width === undefined
    || !firstWordEl
    || !lastWordEl
  ) {
    closeOptions()
    return
  }

  // const showAnnotate = isReadingPanel && hasMyPlan
  // const showDragNote = (isReadingPanel || isSearchResults) && hasToolsPlan && inAProject

  setOptionsPopperInfo({
    open: true,
    onClose: () => document.getSelection().empty(),
    $type: 'selection',
    isAnchoredBySelection: true,
    pseudoElStyle,
    children: (
      <Container>

        <MenuList>

          <MenuItem
            onClick={() => copy(copyText, { format: `text/plain` })}
            tabIndex={-1}
          >
            {i18n("Copy text")}
          </MenuItem>

          <MenuItem
            onClick={() => {

              const { bookId, chapter } = getRefFromLoc(selectionStart.loc)
              const searchParams = new URLSearchParams(``)
              
              if(!completeVersesSelection) {
                searchParams.set(`range`, JSON.stringify({ start: selectionStart, end: selectionEnd }))
              } else {
                searchParams.set(`range`, `${selectionStart.loc}-${selectionEnd.loc}`)
              }

              const link = `${window.location.origin}/version/${versionId}/${getUsfmBibleBookAbbr(bookId)}/${chapter}?${searchParams.toString()}`

              copy(link, { format: `text/plain` })

              const closeSnackbar = () => setSnackbarInfo({ ...snackbarInfo, open: false })
              const snackbarInfo = {
                open: true,
                message: i18n("Link copied to clipboard."),
                buttonLabel: i18n("Open Link"),
                onClick: () => window.open(link, '_blank'),
                onClose: closeSnackbar,
              }
              setSnackbarInfo && setSnackbarInfo(snackbarInfo)

              window.getSelection().empty()

            }}
            tabIndex={-1}
          >
            {i18n("Copy link to share")}
          </MenuItem>

          {/* {inAProject && isAtLeastOneWholeVerse && (!IS_EMBED || getEmbedFullScreen()) && 
            <MenuItem
              onClick={() => {
                Show `Create a new [study module] from [ref + version]`
              }}
              tabIndex={-1}
            >
              {i18n("Study...")}
            </MenuItem>
          } */}

          {/* IMPORTANT: As I add things here, I need to update `verticalSpaceNeeded` in PassageInNotesContent */}

        </MenuList>

        {showSearchSuggestions &&
          <>
            <Divider />
            <MenuList>
              <SearchSuggestionMenuItems
                baseSearchStr={searchStr}
                words={words}
                bookId={bookId}
                onClose={closeOptions}
                versionId={versionId}
                selectionStart={selectionStart}
                selectionEnd={selectionEnd}
                tabIndex={-1}  // need to do this because of <MenuList>
              />
            </MenuList>
          </>
        }

        {/* {(showAnnotate || showDragNote) &&
          <>

            <Divider />

            {showAnnotate &&
              <MenuList>

                <MenuItem
                  // onClick={}
                  tabIndex={-1}
                  disabled
                >
                  {i18n("Make a Note")}  // i.e. annotate
                </MenuItem>

              </MenuList>
            }

            {showDragNote &&
              Drag this selection into a Notes tab.
            }

          </>
        } */}

        {!!goCreateStudyBibleItem && !!selectionStart && !!selectionEnd &&
          <>

            <Divider />

            <MenuList>

              <MenuItem
                onClick={() => {
                  goCreateStudyBibleItem({
                    selectionStart,
                    selectionEnd,
                  })
                }}
                tabIndex={-1}
              >
                {i18n("Create study Bible entry", "", "admin")}
              </MenuItem>

            </MenuList>

          </>
        }

      </Container>
    ),
  })
}

export default selectText